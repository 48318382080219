import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Divider, Radio, Checkbox } from "antd";

import TeethType from "components/shared/TeethType/TeethType";
import {
  DownloadOutlined,
} from "@ant-design/icons";
import IPRInfo from "./IPRInfo/IPRInfo";
import AttachmentsInfo from "./IPRInfo/AttachmentsInfo";
import IprSessionList from "./IprSessionList/IprSessionList";
import IprModal from "./IprModal/IprModal";
import FileList from "./IprModal/FileList/FileList";
import { checkTextEditorContent, dataMapper, generateAlignerLabels, iprJawMappers } from "utilities/IPR";
import { isSafari, uniqByKeepLast } from "utilities/commonMethod";
import { primaryColor, companyType } from "colors-config";
import {ReactToPrint ,useReactToPrint} from "react-to-print";
import AttachmentInfo from "./AttachmentInfo";
import AttachmentRow from "./AttachmentRow";
import { showPhasingDetails } from "./utils";
import TreatmentPDF from "components/private/TreatmentPDF/TreatmentPDF";
import AppButton from "components/shared/AppButton/AppButton";
import TreatmentProductsTable from "components/shared/TreatmentProductsTable/TreatmentProductsTable";

const IPRDetail = ({ treatment, treatmentPlansState , patientInfo, clinic}) => {
  const [upperAligners, setUpperAligners] = useState([]);
  const [lowerAligners, setLowerAligners] = useState([]);
  const [alignersLabel, setAlignersLabel] = useState([]);
  const [openIprGuideModal, setOpenIprGuideModal] = useState(false);
  const [chartValue, setChartValue] = useState("fdi");
  const componentRef = useRef();
  const [fullUpperAligners, setFullUpperAligners] = useState([]);
  const [fullLowerAligners, setFullLowerAligners] = useState([]);
  const [lowerAttachments, setLowerAttachments] = useState([]);
  const [upperAttachments, setUpperAttachments] = useState([]);
  const [fullUpperAttachments, setFullUpperAttachments] = useState([]);
  const [fullLowerAttachments, setFullLowerAttachments] = useState([]);
  const [attachmentsLabel, setAttachmentsLabel] = useState([]);
  const [value, setValue] = useState("ipr");
  const [hideLabDetails, setHideLabDetails] = useState(false);

  const handleIPr = (selectedTreatmentData) => {
    let iprExtractedData = {
      upperJaw: [],
      lowerJaw: [],
      alignerLabel: [],
    };

    if (selectedTreatmentData?.iprData?.length) {
      const data = iprJawMappers(selectedTreatmentData.iprData, selectedTreatmentData);
      iprExtractedData = {
        ...data,
      };
    }
    setAlignersLabel(iprExtractedData.alignerLabel);
    setLowerAligners(iprExtractedData.lowerJaw);
    setUpperAligners(iprExtractedData.upperJaw);
    setFullUpperAligners(iprExtractedData.upperJaw);
    setFullLowerAligners(iprExtractedData.lowerJaw);
  };

  const handleAttachments = (selectedTreatmentData) => {
    let iprExtractedData = {
      upperJaw: [],
      lowerJaw: [],
      alignerLabel: [],
    };

    if (selectedTreatmentData?.attachmentData?.length) {
      const data = iprJawMappers(selectedTreatmentData.attachmentData, selectedTreatmentData);
      iprExtractedData = {
        ...data,
      };
    }
    setUpperAttachments(iprExtractedData.upperJaw);
    setFullUpperAttachments(iprExtractedData.upperJaw);
    setLowerAttachments(iprExtractedData.lowerJaw);
    setFullLowerAttachments(iprExtractedData.lowerJaw);
    setAttachmentsLabel(iprExtractedData.alignerLabel);
  };


  useEffect(() => {
    if (treatment) {
      handleIPr(treatment);
      handleAttachments(treatment);
      const savedChecked = localStorage.getItem('hideLabDetailsCheck');
      if (savedChecked !== null) {
        setHideLabDetails(JSON.parse(savedChecked));
      }
    }
  }, [treatment]);

  useEffect(() => {
    if (treatment?.iprData?.length) {
      let apiIprData = treatment.iprData;
      if (treatment.revision_plan_version) {
        apiIprData = apiIprData.filter(
          (obj) => obj.refinement && treatment.lab_treatment_id == obj.labTreatmentId
        );
      } else {
        apiIprData = apiIprData.filter((obj) => !obj.refinement);
      }
      const { lower, upper } = dataMapper(apiIprData);
      setUpperAligners(upper.reverse());
      setFullUpperAligners(upper);
      setLowerAligners(lower);
      setFullLowerAligners(lower);
      const iprData = apiIprData;
      iprData.sort((a, b) => {
        return a.aligner_count - b.aligner_count;
      });
      let uniqueSessions = uniqByKeepLast(iprData, (obj) => obj.aligner_count);
      // uniqueSessions = uniqueSessions.map((obj) => {
      //   obj.checked = true;
      //   return obj;
      // });
      // setAlignersLabel(uniqueSessions);
      const alignerLabel = generateAlignerLabels(uniqueSessions, treatment.num_of_upper_aligners, treatment.num_of_lower_aligners);
      setAlignersLabel(alignerLabel);
    }
  }, [treatment]);


  useEffect(() => {
    if (treatment?.attachmentData?.length) {
      let apiAttachmentData = treatment.attachmentData;
      if (treatment.revision_plan_version) {
        apiAttachmentData = apiAttachmentData.filter(
          (obj) => obj.refinement && treatment.lab_treatment_id == obj.labTreatmentId
        );
      } else {
        apiAttachmentData = apiAttachmentData.filter((obj) => !obj.refinement);
      }

      const { lower, upper } = dataMapper(apiAttachmentData);
      setUpperAttachments(upper.reverse());
      setFullUpperAttachments(upper);
      setLowerAttachments(lower);
      setFullLowerAttachments(lower);
      const attachmentData = apiAttachmentData;
      attachmentData.sort((a, b) => {
        return a.aligner_count - b.aligner_count;
      });
      let uniqueSessions = uniqByKeepLast(attachmentData, (obj) => obj.aligner_count);
      // uniqueSessions = uniqueSessions.map((obj, index) => {
      //   obj.checked = true;
      //   obj.session = index + 1;
      //   return obj;
      // });    
      // setAttachmentsLabel(uniqueSessions);
      const alignerLabel = generateAlignerLabels(uniqueSessions, treatment.num_of_upper_aligners, treatment.num_of_lower_aligners);
      setAttachmentsLabel(alignerLabel);
    }
  }, [treatment]);

  const handleStructureChange = (value) => {
    setChartValue(value);
  };
  const showModal = () => {
    setOpenIprGuideModal(true);
  };

  const onAlignerLabelClicked = (index) => {
    let uniqueSessions = [...alignersLabel];
    uniqueSessions[index].checked = !uniqueSessions[index].checked;
    setAlignersLabel(uniqueSessions);
    let fullUpperAlignersTemp = [];
    let fullLowerAlignersTemp = [];
    fullUpperAligners.forEach((obj) => {
      const findAligner = uniqueSessions.find((uObj) => uObj.aligner_count == obj.aligner_count);
      if (findAligner && findAligner.checked) {
        fullUpperAlignersTemp.push(obj);
      }
    });

    fullLowerAligners.forEach((obj) => {
      const findAligner = uniqueSessions.find((uObj) => uObj.aligner_count == obj.aligner_count);
      if (findAligner && findAligner.checked) {
        fullLowerAlignersTemp.push(obj);
      }
    });
    setUpperAligners(fullUpperAlignersTemp);
    setLowerAligners(fullLowerAlignersTemp);
  };

  const onAttachmentsLabelClicked = (index) => {
    let uniqueSessions = [...attachmentsLabel];
    uniqueSessions[index].checked = !uniqueSessions[index].checked;
    setAttachmentsLabel(uniqueSessions);

    let fullUpperAlignersTemp = [];
    let fullLowerAlignersTemp = [];
    fullUpperAttachments.forEach((obj) => {
      const findAligner = uniqueSessions.find((uObj) => uObj.aligner_count == obj.aligner_count);
      if (findAligner && findAligner.checked) {
        fullUpperAlignersTemp.push(obj);
      }
    });

    fullLowerAttachments.forEach((obj) => {
      const findAligner = uniqueSessions.find((uObj) => uObj.aligner_count == obj.aligner_count);
      if (findAligner && findAligner.checked) {
        fullLowerAlignersTemp.push(obj);
      }
    });
    setUpperAttachments(fullUpperAlignersTemp);
    setLowerAttachments(fullLowerAlignersTemp);
  };

  const getTotalPrice = () => {
    if (treatment?.labProductData?.quantityType == "Per aligner") {
      return (treatment?.labProductData?.price * treatment?.quantity).toFixed(2);
    }
    return treatment?.labProductData?.price.toFixed(2);
  };

  const header = (
    <>
      <div className="chart-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1>Treatment Guide</h1>
        </div>
        <TeethType teethTypeName={chartValue} onTeethTypeChange={handleStructureChange} />
      </div>
      <Divider />
    </>
  );

  const detailOptions = [
    {
      label: "IPR",
      value: "ipr",
    },
    {
      label: "Attachment",
      value: "attachment",
    },
  ];

  const onChange4 = ({ target: { value } }) => {
    setValue(value);
  };


  const showAdditionalDetails = (additionalDetails = "") => {
    const hasContent = checkTextEditorContent(additionalDetails);

    return (
      <div
        className={`patient-response ${!hasContent ? "" : "detail-container"}`}
        style={{ fontWeight: "400", textAlign: "justify" }}
      >
        {!hasContent ? (
          "--"
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: treatment?.additionalDetails,
            }}
          />
        )}
      </div>
    );
  };
  const getPageStyles = () => {
    return `
      @media print {
       
        @page {
          margin: 0 !important;
           padding: 0 !important;
           size: A4;
        }
          
        .teethChart-container .teethRow-container .teethRow-one svg {
          width: 38px !important;
        }

        .ipr-session-list-labels{
        margin-left:auto;}
        

          ${isSafari() ? 

            `
             html, body {
       margin: 0 !important;
           padding: 0 !important;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
      .aligner-component .ipr-check-row{
          display:flex;}
       
    
  `:''
          }
      }
    `;
  };


  const handleHideCheck = (e) => {
    setHideLabDetails(e.target.checked);
    localStorage.setItem('hideLabDetailsCheck', JSON.stringify(e.target.checked));
  };

  const handleDownload = useReactToPrint({
    // options: {
    //   unit: "in",
    //   format: [8.5, 11], // Letter size
    //   orientation: "portrait",
    //   margin: 1, // Set 1 inch margin on all sides
    // },
    // pageStyle: "@page { margin: 10mm; }",
    content: () => {
      document
        .querySelectorAll(".treatment-pdf")
        .forEach(function (container) {
          if (container) {
            container.style.display = "block";
          }
        });
      return componentRef.current;
    },
    onAfterPrint: () => {
      document
        .querySelectorAll(".treatment-pdf")
        .forEach(function (container) {
          if (container) {
            container.style.display = "none";
          }
        });
    },
  });

  return (
    <div>
      <style>{getPageStyles()}</style>
      <div>
        {openIprGuideModal && (
          <IprModal
            alignersLabel={alignersLabel}
            chartValue={chartValue}
            handleStructureChange={handleStructureChange}
            treatment={treatment}
            openIprGuideModal={openIprGuideModal}
            setOpenIprGuideModal={setOpenIprGuideModal}
            lowerAligners={lowerAligners}
            upperAligners={upperAligners}
            onAlignerLabelClicked={onAlignerLabelClicked}
            upperAttachments={upperAttachments}
            lowerAttachments={lowerAttachments}
            attachmentsLabel={attachmentsLabel}
            onAttachmentsLabelClicked={onAttachmentsLabelClicked}
            treatmentPlansState={treatmentPlansState}
          />
        )}
        <Row justify="space-between" style={{ padding: "0px 10px" }}>
          <p
            style={{
              fontWeight: "500",
              fontSize: "1rem",
              margin:"0px 0px 0px 10px"
            }}
          >
            Detail
          </p>
          <div
          style={{
            display: "flex",
            alignItems:"center"
          }}
          >
            {(alignersLabel.length || attachmentsLabel.length) ? null :
              <div
                style={{
                  marginRight: "10px",
                  cursor:"pointer"
                }}
                onClick={() => handleDownload()}
                className="download-btn">
                Download    <DownloadOutlined />
              </div>
            }
           
            <Checkbox onChange={handleHideCheck} checked={hideLabDetails}>
              Hide Lab Details
            </Checkbox>
          </div>
        </Row>
        <Row
          style={{
            marginLeft: "0.5rem",
            marginBottom: "1.5rem",
            marginTop: "-0.5rem",
            padding: "0px 10px",
          }}
        >
          <div className="aligner-component">
            <Row>
              <Col span={8}>
                <div>
                  <Row>
                    <p className="patient-question">
                      No. of Upper {companyType == 4 ? "Stages" : "Aligners"}
                    </p>
                  </Row>
                  <Row>
                    <p className="patient-response">
                      {treatment?.num_of_upper_aligners}
                    </p>
                  </Row>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <Row>
                    <p className="patient-question">
                      No. of Lower {companyType == 4 ? "Stages" : "Aligners"}
                    </p>
                  </Row>
                  <Row>
                    <p className="patient-response">
                      {treatment?.num_of_lower_aligners}
                    </p>
                  </Row>
                </div>
              </Col>
              {/* <Col span={8}>
                <div>
                  <Row>
                    <p className="patient-question">Attachments</p>
                  </Row>
                  <Row>
                    <p className="patient-response">
                      {Boolean(treatment?.attachments) === true ? "Yes" : "No"}
                    </p>
                  </Row>
                </div>
              </Col> */}
            </Row>
            {
              !hideLabDetails && (
                <TreatmentProductsTable treatment={treatment}/>
              )
            }
            {companyType === 5 && showPhasingDetails(treatment?.phasing)}

            <Row>
              <p className="patient-question">Additional Details</p>
            </Row>
            <Row>{showAdditionalDetails(treatment?.additionalDetails)}</Row>

            <Row>
              <p className="patient-question" style={{marginTop:"10px"}}>Manual Treatment Guide PDF</p>
            </Row>
            <Row>
              <FileList iprFile={treatment?.iprDocument} />
            </Row>

          </div>
        </Row>
        <Row
          style={{
            marginLeft: "0.5rem",
            marginBottom: "1.5rem",
            marginTop: "-0.5rem",
            padding: "0px 10px",
          }}
        >
          {alignersLabel.length || attachmentsLabel.length ? (
            <div className="aligner-component">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Radio.Group
                  className="heading-radio"
                  options={detailOptions}
                  onChange={onChange4}
                  value={value}
                  optionType="button"
                  buttonStyle="solid"
                />
                <Row align="middle">
                  <AppButton
                    onClick={showModal}
                    shape="round"
                    type="primary"
                    className="guideBtn"
                  >
                    View Treatment Guide
                  </AppButton>
                  <div    
                onClick={() => handleDownload()} 
               className="download-btn">
                  Download  <DownloadOutlined /> 
                  </div>
                </Row>
              </div>

              <div>
                {value == "ipr" ? (
                  <>
                    <Col lg={24}>
                    </Col>
                    <div className="chart-header" style={{ marginTop:"15px" }}>
                      <div style={{ display: "flex", alignItems: "center"}}>
                        {/* <h1>Treatment Guide</h1> */}
                      </div>
                      <TeethType
                        teethTypeName={chartValue}
                        onTeethTypeChange={handleStructureChange}
                      />
                    </div>
                    <Divider />
                    <Row align="top">
                      <Col lg={10}>
                        <div>
                          <Row>
                            <p
                              className="patient-question"
                              style={{ marginTop: "0px" }}
                            >
                              No. of IPR sessions
                            </p>
                          </Row>
                          <Row>
                            <p className="patient-response">
                              {alignersLabel.length}
                            </p>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={14} className="ipr-session-list-labels">
                        <IprSessionList
                          alignersLabel={alignersLabel}
                          onAlignerLabelClicked={onAlignerLabelClicked}
                        />
                      </Col>
                    </Row>
                  
                    
                    <IPRInfo
                      lowerAligners={lowerAligners}
                      upperAligners={upperAligners}
                      chartValue={chartValue}
                      upperAttachments={upperAttachments}
                      lowerAttachments={lowerAttachments}
                    />
                  </>
                ) : (
                  <>
                    <div className="chart-header" style={{marginTop:"15px"}}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {/* <h1>Treatment Guide</h1> */}
                      </div>
                      <TeethType
                        teethTypeName={chartValue}
                        onTeethTypeChange={handleStructureChange}
                      />
                    </div>
                    <Divider />

                    {/* <AttachmentShowInfo
                  alignersLabel={attachmentsLabel}
                  onAlignerLabelClicked={onAttachmentsLabelClicked}
                  upperAttachments={upperAttachments}
                  lowerAttachments={lowerAttachments}
                  chartValue={chartValue}
                /> */}
                    <AttachmentInfo
                      alignersLabel={attachmentsLabel}
                      onAlignerLabelClicked={onAttachmentsLabelClicked}
                      upperAttachments={upperAttachments}
                      lowerAttachments={lowerAttachments}
                    />
                    <AttachmentsInfo
                      lowerAligners={lowerAligners}
                      upperAligners={upperAligners}
                      chartValue={chartValue}
                      upperAttachments={upperAttachments}
                      lowerAttachments={lowerAttachments}
                    />

                    <AttachmentRow
                      alignersLabel={attachmentsLabel}
                      onAlignerLabelClicked={onAttachmentsLabelClicked}
                      upperAttachments={upperAttachments}
                      lowerAttachments={lowerAttachments}
                      chartValue={chartValue}
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </Row>
      </div>

      <div
        ref={componentRef}
        className="treatment-pdf"
       style={{ display: "none" }}
        id="printablediv"
      >
        <TreatmentPDF
          clinic={clinic}
          patientInfo={patientInfo}
          treatment={treatment}
          lowerAligners={lowerAligners}
          upperAligners={upperAligners}
          chartValue={chartValue}
          upperAttachments={upperAttachments}
          lowerAttachments={lowerAttachments}
          alignersLabel={alignersLabel}
          attachmentsLabel={attachmentsLabel}
          onAlignerLabelClicked={onAttachmentsLabelClicked}
        />
      </div>
    </div>
  );
};

export default IPRDetail;
