// SearchPage.jsx
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Spin, Row, Col, Typography, Select, Input, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import mobilePhone from "../../assets/images/mobilePhone.png";
import "../../assets/styles/components/onboarding/HelpSupport.scss";
import { getPatientCountryCodesList, getClinicProfileSelector } from "services/redux/selectors/clinicSelectors";
import { useEffect } from "react";
import AppButton from "components/shared/AppButton/AppButton";
import { completeProfileStepTwo } from "api/patient";
import { CLINIC_LOADED, COMPLETE_ONBOARDING_STEP_TWO } from "services/redux/actions/types";
import { primaryColor } from "colors-config";
import mixpanel from 'mixpanel-browser';


const { Title } = Typography;

const HelpSupport = () => {
  // let [searchParams, setSearchParams] = useParams();
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const countries = useSelector(getPatientCountryCodesList);
  const clinic = useSelector(getClinicProfileSelector);
  const [supportPrefix, setSupportPrefix] = useState(clinic?.supportPrefix);
  const [form] = Form.useForm();
  const step="Help & Support"

  const dispatch = useDispatch();



  useEffect(()=>{
    setPageLoading(true);
  },[])

  useEffect(() => {
    
    if (clinic) {
      const prefix = clinic?.supportSamePhone ? clinic?.country_code_name : clinic?.supportPrefix;
      const phone = clinic?.supportSamePhone ? clinic?.clinic_contact : clinic?.supportNumber;
      const email = clinic?.supportSameEmail ? clinic?.clinic_email : clinic?.supportEmail;
      form.setFieldsValue({ supportPrefix: prefix, supportNumber: phone, supportEmail: email })
      setSupportPrefix(prefix);
      mixpanel.track('Step 2 Started', { step: "Help & Support", clinic_id:clinic?.clinic_id});
    }
    setPageLoading(false);

  }, [clinic])


  
  // Reading query parameters
  //   let query = searchParams.get("query");
  //   let sort = searchParams.get("sort");
  //   let page = searchParams.get("page");

  // Updating query parameters
  const updateSearchParams = () => {
    // setSearchParams({ query: "sunil" });
  };

  const history = useHistory();
  const onFinish = async (values) => {

    try {
      setLoading(true)
      const supportSamePhone = (clinic?.clinic_contact === values.supportNumber && values.supportPrefix === clinic?.country_code_name);
      const supportSameEmail = clinic?.clinic_email === values.supportEmail;
      const clinicId = clinic?.clinic_id;
      const payload = {
        supportNumber: values.supportNumber,
        supportSamePhone,
        supportSameEmail,
        supportPrefix,
        supportEmail: values.supportEmail
      }

      mixpanel.people.set({
        supportNumber: supportPrefix+" "+values.supportNumber,
        supportEmail: values.supportEmail
      });

      const { data } = await completeProfileStepTwo(payload,clinicId);
      mixpanel.track('Step Transition', { step: "Clinic Setup", clinic_id:clinic?.clinic_id});
      dispatch({
        type: CLINIC_LOADED,
        payload: data.data
      })

      dispatch({
        type: COMPLETE_ONBOARDING_STEP_TWO,
        payload: data.data
      })
      message.success('Contact details updated');
      history.push('/onboarding/clinic-setup');
    } catch (error) {
      message.error('Error updating contact details');
    } finally{
      setLoading(false)
    }
    
  };

  const prefixSelector = (
    <Select size="large"
      showSearch
      value={supportPrefix}
       onChange={(value) => {
        setSupportPrefix(value);
        mixpanel.track('Field Updated', {
          step: step,
          field: 'supportCountryCode',
          value: value,
          clinic_id:clinic?.clinic_id
        });
       }} 
      dropdownClassName="country-dropdown"
      className="country-select"
      dropdownStyle={{ width: "300px" }}
      style={{ width: 110 }}
      labelRender={(props) => {
        return props.label;
      }}
      options={countries?.map((code, index) => {
        return {
          ...code,
          key: index,
          value: code.countryCallingCodes,
          label : <><span className="emoji-font">{code.emoji}</span> {code.countryCallingCodes}</>
        };
      })}
      optionRender={(option) => {
        const { data } = option;
        return (
          <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}>
            <span className="emoji-font">{data.emoji}</span>
            <span>{data.countryCallingCodes}</span>
            <span>| {data.name}</span>
          </div>
        );
      }}
      filterOption={(input, option) => {
        const lowerCasedInput = input.toLowerCase();
        return (
          option.value.toLowerCase().includes(lowerCasedInput) ||
          option.name.toLowerCase().includes(lowerCasedInput)
        );
      }}
    />

  );

  const handleSubmit = () => {
    // form.validateFields().then(() => {
    //   onConfirm(); // Go to next step
    // });
    history.push("/onboarding/clinic-setup");
  };

  return (
    <div className="clinician-details-container">
      <Spin spinning={pageLoading}>
      <Row justify="start">
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 20, offset: 1 }}
          md={{ span: 16, offset: 2 }}
          lg={{ span: 12, offset: 4 }}
          xl={{ span: 12, offset: 4 }}
        >
          <Title level={3} className="support-title">
            Confirm contact details for
            
            <span style={{ color: primaryColor, fontWeight: 800}}> Help and Support</span>
          </Title>
          <Typography.Paragraph className="patient-app-help-text">
            These help and support details will be shared with the patient on
            your patient app
          </Typography.Paragraph>

          <Row justify="end" align="middle">
            <Col span={8} style={{ textAlign: "right" }}>
              <img
                src={mobilePhone}
                alt="Mobile"
                style={{
                  maxWidth: "80%",
                  marginRight: "50px",
                }}
              />
            </Col>
            {/* Right Column - Form */}
            <Col span={16}>
              <Form
                name="clinician_details"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                className="clinician-details-form"
              >
                {/* Clinician Name Input */}
                <Form.Item
                  name="supportEmail"
                >
                  <Input  size={"large"} placeholder="Support Email"
                  // onChange={(e) => {
                  //   mixpanel.track('Field Updated', {
                  //     step: step,
                  //     field: 'supportEmail',
                  //     value: e.target.value,
                  //     clinic_id:clinic?.clinic_id
                  //   });
                  // }}
                  />
                </Form.Item>

                {/* Phone Number Input */}
                <Form.Item
                  name="supportNumber"
                  rules={[
                    () => ({
                      validator(_, value) {
                        const convertedValue = Number(value);
                        if (!value) {
                          return Promise.resolve();
                        } else if (
                          !isNaN(convertedValue) &&
                          (value.length === 9 || value.length === 10 || value.length === 8)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Invalid phone number");
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    addonBefore={prefixSelector}
                    autoComplete="off"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Phone Number"
                    onChange={(e) => {
                      mixpanel.track('Field Updated', {
                        step: step,
                        field: 'supportNumber',
                        value: e.target.value,
                        clinic_id:clinic?.clinic_id
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="end" style={{ position: "absolute", right:0, bottom: '-90px' }}>
                    <AppButton
                      shape="round"
                      type="primary"
                      htmlType="submit"
                      style={{ padding: '0 40px', height:'35px'}}
                      loading={loading}
                      className="guideBtn"  >
                      Confirm
                    </AppButton>
                  </Row>
                </Form.Item>
              </Form>

              {/* Confirm Button Outside the Form */}
            </Col>
          </Row>
        </Col>
      </Row>
      </Spin>
      <div style={{ height: '50px'}}></div>
    </div>
  );
};

export default HelpSupport;
