import  { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { Modal } from "antd";
import {  convertToMinutes } from "../../../utilities/commonMethod";
import {
  getClinicProfileSelector
} from "services/redux/selectors/clinicSelectors";
import { useSelector } from "react-redux";

const AppointmentConfirmationModel = ({
  title,
  content,
  onConfirmation,
  showModal,
  setShow,
  selectedClinician,
  clinicians,
  selectedAppointment,
  mode,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const clinic = useSelector(getClinicProfileSelector);

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
    onConfirmation();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  const appointmentDateTime = useMemo(() => {
    const startMinutes = convertToMinutes(
      selectedAppointment.appointment_start_time
    );
    const endMinutes = convertToMinutes(
      selectedAppointment.appointment_end_time
    );

    return {
      date: moment(selectedAppointment.appointment_date).format("MMM Do YYYY"),
      startTime: moment(selectedAppointment.appointment_date)
        .startOf("day")
        .add(startMinutes, "minutes")
        .format("hh:mm A"),
      endTime: moment(selectedAppointment.appointment_date)
        .startOf("day")
        .add(endMinutes, "minutes")
        .format("hh:mm A"),
    };
  }, [selectedAppointment]);

  // const clinicianName = useMemo(() => {
  //  return clinicians?.filter((clinician) => {
  //     if (clinician.clinician_id === selectedClinician)
  //       return clinician.clinician_name;
  //   })[0]?.clinician_name || "";
  // }, [clinicians, selectedClinician]);

  return (
    <>
      <Modal
        title={title}
        okText="Confirm"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        {mode === "confirm" && (
          <p>
            Are you sure you want to <b>confirm the appointment</b> {content}{" "}
            for <b>{`${appointmentDateTime?.date} `}</b>
            at
            <b>{` ${appointmentDateTime?.startTime}`} {" - "}
            {appointmentDateTime?.endTime}</b> with
            <b>  {clinic?.clinic_name} </b>
          </p>
        )}
        {mode === "attended" && (
          <p>
            Are you sure want change the patient (
            {selectedAppointment.patient_first_name +
              " " +
              selectedAppointment.patient_last_name}
            ) appointment status to attended?
          </p>
        )}
      </Modal>
    </>
  );
};

export default AppointmentConfirmationModel;
