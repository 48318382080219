import { useState, useEffect, useMemo, useContext, useRef } from "react";
import {
  Menu,
  Radio,
  Typography,
  Button,
  message,
  Select,
  Tag,
  Input,
  InputNumber,
  Form,
  Tooltip,
  Divider,
  Row,
  Col,
  Dropdown
} from "antd";
import { DownCircleTwoTone, UserOutlined } from "@ant-design/icons";
import AddOrEditPatient from "../../shared/modals/AddOrEditPatient";
import "../../../assets/styles/components/patientDetailsStyle.scss";
import { ReactComponent as CalendarIcon } from "../../../assets/images/calendar_icon.svg";
import { ReactComponent as SmartphoneIcon } from "../../../assets/images/smartphone_icon.svg";
import { ReactComponent as AddressIcon } from "../../../assets/images/address_icon.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/user_icon.svg";
import { ReactComponent as EmailIcon } from "../../../assets/images/envelope.svg";
import ConfirmationModal from "../../../components/shared/modals/confirmationModal";
import moment from "moment";
import {
  updatePatientTreatmentData,
  updatePaymentStatusApi,
  updatePatEmailApi,
  checkPatientEmail,
  resendRegistrationEmail,
} from "api/patient";
import { PATIENTCONSTANTS, getTreatmentPlanStatus } from "../../../utilities/patientDetail";
import InsuranceModal from "components/shared/modals/InsuranceModal";
import { appCurrencyName, primaryColor, tagBackgroundColor } from "colors-config";
import { JOURNEY_STATUS } from "../../../constants";
import { getClinicProductPrice } from "api/clinic";
import { ReactComponent as EditIcon } from "assets/images/edit_icon.svg";
import dayjs from "dayjs";
import SendAppLinkModal from "components/shared/modals/sendAppLinkModal";
import AppButton from "components/shared/AppButton/AppButton";
import { getAddressView } from "utilities/commonMethod";
import AppSettingsContext from "Context/useAppContext";
import { getDisplayGender } from "utilities/Patient";
import { ReactComponent as SentIcon } from "assets/images/SentIcon.svg";

const { Title } = Typography;
const { Option } = Select;

const ImpressionDecision = {
  Rejected: 0,
  Approved: 1,
  Received: 2,
};

const ImpressionDecisionText = {
  Rejected: "Impressions rejected",
  Approved: "Impressions Approved",
  Received: "Impression image received",
};

const headerStyles = {
  infoSection: {
    display: 'flex',
    gap: '10px',
    // marginLeft: '-200px'
    paddingTop: '10px'
  },
  infoColumn: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'mulish, sans-serif'
  },
  infoLabel: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#77888B',
    marginBottom: '4px',
  },
  infoValue: {
    fontSize: '14px',
    color: '#374151',
    fontWeight: 600
  }
}

const PatientDetailsComponent = ({
  patientInfo,
  getPatientInfo,
  dropdownValues,
  status,
  updateTreatmentOrigin,
  treatmentProgressDet,
  clinic,
  getOrders,
  setPaymentStatus,
  paymentStatus,
  paidStatus,
  setPaidStatus,
  productList,
  patientId,
  patientOrders,
  wholeTreatmentPlan,
  sendAppStage,
  setPatientInfoState,
  labOrders,
  onSendAppClick
}) => {
  const [buttonValue, setButtonValue] = useState("a");
  const [activeTreatmentOrigin, setActiveTreatmentOrigin] = useState("HIK Impressions");
  const [showPopup, setShowPopup] = useState(false);
  const [activeAlignerOrigin, setactiveAlignerOrigin] = useState("Aligner 1/6");
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [showPaymentLoading, setShowPaymentLoading] = useState(false);

  const [insuranceModal, setInsuranceModal] = useState(false);
  const [checkCompleteTreatment, setCheckCompleteTreatment] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [paymentDropdown, setPaymentDropdown] = useState(false);
  const [amountDropdown, setAmountDropdown] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [treatmentEditLoading, setTreatmentEditLoading] = useState(false);
  // const [firstTimeName, setFirstTimeName] = useState(false);
  const [approvedTreatment, setApprovedTreatment] = useState();
  const [labOrderDetails, setLabOrderDetails] = useState();


  const selectRef = useRef(null);

  const [treatmentData, setTreatmentData] = useState({
    treatmentName: "-",
    archesTreated: "-",
    netPrice: 0,
    discount: 0,
    total: 0,
    currencyName: "-",
    discountAmount: 0,
    greyText: "",
  });

  const [isModalSendVisible, setIsModalSendVisible] = useState(false);

  const { clinicConfig } = useContext(AppSettingsContext);

  const appStatus = () => {
    return (
      <>
        {(sendAppStage !== 2 && sendAppStage !== 1) && (
          <AppButton
            type="primary"
            shape="round"
            onClick={() => {
              onSendAppClick();
            }}
            icon={<SentIcon />}
            className="send-appBtn"
            style={{ height: '35px', padding: '5px 15px'}}
          >
            Send App
          </AppButton>
        )}
      </>
    );
  };

  const PatientAppIcon = () => (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.625 18H12.375C13.6181 18 14.625 16.9931 14.625 15.75V2.25C14.625 1.00688 13.6181 0 12.375 0H5.625C4.38188 0 3.375 1.00688 3.375 2.25V15.75C3.375 16.9931 4.38188 18 5.625 18ZM4.5 2.25H13.5V15.75H4.5V2.25Z" fill={primaryColor} />
      <path d="M6 9.20433L7.68 11L12 7" stroke={primaryColor} strokeLinecap="round" />
    </svg>
  )

  const onIconCLick = () => {
    setShowPatientEmailModal(true);
    setIsModalSendVisible(true);
  };

  useEffect(() => {
    if (wholeTreatmentPlan?.length) {
      const approvedTreatment = wholeTreatmentPlan?.find((obj) => obj.approved) || false;

      setApprovedTreatment(approvedTreatment ? approvedTreatment : wholeTreatmentPlan[0]);
    }
  }, [wholeTreatmentPlan]);

  useEffect(() => {
    if (treatmentProgressDet?.prescriptionId && !patientOrders.length) {
      setShowEditButton(true);
    } else {
      setShowEditButton(false);
    }
  }, [patientOrders]);
  useEffect(() => {
    if (labOrders && labOrders.length) {
      setLabOrderDetails(labOrders[0]);
    }
  }, [labOrders]);

  useEffect(() => {
    if (treatmentProgressDet) {

      let paymentStatus = "-";
      if (treatmentProgressDet?.payment_status) {
        paymentStatus = treatmentProgressDet.payment_status;
      } else if (patientInfo?.payment_status) {
        paymentStatus = patientInfo.payment_status;
      } else {
        paymentStatus = "-";
      }
      if (
        patientInfo?.payment_status == "Free" ||
        patientInfo?.payment_status == "Partially Paid"
      ) {
        paymentStatus = patientInfo.payment_status;
      }
      setPaidStatus(paymentStatus);
      setPaymentStatus(paymentStatus);

      const tempTreatmentData = {};
      if (treatmentProgressDet?.treatment_name) {
        tempTreatmentData.treatmentName = treatmentProgressDet?.treatment_name;
      }
      if (treatmentProgressDet?.arches_treated) {
        tempTreatmentData.archesTreated = treatmentProgressDet?.arches_treated;
      }
      if (typeof Number(treatmentProgressDet?.treatment_price)) {
        tempTreatmentData.netPrice = Number(treatmentProgressDet.treatment_price);
        tempTreatmentData.total = Number(treatmentProgressDet?.treatment_price);
        tempTreatmentData.discount = 0;
        tempTreatmentData.discountAmount = 0;
      }
      if (treatmentProgressDet?.currency_name) {
        tempTreatmentData.currencyName = treatmentProgressDet?.currency_name;
      }

      if (typeof Number(treatmentProgressDet?.discount)) {
        tempTreatmentData.discount = getDiscountPercentage(
          treatmentProgressDet.treatment_price,
          treatmentProgressDet.discount
        );
        tempTreatmentData.discountAmount = treatmentProgressDet.discount;
        tempTreatmentData.total =
          Number(treatmentProgressDet.treatment_price) !== 0
            ? Number(treatmentProgressDet.treatment_price) - treatmentProgressDet.discount
            : 0;
      }

      setTreatmentData(tempTreatmentData);
    }
  }, [patientInfo, treatmentProgressDet]);

  const calculateTotalPrice = (price, discount) => {
    const numVal1 = Number(price);
    const numVal2 = Number(discount);

    const totalValue = numVal1 * ((100 - numVal2) / 100);
    return Math.ceil(totalValue);
  };
  useEffect(() => {
    if (status) {
      if (status.patient_treatment_origin_name) {
        setActiveTreatmentOrigin(status.patient_treatment_origin_name);
      }
      if (status.clinic_patient_journey_status_name == JOURNEY_STATUS.TREATMENT_COMPLETED) {
        setCheckCompleteTreatment(true);
      } else {
        setCheckCompleteTreatment(false);
      }
    }
  }, [status]);

  const companyType = clinic?.companyType ? clinic.companyType : 1;

  const onButtonClick = (e) => {
    setButtonValue(e.target.value);
  };
  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          if (dropdownValues?.data[0].treatment_origin_name) {
            setActiveTreatmentOrigin(dropdownValues?.data[0].treatment_origin_name);
            updateTreatmentOrigin(dropdownValues?.data[0].treatment_origin_id);
          } else {
          }
        }}
        className="noselect"
      >
        {dropdownValues?.data[0].treatment_origin_name
          ? dropdownValues.data[0].treatment_origin_name
          : "-"}
      </Menu.Item>
      <Menu.Item
        key="1"
        className="noselect"
        onClick={() => {
          if (dropdownValues?.data[1].treatment_origin_name) {
            setActiveTreatmentOrigin(dropdownValues?.data[1].treatment_origin_name);
            updateTreatmentOrigin(dropdownValues?.data[1].treatment_origin_id);
          } else {
          }
        }}
      >
        {dropdownValues?.data[1].treatment_origin_name
          ? dropdownValues.data[1].treatment_origin_name
          : "-"}
      </Menu.Item>
      <Menu.Item
        key="3"
        className="noselect"
        onClick={() => {
          if (dropdownValues?.data[2].treatment_origin_name) {
            setActiveTreatmentOrigin(dropdownValues?.data[2].treatment_origin_name);
            updateTreatmentOrigin(dropdownValues?.data[2].treatment_origin_id);
          }
        }}
      >
        {dropdownValues?.data[2].treatment_origin_name
          ? dropdownValues.data[2].treatment_origin_name
          : "-"}
      </Menu.Item>
    </Menu>
  );
  const patientAddress = useMemo(() => {
    if (patientInfo) {
      const address = `${patientInfo?.patient_address || ""}${patientInfo?.patient_address && patientInfo?.patient_city ? "," : ""
        } ${patientInfo?.patient_city || ""}${patientInfo?.patient_county && patientInfo?.patient_city ? "," : ""
        } ${patientInfo?.patient_county || ""}${patientInfo?.patient_county && patientInfo?.patient_postal_code ? "," : ""
        } ${patientInfo?.patient_postal_code || ""}${patientInfo?.patient_country && patientInfo?.patient_postal_code ? "," : ""
        } ${patientInfo?.patient_country || ""}`;
      if (address === "" || !address.replace(/\s/g, "").length) return "-";
      return address;
    }
    return "-";
  }, [patientInfo]);

  const alignerMenu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setactiveAlignerOrigin("Aligner 2/6");
        }}
        className="noselect"
      >
        Aligner 2/6
      </Menu.Item>
      <Menu.Item
        key="1"
        className="noselect"
        onClick={() => {
          setactiveAlignerOrigin("Aligner 3/6");
        }}
      >
        Aligner 3/6
      </Menu.Item>
      <Menu.Item
        key="3"
        className="noselect"
        onClick={() => {
          setactiveAlignerOrigin("Aligner 4/6");
        }}
      >
        Aligner 4/6
      </Menu.Item>
    </Menu>
  );

  const handleSelectClick = (e) => {
    e.stopPropagation()
  }

  const handlePaymentStatusChange = (value) => {
    console.log('val', value)
    if (value == "-" || value == "Refunded") {
      value = "";
    }
    setPaymentStatus(value);
  };

  const cancelPaymentStatus = () => {
    setPaymentStatus(paidStatus);
  };

  const updatePaymentStatus = async () => {
    try {
      setShowPaymentLoading(true);

      let value = paymentStatus;

      const data = {
        patientId: patientInfo.patient_id,
        status: value,
      };

      await updatePaymentStatusApi(data);
      if (value) {
        getOrders();
      }
      message.success("Payment status updated");
      if (value == "") {
        setPaidStatus("-");
        setPaymentStatus("-");
      }
      setPaidStatus(value);
    } catch (err) {
      message.error("Some error occured!");
    } finally {
      setShowPaymentLoading(false);
    }
  };

  const onInsuranceModalOpen = () => {
    setInsuranceModal(true);
  };
  const onInsuranceModalClose = () => {
    setInsuranceModal(false);
  };
  const onInsuranceModalOk = () => {
    setInsuranceModal(false);
  };

  const onPatientTreatmentClose = () => {
    setEditOpen(false);
  };


  const onPatientTreatmentSaveClick = async () => {
    try {
      setTreatmentEditLoading(true);
      if (treatmentProgressDet?.prescriptionId) {
        const inputData = {
          clinicId: clinic.clinicId,
          patientId: patientId,
          netPrice: treatmentData.netPrice,
          discount: treatmentData.discountAmount,
          archesTreated: treatmentData.archesTreated,
          treatmentName: treatmentData.treatmentName,
        };
        await updatePatientTreatmentData(treatmentProgressDet?.prescriptionId, inputData);
        message.success("Treatment updated successfully");
        onPatientTreatmentClose();
      }
    } catch (error) {
      message.error("Error occured");
    } finally {
      setTreatmentEditLoading(false);
    }
  };

  const onTreatmentDataChange = async (value, key) => {
    setTreatmentData({
      ...treatmentData,
      [key]: value,
    });
  };

  const onAmountChange = async (value, key) => {
    if (key == "discount") {
      const totalValue = calculateTotalPrice(treatmentData.netPrice, value);
      const tempTreatmentData = { ...treatmentData };
      tempTreatmentData.total = totalValue;
      tempTreatmentData.discount = value;
      tempTreatmentData.discountAmount = treatmentData.netPrice - totalValue;
      setTreatmentData(tempTreatmentData);
    }
    if (key == "discountAmount") {
      const tempTreatmentData = { ...treatmentData };
      tempTreatmentData.total = treatmentData.netPrice - value;
      tempTreatmentData.discount = getDiscountPercentage(treatmentData.netPrice, value);
      tempTreatmentData.discountAmount = value;
      setTreatmentData(tempTreatmentData);
    }
  };

  const getDiscountPercentage = (netPrice, value) => {
    const total = Number(netPrice) - Number(value);
    const diffPercentage = (total / netPrice) * 100;
    const actualPercentage = Number(100 - diffPercentage).toFixed(1);
    return actualPercentage;
  };

  const onTreatmentNameChange = async (value) => {
    const productPriceRes = await getClinicProductPrice({
      productName: value,
      clinicId: clinic.clinicId,
    });
    if (productPriceRes?.data) {

      const netPrice = productPriceRes.data.price;
      const discountAmount = productPriceRes?.data.free
        ? productPriceRes.data.price
        : productPriceRes.data.discount;
      const totalValue = netPrice - discountAmount;

      const tempTreatmentData = { ...treatmentData };
      tempTreatmentData.total = totalValue;
      tempTreatmentData.netPrice = netPrice;
      tempTreatmentData.discount = getDiscountPercentage(
        productPriceRes.data.price,
        discountAmount
      );
      tempTreatmentData.discountAmount = discountAmount;
      tempTreatmentData.treatmentName = value;

      setTreatmentData(tempTreatmentData);
    }
  };
  const [editPatientEmailForm] = Form.useForm();
  const [showPatientEmailModal, setShowPatientEmailModal] = useState(false);

  const onEditEmailPatient = async () => {
    try {
      const res = await editPatientEmailForm.validateFields();
      const obj = {
        patient_id: patientId,
      };

      obj.email = res.email;
      const checkPatientDetails = {
        email: res.email,
      };

      if (patientInfo.patient_email != res.email) {
        const checkPatientResponse = await checkPatientEmail(checkPatientDetails);
        if (checkPatientResponse?.data?.data?.emailExists) {
          throw new Error("Patient Email already Exists. Assign new Email");
        }
      }

      await resendRegistrationEmail(obj);
      message.success("Email has been sent successfully.");

      setShowPatientEmailModal(false);
      setIsModalSendVisible(false);
      setPatientInfoState({
        ...patientInfo,
        patient_email: res.email,
      });
    } catch (error) {
      message.error(error?.values ? "Please enter patient email" : error.message);
    }
  };

  const handleAppLinkOk = () => {
    onEditEmailPatient();
  };
  const handleAppLinkCancel = () => {
    setIsModalSendVisible(false);
    setShowPatientEmailModal(false);
  };
  return (
    <div
      style={{ width: "100%" }}
      className={`${companyType == 3 ? "smiyle-nation-container" : ""}`}
    >
      {showPatientEmailModal && (
        <div className="send-app-confirmation-modal">
          <SendAppLinkModal
            title="Send App Registration"
            isModalVisible={isModalSendVisible}
            setIsModalSendVisible={setIsModalSendVisible}
            handleOk={handleAppLinkOk}
            handleCancel={handleAppLinkCancel}
            content={
              <Form
                form={editPatientEmailForm}
                name="sendPatientEmail"
                initialValues={{
                  email: patientInfo.patient_email,
                }}
                layout="vertical"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please enter patient email",
                    },
                  ]}
                  name="email"
                  label="Email"
                >
                  <Input />
                </Form.Item>
              </Form>
            }
            showModal={showPatientEmailModal}
            onConfirmation={onEditEmailPatient}
            setShow={setShowPatientEmailModal}
            onCancel={() => setShowPatientEmailModal(false)}
          />
        </div>
      )}
      {showPopup && (
        <AddOrEditPatient
          title={
            <div className="titleSpan">
              <img
                src={require("../../../assets/images/editUser.png")}
                style={{ width: "30px" }}
                alt=""
              />
              <Title level={5} style={{ margin: "0px" }}>
                EDIT PATIENT
              </Title>
            </div>
          }
          IsEdit={true}
          content={patientInfo}
          showModal={showPopup}
          onConfirmation={getPatientInfo}
          setShow={setShowPopup}
        />
      )}
      {showPaymentModal && (
        <ConfirmationModal
          title="Payment"
          content={"Are you sure you want to change the payment status?"}
          showModal={showPaymentModal}
          onConfirmation={updatePaymentStatus}
          setShow={setShowPaymentModal}
          onCancel={cancelPaymentStatus}
        ></ConfirmationModal>
      )}
      {insuranceModal && (
        <InsuranceModal
          insuranceModal={insuranceModal}
          onInsuranceModalOpen={onInsuranceModalOpen}
          onInsuranceModalClose={onInsuranceModalClose}
          onInsuranceModalOk={onInsuranceModalOk}
          patientInfo={patientInfo}
        />
      )}

      <div
        className={
          buttonValue == "a"
            ? "patient-detail-container"
            : "patient-detail-container-patientInfo"
        }
      >
        <Row className="patient-detail-topRow">
          <Col span={status?.clinic_patient_journey_status_name === 'Lead Stage' ? 5 : 12}><div className="patient-detail-topRow-profile"> <div className="profile-icon" style={{ backgroundColor: '#FAFAFA' }}>
            <UserIcon className="user-icon-image" alt="Edit" />
          </div>
            <div className="patient-name">
              <div
                className={`subheading ${process.env.REACT_APP_COMPANY_TYPE == 5
                  ? "subheading-smilealign"
                  : ""
                  }`}
                onClick={() => {
                  setShowPopup(!showPopup);
                }}
              >
                {patientInfo?.patient_first_name
                  ? patientInfo.patient_first_name
                  : "-"}{" "}
                {patientInfo?.patient_last_name
                  ? patientInfo.patient_last_name
                  : "-"}
                {
                  <EditIcon
                    className="hoverIcon"
                    style={{
                      width: "14px",
                      marginLeft: "5px",
                      paddingTop: "3px",
                      cursor: "pointer",
                      marginTop: "-15px",
                      fill: primaryColor,
                    }}
                  />
                }
              </div>
              <div className="caption">
                {patientInfo?.patient_id ? patientInfo.patient_id : "-"}
              </div>
            </div></div></Col>
          {status?.clinic_patient_journey_status_name === 'Lead Stage' && <Col span={7}> <div style={headerStyles.infoSection}>
            <div style={headerStyles.infoColumn}>
              <div style={{ textAlign: 'left' }}>
                <span style={headerStyles.infoLabel}>Journey Status:</span>
                <span style={{...headerStyles.infoValue, paddingLeft: '5px'}}>{status?.clinic_patient_journey_status_name}</span>
              </div>
              <div style={{ textAlign: 'left' }}>
                <span style={headerStyles.infoLabel}>Status Updated Date:</span>
                <span style={{...headerStyles.infoValue, paddingLeft: '5px'}}>{approvedTreatment?.updatedAt ? dayjs(approvedTreatment?.updatedAt).format(
                  "DD-MM-YYYY"
                ) : '--'}</span>
              </div>
            </div>
            <Divider type="vertical" plain style={{ height: '40px', color: '#77888B' }} />
            <div style={headerStyles.infoColumn}>
              <div style={{ textAlign: 'left' }}>
                <span style={headerStyles.infoLabel}>Patient App</span>
                <div style={{ display: 'flex', alignItems: 'left' }}>
                  {sendAppStage === 1 ? <span style={headerStyles.infoValue} >Patient Registered<UserOutlined fill={primaryColor} style={{ fontSize: '16px', color: primaryColor, marginLeft: '4px' }} /></span> : sendAppStage === 2 ? <span style={headerStyles.infoValue}>Patient Logged In</span> : <span style={{paddingTop:"5px"}}>{appStatus()}</span>}
                </div>
              </div>
            </div>
          </div></Col>}
          <Col span={status?.clinic_patient_journey_status_name === 'Lead Stage' ? 8 : 8} offset={4}>
            <div className="patient-detail-topRow-switcher">
              <Radio.Group
                onChange={onButtonClick}
                defaultValue="a"
                buttonStyle="solid"
              >
                <Radio.Button className="noselect" value="a">
                  Treatment Statuses
                </Radio.Button>
                <Radio.Button className="noselect" value="b">
                  Patient info{" "}
                </Radio.Button>
              </Radio.Group>
            </div>
          </Col>
        </Row>

        <div className={(status?.clinic_patient_journey_status_name !== 'Lead Stage') ? "patient-detail-bottomRow patient-detail-infoRow" : ''}>
          {(status?.clinic_patient_journey_status_name !== 'Lead Stage') && (!treatmentData?.treatmentName ? <div className={buttonValue == "a" ? "treatment-progress" : "hidden"}>
            <div className="treatment-progress__left">
              <div className="treatment-progress__section">
                <h3 className="treatment-progress__heading">Treatment Progress</h3>
                <span className="treatment-progress__aligner-badge" style={{ color: primaryColor, backgroundColor: tagBackgroundColor }}>{process.env.REACT_APP_COMPANY_TYPE == 4
                  ? "Stage"
                  : "Aligner"} {treatmentProgressDet?.current_aligner_number
                    ? treatmentProgressDet.current_aligner_number
                    : "-"} / {treatmentProgressDet?.total_aligners
                      ? treatmentProgressDet.total_aligners
                      : "-"}</span>
              </div>
              <div className="treatment-progress__section">
                <div className="treatment-progress__info-group">
                  <span className="treatment-progress__label">{checkCompleteTreatment
                    ? "Date Completed"
                    : "Estimated completion date"}:</span>
                  <span className="treatment-progress__value">{treatmentProgressDet?.estimated_completion_date
                    ? moment(
                      treatmentProgressDet.estimated_completion_date
                    ).format("DD, MMM, YYYY")
                    : "-"}</span>
                </div>
                <div className="treatment-progress__info-group">
                  <span className="treatment-progress__label">Time elapsed on current stage:</span>
                  <span className="treatment-progress__value">{checkCompleteTreatment
                    ? "-"
                    : treatmentProgressDet?.time_elapsed_on_current_stage
                      ? treatmentProgressDet.time_elapsed_on_current_stage
                      : "-"}</span>
                </div>
              </div>
              <div className="treatment-progress__section">
                <div className="treatment-progress__info-group">
                  <span className="treatment-progress__label">Next Aligner Change date:</span>
                  <span className="treatment-progress__value">{checkCompleteTreatment
                    ? "-"
                    : treatmentProgressDet?.next_aligner_change_date
                      ? moment(
                        treatmentProgressDet.next_aligner_change_date
                      ).format("DD, MMM, YYYY")
                      : "-"}</span>
                </div>
                <div className="treatment-progress__info-group">
                  <span className="treatment-progress__label">Time elapsed on treatment:</span>
                  <span className="treatment-progress__value">{treatmentProgressDet?.time_elapsed_on_treatment
                    ? treatmentProgressDet.time_elapsed_on_treatment
                    : "-"}</span>
                </div>
              </div>
            </div>
            <div className="treatment-progress__right">
              <Row>
                <div className="treatment-progress__info-group">
                  <span className="treatment-progress__label">Journey Status:</span>
                  <span className="treatment-progress__value" style={{ color: primaryColor, fontWeight: "bold" }}>{status?.clinic_patient_journey_status_name
                    ? status.clinic_patient_journey_status_name
                    : "-"}</span>
                </div>
                <div className="treatment-progress__info-group" style={{ paddingLeft: '30px' }}>
                  <span className="treatment-progress__label">Patient App</span>
                  <span className="treatment-progress__value">{sendAppStage === 1 ? <>Patient Registered<UserOutlined fill={primaryColor} style={{ fontSize: '16px', color: primaryColor, marginLeft: '4px' }} /></> : sendAppStage === 2 ? 'Patient Logged In' : appStatus()}</span>
                </div>
              </Row>
              <Row >
                <div className="treatment-progress__info-group">
                  <span className="treatment-progress__label">Status Updated Date:</span>
                  <span className="treatment-progress__value">{approvedTreatment?.updatedAt ? dayjs(approvedTreatment?.updatedAt).format(
                    "DD-MM-YYYY"
                  ) : '-'}</span>
                </div>
              </Row>


            </div>

          </div> :
            <div className={buttonValue == "a" ? "therapy-tracker" : "hidden"}>
              <div className="therapy-tracker__section therapy-tracker__section--progress">
                <div className="therapy-tracker__column">
                  <h3 className="therapy-tracker__heading">Treatment Progress</h3>
                  <div className="therapy-tracker__aligner" style={{ color: primaryColor, backgroundColor: tagBackgroundColor,fontWeight:"bold", marginTop:"-15px" }}>{process.env.REACT_APP_COMPANY_TYPE == 4
                    ? "Stage"
                    : "Aligner"} {treatmentProgressDet?.current_aligner_number
                      ? treatmentProgressDet.current_aligner_number
                      : "-"} / {treatmentProgressDet?.total_aligners
                        ? treatmentProgressDet.total_aligners
                        : "-"}</div>
                  <div className="therapy-tracker__info">
                    <span className="therapy-tracker__label">{checkCompleteTreatment
                      ? "Date Completed"
                      : "Estimated completion date"}:</span>
                    <span className="therapy-tracker__value">{treatmentProgressDet?.estimated_completion_date
                      ? moment(
                        treatmentProgressDet.estimated_completion_date
                      ).format("DD, MMM, YYYY")
                      : "-"}</span>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '32px' }}>
                  <Divider type="vertical" style={{ height: '25px' }} />
                  <Divider type="vertical" style={{ height: '25px' }} />
                  <Divider type="vertical" style={{ height: '25px' }} />
                </div>
                <div className="therapy-tracker__column" style={{ marginLeft: '5px' }}>
                  <div className="therapy-tracker__info">
                    <span className="therapy-tracker__label">Time Elapsed On Current Stage:</span>
                    <span className="therapy-tracker__value">{checkCompleteTreatment
                      ? "-"
                      : treatmentProgressDet?.time_elapsed_on_current_stage
                        ? treatmentProgressDet.time_elapsed_on_current_stage
                        : "-"}</span>
                  </div>
                  <div className="therapy-tracker__info">
                    <span className="therapy-tracker__label">Next Aligner Change Date:</span>
                    <span className="therapy-tracker__value">{checkCompleteTreatment
                      ? "-"
                      : treatmentProgressDet?.next_aligner_change_date
                        ? moment(
                          treatmentProgressDet.next_aligner_change_date
                        ).format("DD, MMM, YYYY")
                        : "-"}</span>
                  </div>
                  <div className="therapy-tracker__info">
                    <span className="therapy-tracker__label">Time elapsed on treatment:</span>
                    <span className="therapy-tracker__value">{treatmentProgressDet?.time_elapsed_on_treatment
                      ? treatmentProgressDet.time_elapsed_on_treatment
                      : "-"}</span>
                  </div>
                </div>
              </div>

              <div className="therapy-tracker__section therapy-tracker__section--visible">
                <div className="therapy-tracker__column">
                  <h3 className="therapy-tracker__heading">Visible to Patient</h3>
                  <div className="therapy-tracker__info">
                    <span className="therapy-tracker__label">Treatment Name:</span>
                    <span className="therapy-tracker__value">{treatmentData.treatmentName ? (
                      treatmentData.treatmentName
                    ) :
                      "-"}</span>
                  </div>
                  {(treatmentData?.total !== undefined && treatmentData?.total !== 0) && <div className="therapy-tracker__info price-editor">
                    <span className="therapy-tracker__label">Price:</span>
                    <div className="therapy-tracker__price" style={{ justifyContent: 'space-between' }}>
                      <span className="therapy-tracker__value">{treatmentData?.currencyName
                        ? treatmentData.currencyName
                        : ""}{" "}
                        {treatmentData?.total !== undefined
                          ? treatmentData.total
                          : "-"}</span>
                      {paidStatus != "Paid" && 
                      <Dropdown
                        placement="bottomRight"
                        visible={amountDropdown}
                        onVisibleChange={(visible) => setAmountDropdown(visible)}
                        overlay={
                          <Menu>
                            <Menu.Item key="payment">
                              <div className="price-dropDown">
                                <div className="row-one">
                                  <p>Price</p>
                                  <Input
                                    size="small"
                                    type="number"
                                    placeholder="Price"
                                    value={treatmentData?.netPrice}
                                    onChange={(event) => { onTreatmentDataChange(event.target.value, "netPrice") }}
                                    // onChange={onNameChange}
                                    onKeyDown={e => e.stopPropagation()}
                                    suffix={
                                      <b style={{ color: primaryColor }}>
                                        {treatmentData?.currencyName || appCurrencyName.currency_name}
                                      </b>
                                    }
                                  />
                                </div>

                                <div className="row-one">
                                  <p>Discount</p>
                                  <div className="row-two">
                                    <InputNumber
                                      size="medium"
                                      type="number"
                                      max={100}
                                      min={0}
                                      placeholder="Discount"
                                      // className="discount-amount-input"
                                      suffix="%"
                                      controls={false}
                                      value={treatmentData?.discount || 0}
                                      onChange={value => {
                                        onAmountChange(value, "discount");
                                      }}
                                      // value={0}
                                      // onChange={onNameChange}
                                      onKeyDown={e => e.stopPropagation()}
                                    />
                                    <InputNumber
                                      size="medium"
                                      type="number"
                                      className="discount-amount-input"
                                      controls={false}
                                      max={treatmentData.netPrice}
                                      min={0}
                                      placeholder="Amount"
                                      suffix={
                                        <b style={{ color: primaryColor }}>
                                          {treatmentData.currencyName || appCurrencyName.currency_name}
                                        </b>
                                      }
                                      value={treatmentData.discountAmount}
                                      onChange={value => {
                                        onAmountChange(value, "discountAmount");
                                      }}
                                      // onChange={onNameChange}
                                      onKeyDown={e => e.stopPropagation()}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="price-footer"
                                >
                                  <p>Discount Price</p>
                                  <b>
                                    {treatmentData.currencyName}{" "}
                                    {parseInt(treatmentData.netPrice) -
                                      parseInt(treatmentData.discountAmount)}{" "}{treatmentData.currencyName || appCurrencyName.currency_name}
                                  </b>
                                </div>
                              </div>
                              <div className="price-editor__actions">
                                <Button
                                  // className="price-editor__button primary"
                                  onClick={onPatientTreatmentSaveClick}
                                  loading={treatmentEditLoading}
                                  style={{ width: '100%', height: '35px' }}
                                  type="primary"
                                // onClick={handleSave}
                                >
                                  Save
                                </Button>
                              </div>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <EditIcon
                          style={{
                            width: "14px",
                            marginLeft: "5px",
                            paddingTop: "3px",
                            cursor: "pointer",
                            marginTop: "-15px",
                            fill: primaryColor,
                          }}
                        />
                      </Dropdown>}
                    </div>
                  </div>}


                </div>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
                  <div className="therapy-tracker__info" style={{ rowGap: '32px' }}>
                    <span className="therapy-tracker__label"></span>
                    <span className="therapy-tracker__value"></span>
                  </div>
                  <Divider type="vertical" style={{ height: '30px' }} />
                  {(treatmentData?.total !== undefined && treatmentData?.total !== 0) && <Divider type="vertical" style={{ height: '30px' }} />}
                </div>
                <div className="therapy-tracker__column" style={{ marginLeft: '5px' }}>
                  <div className="therapy-tracker__info" style={{ rowGap: '32px' }}>
                    <span className="therapy-tracker__label"></span>
                    <span className="therapy-tracker__value"></span>
                  </div>
                  <div className="therapy-tracker__info">
                    <span className="therapy-tracker__label">Arches Treated:</span>
                    <span className="therapy-tracker__value">{treatmentData.archesTreated ? (
                      treatmentData.archesTreated
                    ) : (
                      "-"
                    )}</span>
                  </div>
                  {(treatmentData?.total !== undefined && treatmentData?.total !== 0) && <div className="therapy-tracker__info price-editor">
                    <span className="therapy-tracker__label">Payment Status:</span>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span className="therapy-tracker__value" >{paymentStatus}</span>
                      {(!paidStatus ||
                        paidStatus != "Paid" ||
                        paidStatus == "Partially Paid") &&
                        <Dropdown
                          visible={paymentDropdown}
                          onVisibleChange={(visible) => setPaymentDropdown(visible)}
                          placement="bottomRight"
                          overlay={
                            <Menu>
                              <Menu.Item key="payment">
                                <div className="price-dropDown">
                                  <Select
                                    ref={selectRef}
                                    size="large"
                                    defaultValue="-"
                                    value={paymentStatus}
                                    loading={showPaymentLoading}
                                    // className="patient-payment-status-select"
                                    style={{ margin: '0 15px', width: '250px' }}
                                    onChange={handlePaymentStatusChange}
                                    options={[
                                      { value: "-", label: "-" },
                                      {
                                        value: "Awaiting Payment",
                                        label: "Awaiting Payment",
                                      },
                                      { value: "Paid", label: "Paid" },
                                      { value: "Cancelled", label: "Cancelled" },
                                      { value: "Partially Paid", label: "Partially Paid" },
                                      { value: "Refunded", label: "Refunded" },
                                      { value: "Free", label: "Free" },
                                    ]}
                                  />
                                </div>
                                <div className="price-editor__actions">
                                  <Button
                                    onClick={updatePaymentStatus}
                                    loading={showPaymentLoading}
                                    style={{ width: '100%', height: '35px' }}
                                    type="primary"
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={['click']}>
                          <EditIcon
                            style={{
                              width: "14px",
                              marginLeft: "5px",
                              paddingTop: "3px",
                              cursor: "pointer",
                              marginTop: "-15px",
                              fill: primaryColor,
                            }}
                          />
                        </Dropdown>}
                    </div>
                  </div>}
                </div>
              </div>

              <div className="therapy-tracker__section therapy-tracker__section--status">
                <div className="therapy-tracker__column">
                  <div className="therapy-tracker__info">
                    <span className="therapy-tracker__label" style={{ fontSize: '16px', fontWeight: 600 }}>Journey Status:</span>
                    <span className="therapy-tracker__value" style={{ color: primaryColor,fontWeight: "bold" }}>{status?.clinic_patient_journey_status_name
                      ? status.clinic_patient_journey_status_name
                      : "-"}</span>
                  </div>
                  <div className="therapy-tracker__info">
                    <span className="therapy-tracker__label">Status Updated Date:</span>
                    <span className="therapy-tracker__value">{approvedTreatment?.updatedAt ? dayjs(approvedTreatment?.updatedAt).format(
                      "DD-MM-YYYY"
                    ) : '-'}</span>
                  </div>
                  <div className="therapy-tracker__info">
                    <span className="therapy-tracker__label">Patient App</span>
                    <div className="therapy-tracker__patient-status">
                      <span className="therapy-tracker__value">{sendAppStage === 1 ? <>Patient Registered<UserOutlined fill={primaryColor} style={{ fontSize: '16px', color: primaryColor, marginLeft: '4px' }} /></> : sendAppStage === 2 ? 'Patient Logged In' : appStatus()}</span>

                    </div>
                  </div>
                </div>
              </div>
            </div>)}

          <div className={buttonValue == "b" ? "patient-info" : "hidden"}>
            <div
              style={{
                height: "80%",
                width: "200px",
                marginTop: "10px",
                marginLeft: "10px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginLeft: "-12px" }} className="caption">
                DOB
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "-16px",
                  marginTop: "5px",
                  gap: "5px",
                }}
              >
                <CalendarIcon stroke={primaryColor} />
                <div className="content" style={{ marginLeft: "-20px" }}>
                  {patientInfo?.patient_date_of_birth
                    ? moment(patientInfo.patient_date_of_birth).format(
                      "DD/MM/YYYY"
                    )
                    : "-"}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "80%",
                width: "200px",
                marginTop: "10px",
                marginLeft: "10px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginLeft: "-12px" }} className="caption">
                Gender
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "-16px",
                  marginTop: "5px",
                  gap: "5px",
                }}
              >
                <UserOutlined style={{ color: primaryColor }} />
                <div className="content" style={{ marginLeft: "-24px" }}>
                  <p className="gender">
                    <Tooltip title={getDisplayGender(patientInfo)} placement="topLeft">
                      <span>{getDisplayGender(patientInfo)}</span>
                    </Tooltip>
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "80%",
                width: "200px",
                marginTop: "10px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginLeft: "-4px" }} className="caption">
                Mobile No
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "-9px",
                  marginTop: "5px",
                  gap: "5px",
                }}
              >
                <SmartphoneIcon stroke={primaryColor} />
                <div className="content" style={{ marginLeft: "-28px" }}>
                  {patientInfo?.patient_country_code_name
                    ? `${patientInfo?.patient_country_code_name}-`
                    : ""}
                  {patientInfo?.patient_phone_number
                    ? patientInfo.patient_phone_number
                    : "-"}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "80%",
                width: "350px",
                marginTop: "10px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginLeft: "-4px" }} className="caption">
                Email Id
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "4px",
                  marginTop: "5px",
                  gap: "5px",
                }}
                className="editIconHover"
              >
                <EmailIcon
                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                  alt="email"
                  fill={primaryColor}
                />
                <div className="content" style={{ marginLeft: "-12px" }}>
                  {patientInfo?.patient_email ? patientInfo.patient_email : "-"}
                </div>
                <div>
                  {" "}
                  {sendAppStage == 0 ? (
                    <EditIcon
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                      fill={primaryColor}
                      className="editIcon "
                      onClick={onIconCLick}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "80%",
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="caption" style={{ textAlign: "left" }}>
                Clinic Details
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "-7px",
                  marginTop: "5px",
                  gap: "5px",
                }}
              >
                <UserOutlined style={{ color: primaryColor }} />
                <div
                  className="content"
                  style={{ marginLeft: "-25px", width: "100%" }}
                >
                  {clinic.clinicName}
                  <span className="caption" style={{ fontSize: "14px" }}> {getAddressView(clinic.clinicProfile)}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

export default PatientDetailsComponent;
