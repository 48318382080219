import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  ALIGNERS_IN_PRODUCTION_STATUS,
  ALIGNERS_SHIPPED_STATUS,
  CURRENT_ALIGNER_FRONT_SIDE,
  PROGRESS_IMAGES_ALIGNER_ORDER,
  PROGRESS_IMAGES_ORDER,
} from "./constants";

export const makePatientAddress = (patientData) => {
  const patientAddress = `${patientData?.patient_address || ""}${
    patientData?.patient_address && patientData?.patient_city ? "," : ""
  } ${patientData?.patient_city || ""}${
    patientData?.patient_county && patientData?.patient_city ? "," : ""
  } ${patientData?.patient_county || ""}${
    patientData?.patient_county && patientData?.patient_postal_code ? "," : ""
  } ${patientData?.patient_postal_code || ""}${
    patientData?.patient_country && patientData?.patient_postal_code ? "," : ""
  } ${patientData?.patient_country || ""}`;

  return patientAddress;
};

export const PATIENTCONSTANTS = {
  LIGHT: "light",
  EXTENDING: "extending",
  Full: "full",
  COMPOSITE_BONDING: "composite-bonding",
  WHITENING: "whitening",
  REFINEMENT: "refinement",
  UPPER: "Upper",
  LOWER: "Lower",
  UPPER_AND_LOWER: "Upper and Lower",
};

export const patientStatusMapper = (details) => {
  return {
    ...details,
    tpRequestedData: details?.tpRequestedData
      ? dayjs(details.tpRequestedData).format("DD-MM-YYYY")
      : "--",
  };
};

export const getOrderMessage = (quantity, labProductId) => {
  if (!labProductId) {
    return "Lab Product/Service Name is required";
  }
  if (!quantity) {
    return "Lab Product/Service quantity is required";
  }
  return null; // No errors
};


function containsAlignerImages(data) {
  return data.some(item => item.patient_image_view === "CurrentAlignerFrontSide" || item.imageViewType === "CurrentAlignerFrontSide");
}

export const sortPhotosByOrder = (photos) => {
  const alignerCheck = containsAlignerImages(photos) 
  const order = alignerCheck ? PROGRESS_IMAGES_ALIGNER_ORDER : PROGRESS_IMAGES_ORDER; 
  const photosInOrder = [];
  const otherPhotos = [];

  photos.forEach((photo) => {
    if (
      order.includes(photo.patient_image_view) ||
      order.includes(photo.imageViewType)
    ) {
      photosInOrder.push(photo);
    } else {
      otherPhotos.push(photo);
    }
  });

  // Sort the photos in the order array based on the predefined order
  photosInOrder.sort((a, b) => {
    const aType = a.patient_image_view || a.imageViewType;
    const bType = b.patient_image_view || b.imageViewType;
    return order.indexOf(aType) - order.indexOf(bType);
  });

  // Combine the sorted photos in order with the other photos
  const sortedPhotos = [...photosInOrder, ...otherPhotos];
  return sortedPhotos;
};

export function formatImageView(imageView) {
  return imageView.replace(/([A-Z])/g, " $1").trim();
}
export const getTreatmentPlanStatus = (status) => {
  if (!status) {
    return "--";
  }
  if (status === ALIGNERS_IN_PRODUCTION_STATUS) {
    return "Production";
  }
  if (status === ALIGNERS_SHIPPED_STATUS) {
    return "Shipped";
  }

  return status;
};

export const weeklyAvailabilityInitialMapper = () => {
  return  [
      {
        name: "Sunday",
        workDay: false,
        workHours: [{ start: null }],
      },
      {
        name: "Monday",
        workDay: false,
        workHours: [{ start: null }],
      },
      {
        name: "Tuesday",
        workDay: false,
        workHours: [{ start: null }],
      },
      {
        name: "Wednesday",
        workDay: false,
        workHours: [{ start: null }],
      },
      {
        name: "Thursday",
        workDay: false,
        workHours: [{ start: null }],
      },
      {
        name: "Friday",
        workDay: false,
        workHours: [{ start: null }],
      },
      {
        name: "Saturday",
        workDay: false,
        workHours: [{ start: null, end: null }],
      },
    ]
  
}

const formatWorkHours = (workHours) => {
  return workHours?.map((wh) => ({
    start: wh?.map((time) => dayjs(time, "HH:mm")),
  }));
};

export const weeklyAvailabilityValuesMapper = (initialValues) => {
  const valuesArray = initialValues?.workingHours ? initialValues.workingHours : initialValues.weekTimings
 return {
   timeZone: initialValues.timeZone,
   weekTimings: valuesArray.map((item) => ({
     ...item,
     workHours: item.workHours.length  ? formatWorkHours(item.workHours) :  [{ start: null }],
     breakHours: item.breakHours.length ? item.breakHours.map(date => dayjs(date, "HH:mm")):  []
   }))
 }
}


export const weeklyAvailabilityValuesMapperTwo = (initialValues) => {
  const valuesArray = initialValues?.workingHours ? initialValues?.workingHours : initialValues?.weekTimings
 return  valuesArray?.map((item) => ({
     ...item,
     workHours: item.workHours.length  ? formatWorkHours(item.workHours) :  [{ start: null }],
     breakHours: item.breakHours.length ? item.breakHours.map(date => dayjs(date, "HH:mm")):  []
   }))
};

export const clinicStaffValuesMapper = (initialValues) => {
  // const clinicians = initialValues.map(item =>  ({id: item.clinician_id, clinicianName: item.clinician_name, default: item.default}))
  return {clinicians: initialValues};
};


export const clinicStaffInitialMapper = () => {
 const clinicians = [{id: `New-${uuidv4()}` ,clinicianName:"", default : true }]

  return {clinicians}
};


export const patientbreadcrumbRoutes = [
  {
    path: "/clinic/patients",
    breadcrumbName: "Patients List",
  },
  {
    breadcrumbName: "Patient Detail",
  },
];