import { createSelector } from "reselect";

const getCommon = (state) => state.common;

export const countriesSelector = createSelector(
    getCommon,
    (common) => common.countries
);

export const currencyListSelector = createSelector(
    getCommon,
    (common) => common.currencyList
);

export const genderListSelector = createSelector(
    getCommon,
    (common) => common.genderList
);

export const meetingDurationListSelector = createSelector(
    getCommon,
    (common) => common.meetingDurationList
);

