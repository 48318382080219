import moment from "moment";
import _ from "lodash";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Tooltip} from "antd";
import { APPROVE_AND_ORDER_PLAN, ORDER_REFINEMENT_PLAN } from "./constants";
dayjs.extend(customParseFormat);

export const tConvert = (time) => {
  try {
    time = moment(new Date(Date.parse(`06/14/2020 ${time} UTC`))).format("HH:mm:ss");
    return time;
  } catch (ex) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format in correct
      time = time.slice(1); // Remove full string match value
      time[3] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join("");
  }
  // return adjusted time or original string
};
export const utcToLocalConvert = (date, time) => {
  try {
    date = moment(new Date(date)).format("ddd MMM DD YYYY ");
    date += tConvert(time);

    return date;
  } catch (ex) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[3] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join("");
  }
};
export const isToday = (date) => {
  return moment(new Date()).format("DD-MM-YYYY") === moment(new Date(date)).format("DD-MM-YYYY");
};
export const isItTime = (selectedAppointment) => {
  if (!selectedAppointment.appointment_start_time) {
    return false;
  }

  // const twentyFourHoursAgo = currentTime.subtract(24, "hour");
  // const twentyFourHoursAhead = currentTime.add(12, "hour");
  const givenDateTime = dayjs(
    dayjs(selectedAppointment.appointment_date, "YYYY-MM-DD").format("YYYY-MM-DD") +
      " " +
      selectedAppointment.appointment_start_time,
    "YYYY-MM-DD HH:mm:ss"
  );

  const currentDateTime = dayjs();

  if (currentDateTime.isAfter(givenDateTime)) {
    return true;
  }

  // Calculate the difference in hours
  const hoursDifference = givenDateTime.diff(currentDateTime, "hour");

  // Return true if the absolute difference is less than or equal to 24 hours
  const diff = Math.abs(hoursDifference) <= 24;

  // return  currentTime.isSame(appointmentTime,'d');
  return diff;
};
export const isConfirmButtonDisable = (selectedAppointment) => {
  try {
    const AppUTCDate = new Date(selectedAppointment.appointment_date);
    const todayDate = new Date();
    const UTCTimeStamp = new Date(todayDate).getTime();

    // const appStartTime =
    //   new Date(AppUTCDate).getTime() +
    //   parseInt(
    //     selectedAppointment.appointment_start_time.split(":")[0] *
    //       60 *
    //       60 *
    //       1000
    //   ) +
    //   parseInt(selectedAppointment.appointment_start_time.split(":")[1]) *
    //     60000;

    const appDateEndTime =
      new Date(AppUTCDate).getTime() +
      parseInt(selectedAppointment.appointment_end_time.split(":")[0] * 60 * 60 * 1000) +
      parseInt(selectedAppointment.appointment_end_time.split(":")[1]) * 60000;

    // const diffStartTime = UTCTimeStamp - appStartTime;
    // const diffInMinuteStartTime = Math.floor(diffStartTime / 1000 / 60);

    if (appDateEndTime >= UTCTimeStamp) {
      return true;
    }
    return false;
  } catch (ex) {
    return false;
  }
};

export const convertToMinutes = (time) => {
  if (!validateTimeFormat(time)) {
    return 0;
  }
  const timeArray = time.split(":");
  const hours = parseInt(timeArray[0]);
  const minutes = parseInt(timeArray[1]);
  const seconds = parseInt(timeArray[2]);
  const totalMinutes = hours * 60 + minutes + seconds / 60;
  return totalMinutes;
};

export function validateTimeFormat(time) {
  const regex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/; // hh:mm:ss
  return regex.test(time);
}

export const createDateTime = (date, time) => {
  const dateObj = new Date(date);
  const startOfDay = moment(dateObj).startOf("day");
  const minutes = convertToMinutes(time);
  const newDate = startOfDay.add(minutes, "minutes").toDate();
  return newDate;
};

export const pickStatusColor = (status, type) => {
  if ((status === 1 || status === 5) && type === "slot") {
    return "#FFF2DD";
    // return "red";
  } else if ([2, 7].includes(status) && type === "slot") {
    return "#DBF4FF";
  } else if (status === 3 && type === "slot") {
    return "#D4F3D0";
  } else if ([4, 6, 8, 9].includes(status) && type === "slot") {
    return "#FBDDDD";
  } else if (status === 1 && type === "drawer") {
    return " #EF9C00";
  } else if (status === 3 && type === "drawer") {
    return "#53cf43";
  } else if ([2, 5, 7].includes(status) && type === "drawer") {
    return "#1DBCFA";
  } else if ([4, 6, 8, 9].includes(status) && type === "drawer") {
    return "#e94545";
  }
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export function uniqueArrayByKey(array, key) {
  const uniqueSet = new Set();
  const resultArray = [];

  for (const obj of array) {
    const keyValue = obj[key];

    if (!uniqueSet.has(keyValue)) {
      uniqueSet.add(keyValue);
      resultArray.push(obj);
    }
  }

  return resultArray;
}

export function groupByDate(data) {
  const result = _(data)
    .groupBy((v) => moment(v.createdAt).format("YYYY-MM-DD"))
    .mapValues((v) => v)
    .value();
  return _.reverse(result);
}

export function isValidURL(url) {
  const urlPattern = /^(https?:\/\/)?([\w.-]+(\.\w+)+)(\/\S*)?$/;
  return urlPattern.test(url);
}

export function uniqByKeepLast(data, key) {
  return [...new Map(data.map((x) => [key(x), x])).values()];
}

export const companyTypeCheck = (companyType) => {
  return companyType !== 5;
};

export const stringDecode = (key) => {
  const str = atob(key);

  return JSON.parse(str);
};


export const passwordValidator = (_, value) => {
  if (value.length < 8) {
    return Promise.reject("Password must be at least 8 characters long");
  }
  if (!/(?=.*[a-z])/.test(value)) {
    return Promise.reject("At least 1 lowercase alphabetical character is required");
  }
  if (!/(?=.*[A-Z])/.test(value)) {
    return Promise.reject("At least 1 uppercase alphabetical character is required");
  }
  if (!/(?=.*[0-9])/.test(value)) {
    return Promise.reject("At least 1 numeric character is required");
  }
  if (!/(?=.*[!@#$%^&*()\-_=+.])/.test(value)) {
    return Promise.reject("At least 1 special character is required (! @ # $ % ^ & * ( ) - _ = + .)");
  }
  if (/[^!@#$%^&*()\-_=+a-zA-Z0-9.]/.test(value)) {
    return Promise.reject(
      "A special character that you may have entered is unsupported, please use one or more from the following list ! @ # $ % ^ & * ( ) - _ = + ."
    );
  }
  return Promise.resolve();
};

export const getFormatDate = (date) => dayjs(date).format("DD-MM-YYYY");

export const checkLastActiveStatus=()=>{
  const lastActiveTime = localStorage.getItem("lastActiveTime");
  
  if(lastActiveTime){
    const lastActiveTimeDate = dayjs(lastActiveTime);
    const lastActiveDiff = dayjs().diff(lastActiveTimeDate,'minute');
    if(lastActiveDiff>60){
      return true
    }
  }
  return false;
}

export const renderTreatmentTypes = (companyType, createdAt) => {
  let returnValue = true;
  let oldPrescription = false;
  if (companyType === 1) {
    if (createdAt) {
      const comparisonDate = '2024-11-06';
      const parsedDateToCheck = dayjs(createdAt);
      const parsedComparisonDate = dayjs(comparisonDate);
      const isBeforeOrSame = parsedDateToCheck.isBefore(parsedComparisonDate, 'day') || parsedDateToCheck.isSame(parsedComparisonDate, 'day');
      if (isBeforeOrSame) {
        returnValue = false;
        oldPrescription = true
      }
      else {
        returnValue = true;
      }

    }
    // returnValue = true;
  }
  if (companyType === 5) {
    returnValue = true;
  }

  if (companyType === 6 || companyType === 7) {
    if (createdAt) {
      const comparisonDate = '2024-07-03';
      const parsedDateToCheck = dayjs(createdAt);
      const parsedComparisonDate = dayjs(comparisonDate);
      const isBeforeOrSame = parsedDateToCheck.isBefore(parsedComparisonDate, 'day') || parsedDateToCheck.isSame(parsedComparisonDate, 'day');
      if (isBeforeOrSame) {
        returnValue = false;
        oldPrescription = true
      }
      else {
        returnValue = true;
      }

    }
    else {
      returnValue = true;
    }
  }

  return { show: returnValue, old: oldPrescription };
}

export function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  return (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1);
}


export const columnTitleElement = (value) => {
  return (
    <Tooltip title={value}>
    <p
      className="appTable-column-title"
    >
      {value}
    </p>
  </Tooltip>
 
  );
};
export const getOrderBtnText = (refinementData) =>{
  return refinementData ? ORDER_REFINEMENT_PLAN : APPROVE_AND_ORDER_PLAN;
}


export const disableFutureDates = (current) =>{
  return current && current >= dayjs().endOf("day");
}

export const disablePastDates = (current) => {
  return current && current < dayjs().startOf("day");
};


export const getAddressView = (info) => {
  if (info) {
    const addressParts = [
      info.clinic_address,
      info.clinic_city,
      info.clinic_county,
      info.clinic_zipcode,
      info.clinic_country
    ].filter(part => part && part.trim()); // Filter out empty parts
    const address = addressParts.join(", "); // Join with commas
    return address || "-"; // Return "-" if address is empty
  }
  return "--";
};
