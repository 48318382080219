import { useState, useEffect, useContext } from "react";
import OffProducts from "./OffProducts/OffProducts";
import TreatmentProductsView from "./TreatmentProductsView/TreatmentProductsView";
import { getClinicTreatmentProductsSelector } from "services/redux/selectors/clinicSelectors";
import { useSelector } from "react-redux";
import { clinicIdSelector } from "services/redux/selectors/clinicSelectors";
import AppSettingsContext from "Context/useAppContext";

const Products = () => {
  const [editProducts, setEditProducts] = useState(false);
  const clinicTreatmentProducts = useSelector(getClinicTreatmentProductsSelector);
  const clinicId = useSelector(clinicIdSelector);
  const [showProductModal, setShowProductModal] = useState(false);

  const { setClinicConfig } = useContext(AppSettingsContext);
  //  console.log("clinicTreatmentProducts", clinicTreatmentProducts, clinicId)
  useEffect(() => {
    if (clinicTreatmentProducts.length) {
      setEditProducts(true);
      setClinicConfig((prev) => ({...prev, isProductAvailable: true}));
    } else {
      setEditProducts(false);
      setClinicConfig((prev) => ({...prev, isProductAvailable: false}));
    }
  }, [clinicTreatmentProducts]);

  return (
    <div>
      {editProducts ? (
        <TreatmentProductsView
          setEditProducts={setEditProducts}
          clinicTreatmentProducts={clinicTreatmentProducts}
          clinicId={clinicId}
          showProductModal={showProductModal}
          setShowProductModal={setShowProductModal}
        />
      ) : (
        <OffProducts
          setEditProducts={setEditProducts}
          setShowProductModal={setShowProductModal}
        />
      )}
    </div>
  );
};

export default Products;
