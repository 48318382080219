import { useState } from "react";
import { message, Spin, Table, Button,Popconfirm  } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as EmptyList } from "assets/images/EmptyList.svg";
import { ReactComponent as Eye } from "assets/images/Eye.svg";
import { dentistClinicApiTasks } from "models/dentistClinic";


import { primaryColor } from "colors-config";

const ResourcesList = ({
   tableLoading ,
   resources,
   searchValue,
   setSearchLoading,
   clinic,
   SetResources,
   value,
   setClinicResources,
   onShowResource,
   handleRowClick
  }) => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resourceDetail, setResourceDetail] = useState(null);



  const handleDeleteResource = async (id, name) => {
   const prevResources = [...resources];
    setLoading(true);
    let resourceDeleteBody; 
    resourceDeleteBody = {
      clinic_id: clinic.clinicId,
      file_url: name,
    };
    try {
      const res = await dentistClinicApiTasks.post(
        "clinic/resource/delete",
        resourceDeleteBody
      );
      message.success("File successfully removed");
      const prevResources = [...resources];
      const filterResource = prevResources.filter(
        (resource) => resource.id.id !== id
      );
       console.log("filterResource", filterResource);
       setClinicResources(filterResource);

    } catch (error) {
      console.log(error);
    }
    finally{
      setLoading(false);
    }
  };



  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      render: (resource) => {
        return (
          <div
            style={{
              display: "flex",
              // justifyContent: "center",
                alignItems: "flex-start",
              // marginLeft: "20px",
            }}
          >
            <div
              style={{ cursor: "pointer", textAlign: "left", marginRight:"20px" }}
              // onClick={() => onShowResource(resource.resourceId)}
            >
              {/* <Eye style={{fill:primaryColor}} /> */}
            </div>
            <p>{resource.resourceName}</p>
          </div>
        );
      },
    },
    {
      title: "Shared by",
      dataIndex: "shareList",
      key: "shareList",
      render: (resource) => {
        const { clinic  } = resource
        return (
          <div>
              <p className="share-tab" style={{background: primaryColor}}>{clinic}</p>

          </div>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => {
    
        return (
          <div>
              <p  style={{textTransform: category === 'url' ? "uppercase" : "capitalize"}}>{category}</p>
          </div>
        );
      },
    },
    {
      title: "Uploaded on",
      dataIndex: "uploaded",
      key: "uploaded",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: ({id, name}) => {
        return (
          <Popconfirm
          okButtonProps={{
            style: {
              width: "7rem",
              background: primaryColor,
              borderColor: primaryColor,
              borderRadius: "30px",
              color: "#fff",
              height: "32px",
              padding: "4px 15px",
              marginTop: "10px",
            },
          }}
          cancelButtonProps={{
            style: {
              width: "7rem",
              borderRadius: "30px",
              height: "32px",
              padding: "4px 15px",
              marginTop: "10px",
            },
          }}
          title="Delete Resource"
          description="Are you sure to delete this resource?"
          onConfirm={(e) => {
            e.stopPropagation()
            handleDeleteResource(id, name)
          }}
          onCancel={(e) => console.log("cancel")}
          okText="Yes"
          cancelText="No"
        >
          {
            value === "mine" ?  <Button
            style={{border:"none", boxShadow:"none"}}
              className="delete-btn"
              icon={<DeleteOutlined />}
              onClick={(e) => e.stopPropagation()}
            ></Button> : null
          }
         
        </Popconfirm>
        );
      },
    },
  ];
  return (
    <div className="resources-list-container">
      <Spin spinning={loading}>
        <Table 
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // Handle row click
          })}
        rowClassName="clickable-row" // Add a class name
        dataSource={resources} columns={columns} loading={tableLoading} />
      </Spin>
    </div>
  );
};

export default ResourcesList;
