import classNames from "classnames";
import { Row, Col, Switch, Alert } from "antd";
import AppButton from "components/shared/AppButton/AppButton";
import styles from "./view.module.scss";
import { ReactComponent as PayPhone } from "assets/images/PayPhone.svg";
import { ReactComponent as BulbIcon } from "assets/images/BulbIcon.svg";
import { useSelector } from "react-redux";
import { clinicPayments } from "services/redux/selectors/clinicSelectors";
import { tagBackgroundColor } from "colors-config";

const PaymentsView = ({ setEditPayments,receiveAppPayment }) => {
  const payments = useSelector(clinicPayments);
  return (
    <div className={classNames(styles["payment-view-container"])}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Online Payments</h2>
        </Col>
        <Col>
          <AppButton
            type="primary"
            shape="round"
            style={{ padding: "0 40px", height: "35px" }}
            onClick={() => setEditPayments(true)}
          >
            Edit
          </AppButton>
        </Col>
      </Row>
      <Row
        align="middle"
        justify="end"
        style={{ margin: "20px 0px" }}
      >
        <Col>
          <div className={styles["book-appointment"]}  style={{ background: tagBackgroundColor }}>
            <label>Receive Payments through Patient App</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Switch
                style={{
                  marginLeft: "10px",
                }}
                disabled
                checked={receiveAppPayment}
              />
              <span
                style={{
                  marginLeft: "10px",
                }}
              >
                {receiveAppPayment ? "Yes" : "No"}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row align="middle">
        <Col>
          <Alert
            className={styles["treatment-view-info"]}
            message={
              "Stripe's payments platform lets you accept credit cards, debit cards, and popular payment methods around the world"
            }
            type="info"
            showIcon
            icon={<BulbIcon />}
          />
        </Col>
        <Col>
          {/* <StripeButton
              // stripeConnected={payment?.isEnabled ? payment?.isEnabled : false}
              clinicId={clinicId}
            /> */}
        </Col>
      </Row>
      <Row align={payments.length > 2 ? "bottom" :"top"}>
        <Col lg={18}>
          {payments
            ?.filter((payment) => payment.type !== "IN_CLINIC")
            .map((payment, index) => {
              return (
                <div
                  key={index}
                  style={{ opacity: index === 0 ? 0.5 : 1, marginTop: "10px" }}
                >
                  {index === 1 ? (
                    <Alert
                      className={styles["treatment-view-info"]}
                      message="If you're using any third-party payment URL, make sure to include the button text and the corresponding payment URLs."
                      type="info"
                      showIcon
                      icon={<BulbIcon />}
                    />
                  ) : null}
                  {index > 0 ?     <Row
                    gutter={[32, 32]}
                    align="middle"
                    style={{ width: "100%" }}
                  >
                    <Col lg={6}>
                    
                    </Col>
                    <Col lg={12}>
                 
                    <label>Button Text</label>
                    </Col>
                  </Row> : null}
             
               <div style={{margin:"10px 0px 20px 0px"}}>
                  <Row
                    gutter={[32, 32]}
                    align="top"
                    style={{ width: "100%" }}
                  >
                    <Col lg={6}>
                      <label>Payment Type</label>
                    </Col>
                    <Col lg={12}>
                 
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: "15px",
                          margin: "0px",
                          //  margin: "10px 0px"
                        }}
                      >
                        {payment.buttonText}
                      </p>
                    </Col>
                  </Row>
               </div>
                  {index > 0 ? (
                   <>
                   <Row
                    gutter={[32, 32]}
                    align="middle"
                    style={{ width: "100%" }}
                  >
                    <Col lg={6}>
                    
                    </Col>
                    <Col lg={12}>
                 
                    <label>Button URL</label>
                    </Col>
                  </Row>
                  <div style={{margin:"10px 0px 20px 0px"}}>
                  <Row
                    gutter={[32, 32]}
                    align="top"
                    style={{ width: "100%" }}
                  >
                    <Col lg={6}>
                      <label></label>
                    </Col>
                    <Col lg={12}>
                 
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: "15px",
                          margin: "0px",
                          //  margin: "10px 0px"
                        }}
                      >
                        {payment.url}
                      </p>
                    </Col>
                  </Row>
               </div>
                   </>
                  ) : null}
                  <Row
                    gutter={[32, 32]}
                    align="middle"
                    style={{ margin: "15px 0px" }}
                  >
                    <Col lg={6}>
                      <label style={{ marginLeft: "-14px" }}>Status</label>
                    </Col>
                    <Col lg={12}>
                      <Switch
                        style={{ marginLeft: "-10px" }}
                        disabled={true}
                        checked={payment.isActive}
                      />
                      <span style={{ marginLeft: "10px" }}>Active</span>
                    </Col>
                  </Row>
                  <Row
                    gutter={[32, 32]}
                    align="middle"
                    style={{ marginBottom: "30px" }}
                  >
                    <Col lg={6}>
                      <label></label>
                    </Col>
                    <Col lg={12}>
                      <Switch checked={payment.default} disabled={true} />
                      <span style={{ marginLeft: "10px" }}>
                        Default For All Products & Services
                      </span>
                    </Col>
                  </Row>
                </div>
              );
            })}
        </Col>
        <Col lg={6}>
          <PayPhone />
        </Col>
      </Row>
    </div>
  );
};

export default PaymentsView;
