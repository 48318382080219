import api from "./api";
const baseUrl = process.env.REACT_APP_BASE_URL;

const getPatientResourceUploadStageAPI = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patient/getPatientResourceUploadStage`,
    params,
  }).then((res) => res.data);
};

const createStripePaymentIntentAPI = (data) => {
  return api({
    method: "POST",
    url: `/patient/stripe/createStripePaymentIntent`,
    data: data,
  }).then((res) => res.data);
};

const createClinicLabOrderAPI = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patientlist/patient/orders/placeOrder`,
    data: data,
  }).then((res) => res.data);
};

const createClinicPatientOrderAPI = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patientlist/requestOrder`,
    data: data,
  }).then((res) => res.data);
};

const getTreatmentPlanPrice = (patientId) => {
  return api({
    method: "GET",
    url: `/patient/treatmentSetup/price?patientId=${patientId}`,
  }).then((res) => res.data);
};

const downloadPatientInvoiceAPI = (type, id) => {
  return api({
    method: "GET",
    url: `/clinic/${type}/pdf?patientOrderId=${id}`,
  }).then((res) => res.data);
};

const getTreatmentOldAPI = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patientlist/treatmentSetup/getOld`,
    params: params,
  }).then((res) => res.data);
};

const setPatientTreatmentApproveAPI = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patient/treatment/approve`,
    data: data,
  }).then((res) => res.data);
};

const updatePaymentStatusApi = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patient/earlypayment`,
    data: data,
  }).then((res) => res.data);
};

const updatePatientOrderPaymentStatus = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patient/order/paymentStatusUpdate`,
    data: data,
  });
};

const downloadS3File = (fileName) => {
  return baseUrl + "/clinic/progress-image/download/" + fileName;
};

const addPatientNote = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patientlist/note/add`,
    data: data,
  }).then((res) => res.data);
};

const getPatientNote = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patientlist/note/get`,
    params: params,
  }).then((res) => res.data);
};

const startTreatmentApi = (data) => {
  return api({
    method: "PUT",
    url: `/patient/updateAlignerOrderStatus`,
    data: data,
  });
};

const startRevisionTreatmentApi = (data) => {
  return api({
    method: "PUT",
    url: `/patient/startRevisionTreatment`,
    data: data,
  });
};

const completeTreatment = (data) => {
  return api({
    method: "POST",
    url: `/patient/treatment/complete`,
    data: data,
  });
};

const getPatientProgress = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patientlist/treatmentProgress`,
    params: params,
  }).then((res) => res.data);
};

const updatePatientTreatmentData = (prescriptionId, data) => {
  return api({
    method: "POST",
    url: `/clinic/patient/updateTreatmentData/${prescriptionId}`,
    data: data,
  }).then((res) => res.data);
};

const resendRegistrationEmail = (data) => {
  return api({
    method: "POST",
    url: `/patient/resendRegistrationEmail`,
    data: data,
  });
};
const confirmAppointmentApi = (data) => {
  return api({
    method: "POST",
    url: `/patient/makeAppointmentClinic`,
    data: data,
  });
};

const getAppointmentDetailsApi = (params) => {
  return api({
    method: "GET",
    url: `/clinic/appointment/get/details`,
    params: params,
  });
};

const updatePatEmailApi = (data) => {
  return api({
    method: "POST",
    url: `/patient/updateEmail`,
    data: data,
  });
};

const checkPatientEmail = (params) => {
  return api({
    method: "GET",
    url: `/patient/checkPatientEmail`,
    params: params,
  });
};

const getPatientDetailsApi = (patientId) => {
  return api({
    method: "GET",
    url: `/clinic/patientlist/patientinfo?patientId=${patientId}`,
  });
};

const forgetPasswordPatientService = (data) => {
  return api({
    method: "POST",
    url: "/patient/auth/forgotPassword",
    data,
  })
}

const uploadScanFileAPI = (data) => {
  return api({
    method: "POST",
    url: "/patient/resource/add",
    data,
  });
};


const getPatientCountryCodesService = () => {
  return api({
    method: "GET",
    url: "/patient/getAllCountries",
  });
}

const confirmScanFileAPI = (data) => {
  return api({
    method: "POST",
    url: "/patient/resource/upload/confirm",
    data,
  });
};

const addPatientAddressApi = (data) => {
  return api({
    method: "PUT",
    url: "/patient/editPatientAddress",
    data,
  });
};

const shareWithPatientApi = (data) => {
  return api({
    method: "POST",
    url: "/clinic/treatmentSetup/shareWithPatient",
    data,
  });
};


const patientResourceImageConfirmApi = (data) => {
  return api({
    method: "POST",
    url: "/clinic/patient/resource/upload/confirm",
    data,
  });
};

const patientResourceDeleteApi = (data) => {
  return api({
    method: "DELETE",
    url: "/clinic/patient/deleteResource",
    data,
  });
};

const getRespForQuestionnaireApi = (params) => {
  return api({
    method: "GET",
    url: "/clinic/patient/getRespForQuestionnaire",
    params
  });
};

const patientClinicalFilesDeleteApi = (data) => {
  return api({
    method: "POST",
    url: "/patient/resource/delete",
    data,
  });
};

const patientTreatmentTimelineHistoryApi = (patientId) => {
  return api({
    method: "GET",
    url: "/clinic/treatment/timeline_history/"+patientId,
  });
};

const patientConsentUrlTempApi = (patientId) => {
  return api({
    method: "GET",
    url: "/patient/consentFormDetails/"+patientId,
  });
};


const getClinicProfileService = (clinicId) => {
  return api({
    method: "GET",
    url: `/clinic/onboarding/clinicSettings/${clinicId}`,
  });
}


const getClinicCommonDataService = (clinicId) => {
  return api({
    method: "GET",
    url: `/clinic/common/list/${clinicId}`,
  });
}

const completeProfileStepOne = (data, clinicId) => {
  return api({
    method: "POST",
    url: `/clinic/onboarding/profileStepOne/${clinicId}`,
    data
  })
}

const completeProfileStepTwo = (data, clinicId) => {
  return api({
    method: "POST",
    url: `/clinic/onboarding/profileStepTwo/${clinicId}`,
    data
  })
}

const completeProfileStepThree = (data, clinicId) => {
  return api({
    method: "POST",
    url: `/clinic/onboarding/profileStepThree/${clinicId}`,
    data
  })
}

const createPatientAPI = (data) => {
  return api({
    method: "POST",
    url: `/patient/createPatient`,
    data
  })
}

const editPatientAPI = (data) => {
  return api({
    method: "PUT",
    url: `/patient/editPatient`,
    data
  })
}



export {
  getPatientResourceUploadStageAPI,
  createStripePaymentIntentAPI,
  downloadPatientInvoiceAPI,
  getTreatmentOldAPI,
  setPatientTreatmentApproveAPI,
  getTreatmentPlanPrice,
  updatePaymentStatusApi,
  createClinicLabOrderAPI,
  downloadS3File,
  addPatientNote,
  getPatientNote,
  startTreatmentApi,
  startRevisionTreatmentApi,
  completeTreatment,
  getPatientProgress,
  updatePatientTreatmentData,
  resendRegistrationEmail,
  confirmAppointmentApi,
  updatePatientOrderPaymentStatus,
  createClinicPatientOrderAPI,
  getAppointmentDetailsApi,
  updatePatEmailApi,
  checkPatientEmail,
  getPatientDetailsApi,
  forgetPasswordPatientService,
  getPatientCountryCodesService,
  uploadScanFileAPI,
  confirmScanFileAPI,
  addPatientAddressApi,
  shareWithPatientApi,
  patientResourceImageConfirmApi,
  patientResourceDeleteApi,
  getRespForQuestionnaireApi,
  patientClinicalFilesDeleteApi,
  patientTreatmentTimelineHistoryApi,
  patientConsentUrlTempApi,
  getClinicProfileService,
  getClinicCommonDataService,
  completeProfileStepOne,
  completeProfileStepTwo,
  completeProfileStepThree,
  createPatientAPI,
  editPatientAPI
};
