export const scansArray = [
    {
      name: "upperArch",
      label: "Upper Arch",
      id: 1,
    },
    {
      name: "lowerArch",
      label: "Lower Arch",
      id: 2,
    },
    {
      name: "biteScan1",
      label: "Bite Scan 1",
      id: 3,
    },
    {
      name: "biteScan2",
      label: "Bite Scan 2",
      id: 4,
    },
  ];


  export const getScanName = (name) => {
    const scanTypeMapping = {
      UpperArch: "Upper Arch",
      LowerArch: "Lower Arch",
      Bitescan: "Bite Scan",
      Bitescan2: "Bite Scan 2",
      BiteScan:"Bite Scan",
      BiteScan2:"Bite Scan 2"
    };
  
    return scanTypeMapping[name] || name;
  };

  const genderList = [
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" },
    { label: "Non-binary", value: "NON_BINARY" },
    { label: "Transgender Male/Trans Man", value: "TRANSGENDER_MALE" },
    { label: "Transgender Female/Trans Woman", value: "TRANSGENDER_FEMALE" },
    { label: "Genderqueer/Gender non-conforming", value: "GENDERQUEER" },
    { label: "Intersex", value: "INTERSEX" },
    { label: "Two Spirit", value: "TWO_SPIRIT" },
    { label: "Prefer to self-describe", value: "PREFER_SELF_DESCRIBE" },
    { label: "Prefer not to say", value: "PREFER_NO_SAY" }
];
  
export function getDisplayGender(patientInfo) {
  const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  if (patientInfo?.gender === "PREFER_SELF_DESCRIBE") {
    const selfDescription = patientInfo?.genderSelfDescribe 
      ? capitalizeFirstLetter(patientInfo.genderSelfDescribe)
      : "Prefer to self-describe";
    return selfDescription;
  }

  const gender = genderList.find(item => item.value === patientInfo?.gender);
  return gender ? gender.label : "--";
}

  
