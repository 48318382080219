import { combineReducers } from "redux";

import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import clinicReducer from "./clinicReducer";
import videoCallReducer from "./videoCallReducer";
import commonReducer from "./commonReducer";

export default combineReducers({
  error: errorReducer,
  auth: authReducer,
  clinic: clinicReducer,
  videoCall: videoCallReducer,
  common: commonReducer,
});
