import { tagBackgroundColor } from "colors-config";
import styles from "./footer.module.scss";
import { Flex } from "antd";
import AppButton from "components/shared/AppButton/AppButton";

const FormFooter = ({ onCancelClick, onOkClick, loading}) => {
  return (
    <div
      className={styles["form-footer"]}
      style={{ background: tagBackgroundColor }}
    >
        <Flex justify="flex-end" align="center" gap="middle">
        <AppButton shape="round" onClick={onCancelClick} style={{width:"120px"}}>Cancel</AppButton>
        <AppButton shape="round" type="primary" onClick={onOkClick} loading={loading}  style={{width:"120px", padding: '0 40px', height:'35px'}}>
            Save
          </AppButton>
        </Flex>

    </div>
  );
};

export default FormFooter;
