import { useState } from "react";
import { useSelector } from "react-redux";
import ManagementForm from "./ManagementForm/ManagementForm";
import ManagementView from "./ManagementView/ManagementView";
import {
  getClinicManagementSelector,
  clinicIdSelector,
} from "services/redux/selectors/clinicSelectors";

const Management = () => {
  const clinicManagement = useSelector(getClinicManagementSelector);
  const clinicId = useSelector(clinicIdSelector);
  // console.log("clinicManagement", clinicManagement)
  const [editAddress, setEditAddress] = useState(false);
  function showManagement(edit) {
    if (edit) {
      return (
        <ManagementForm
          setEditAddress={setEditAddress}
          clinicManagement={clinicManagement}
          clinicId={clinicId}
        />
      );
    }
    return (
      <ManagementView
        setEditAddress={setEditAddress}
        clinicManagement={clinicManagement}
      />
    );
  }
  return <div style={{ height: "100%" }}>{showManagement(editAddress)}</div>;
};

export default Management;
