import { useState } from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { dentistClinicApiTasks } from "models/dentistClinic";
import AppButton from "../AppButton/AppButton";

const StripeButton = ({ clinicId, stripeConnected }) => {
  const [loading, setLoading] = useState(false);

  const connectToStripe = async () => {
    setLoading(true);
    try {
      const res = await dentistClinicApiTasks.getAll(
        "clinic/stripe/onboarding",
        { clinicId }
      );
      const url = res.body.url;
      window.open(
        url,
        "_blank",
        "location=yes,height=570,width=1000,scrollbars=yes,status=yes"
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppButton
      icon={stripeConnected ? <CheckCircleFilled  /> : ""}
      loading={loading}
      type="primary"
      shape="round"
      onClick={connectToStripe}
    >
     {stripeConnected ? "Connected to Stripe" :"Connect to Stripe"}  
    </AppButton>
  );
};

export default StripeButton;
