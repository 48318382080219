export const smileAlignPhasingInfo =
  "We are able to send smaller phases of treatment to allow you to monitor treatment more closely. This can be at any stage of treatment you wish. Please let us know if you would like your treatment phased and, in the box below, how many aligners you would like.";
export const spacingInfo =
  "If all spacing cannot be closed/spaces need to be left, please indicate your preference on where these are left.";
export const smileGeniusPhasingInfo = "Please let us know if you would like your case phased.";
export const loginFlagText = "Patient has logged into the app at least once";
export const registerFlagText = "Registered but App login pending";

export const treatmentDetailsPlaceholder =
  "Please add further treatment details here for patient to be able to see on the app before approving their plan";

export const videoCallTooltip =
  "You will be able to join the call 24 hours prior to the scheduled time";

export const onBoardingInviteExpiredMessage = "The link is expired";
export const alreadyInProgressOnboardingMessage =
  "The clinic onboarding is already in progress. Please login to complete the onboarding";

export const supportPhoneToolTip ="The phone number entered will be displayed in the patient app"
export const supportEmailToolTip ="The email entered will be displayed in the patient app"
export const confirmPasswordErrorMessage ="The passwords entered do not match! Please review and enter the same passwords in both fields."
export const availabilityInfoMessage ="Set your weekly working hours and break hours"

export const detailInfoMessage ="The details in the below section are for clinic review only."
export const approvalInfoMessage ="The details in the below section will be shared with the patient for view and approval."
export const treatmentProductsMessage ="Showcase and sell your treatments and products in the patient app for easy viewing and ordering."
export const appointmentMessage ="You can schedule appointments through this portal and display your availability to patients."
export const offProductsMessage = "Showcase Treatment & Products to your patients via app and email."
export const offPaymentMessage="Configure your payment options to charge patients via the Patient App"
export const paymentStripeMessage= "Stripe's payments platform lets you accept credit cards, debit cards, and popular payment methods around the world"
export const paymentThirdPartyMessage= "If you're using any third-party payment URL, make sure to include the button text and the corresponding payment URLs."

