import { Menu } from "antd";
import styles from "./menu.module.scss";
import {  useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { isClinicAvailability, isClinicProducts , isClinicPayments} from "services/redux/selectors/clinicSelectors";



const getItems = (isAvailability, isPayments, isProducts) => {
  const items = [
    {
      label: <span className={styles["menu-subtitle"]}>General Setup</span>,
      type: "group",
    },
    {
      key: "/clinic/settings",
      label: "Clinician Profile",
    },
    {
      type: "divider",
    },
    {
      key: "/clinic/settings/management",
      label: "Clinic Management",
    },
    {
      type: "divider",
    },
    {
      key: "/clinic/settings/partner",
      label: "Partner",
    },
    {
      type: "divider",
    },
    {
      label: <span className={styles["menu-subtitle"]}> Advanced Setup</span>,
      type: "group",
    },
    {
      key: "/clinic/settings/payments",
      label: <span >Online Payments</span>
    },
    {
      type: "divider",
    },
    {
      key: "/clinic/settings/appointments",
      label: <span >Appointments</span>,
    },
    {
      type: "divider",
    },
    {
      key: "/clinic/settings/products",
      label: <span >Treatments & Products</span>,
    }
  ];

  return items;
}
  

const SettingsMenu = () => {
  const history = useHistory();
  const isAvailability = useSelector(isClinicAvailability);
  const isProducts = useSelector(isClinicProducts);
  const isPayments = useSelector(isClinicPayments)

  return (
    <div className={styles["menu-container"]}>
      <h1 className={styles["menu-title"]}>Settings</h1>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[history.location.pathname]}
        onClick={({ key }) => history.push(key)}
        defaultSelectedKeys={["1"]}
        inlineCollapsed={false}
        items={getItems(isAvailability,isPayments,isProducts)}
      />
    </div>
  );
};
export default SettingsMenu;
