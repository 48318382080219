export const buttonType = {
  ACTIVE: "ACTIVE",
  DEFAULT: "DEFAULT",
};

export const PaymentTypes = {
  STRIPE: "STRIPE",
  IN_CLINIC: "IN_CLINIC",
  INSURANCE: "INSURANCE",
  OTHERS: "OTHERS",
};

export const paymentTitles = {
  STRIPE: "Stripe",
  IN_CLINIC: "In Cinic",
  INSURANCE: "Insurance",
  OTHERS: "OTHERS",
};


export const PATIENTINFO ={
  TREATMENT:"TREATMENT",
  PATIENT:"PATIENT"
}