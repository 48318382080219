import api from "./api";

export const generateSignedUrlAPI = (clinicId, data) => {
    return api({
        method: "POST",
        url: `/clinic/scans/generateSignedUrl/${clinicId}`,
        data,
    }).then(res => res.data)
};

export const uploadFileToS3API = (signedUrl, file, contentType, onProgress) => {
    return api({
        method: "PUT",
        url: signedUrl,
        data: file,
        headers: {
            'Content-Type': contentType || 'application/octet-stream',
        },
        onUploadProgress: (progressEvent) => {
            const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // if (onProgress) 
            onProgress({percent});
        }
    });
};

export const addNewScanFilesAPI = (clinicId, data) => {
    return api({
        method: "POST",
        url: `/clinic/scans/addNewScans/${clinicId}`,
        data,
    }).then(res => res.data)
};


export const addConfirmScanFilesAPI = (clinicId, data) => {
    return api({
        method: "POST",
        url: `/clinic/scans/confirm/${clinicId}`,
        data,
    }).then(res => res.data)
};

