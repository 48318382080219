import {
  CLINIC_LOADED,
  CLEAR_CLINIC,
  GET_JOURNEY_STATUS,
  NOTIFICATION_STATE_CHANGE,
  UPDATE_COMMENT_BOX_STATE,
  APPOINTMENT_CREATED,
  SAVE_COUNTRY_CODES,
  CLINIC_NOTIFICATION_LOADED,
  CLINIC_NOTIFICATION_READ,
  PATIENT_COUNTRY_CODES,
  CLINIC_AVAILABILITY,
  CLINICIAN_NAME_UPDATE,
  OPEN_NEW_PATIENT,
  CLINIC_ONBOARDING_PATIENT_STATUS_FINISH,
  OPEN_COMMENT_BOX_PATIENT_STATE,
  CLINIC_ONBOARDING_PATIENT_STATUS_UPDATE,
  INTERCOM_OPEN_SEND_INVITE,
  INTERCOM_SHOW_AFTER_PATIENT_SENT_ORDER_TOUR,
  CLINIC_SETTINGS,
  CLINIC_SETTINGS_LOADER,
  UPDATE_CLINIC_PROFILE_DATA,
  CLINIC_COMMON_DATA,
  UPDATE_CLINIC_MANAGEMENT_DATA,
  COMPLETE_ONBOARDING_STEP_ONE,
  COMPLETE_ONBOARDING_STEP_TWO,
  COMPLETE_ONBOARDING_STEP_TREE,
  UPDATE_CLINIC_TREATMENT_PRODUCTS_DATA,
  UPDATE_CLINIC_APPOINTMENTS_DATA,
  UPDATE_CLINIC_PAYMENTS_DATA,
  UPDATE_CLINIC_RECEIVE_PAYMENT_PROFILE_DATA,
  LOAD_PATIENT_LIST,
  UPDATE_COMMON_DATA_PRODUCT_TYPES,
  UPDATE_COMMON_DATA_APPOINTMENT_TYPES,
} from "../actions/types";

const initialState = {
  clinicId: null,
  clinicEmail: "",
  clinicPhoneNumber: "",
  clinicCountryCode: "",
  clinicStartTime: "",
  clinicEndTime: "",
  journeyStatus: [],
  isNotificationsActive: false,
  notificationCount: 0,
  openCommentBox: false,
  openPatientChatTab: false,
  companyType: 1,
  address: "",
  newAppointment: false,
  clinicians: [],
  openNewPatient: false,
  intercomOpenSendInvite: false,
  intercomOrderTourAfterPatientSent: false,
  clinicSettingsLoader: false,
  clinicSettingsError: "",
  reloadPatientList: false,
  patientCountryCodesList:[]
};

const clinicReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLINIC_LOADED:
      return {
        ...state,
        clinicId: action.payload.clinic_id,
        clinicEmail: action.payload.clinic_email,
        clinicPhoneNumber: action.payload.clinic_contact,
        clinicCountryCode: action.payload.country_code_name,
        clinicStartTime: action.payload.clinic_start_time,
        clinicEndTime: action.payload.clinic_end_time,
        clinicName: action.payload.clinic_name,
        clinicAdmin: action.payload.clinic_admin,
        clinicBankAccNo: action.payload?.clinic_bank_acc_no || "",
        clinicRegistrationNo: action.payload.clinic_regno,
        address: action.payload.clinic_address,
        isOnboarded:
          action.payload.clinic_onboarded_status === "PENDING" ? false : true,
        isFirstLogin: action.payload.clinic_first_login === 0 ? false : true,
        openCommentBox: false,
        openPatientChatTab: false,
        companyType: action.payload.companyType,
        clinicCity: action.payload.clinic_city,
        clinicCounty: action.payload.clinic_county,
        zipCode: action.payload?.clinic_zipcode || "",
        country: action.payload.clinic_country,
        multipleAddresses: action.payload.multipleAddresses,
        teethStructure: action.payload.teeth_structure,
        iprNotification: action.payload.iprNotification,
        iprNotificationDuration: action.payload.iprNotificationDuration,
        chatNotification: action.payload.chatNotification,
        orderNotification: action.payload.orderNotification,
        treatmentNotification: action.payload.treatmentNotification,
        onboardedDate: action.payload.clinic_onboarded_on,
        workingHourData: action.payload.workingHourData,
        clinicians: action.payload.clinicians,
        consentDetails: action.payload.consentDetails,
        firstTimeOnboard: Boolean(action.payload.firstTimeOnboard),
        firstTimeOnboardStep: action.payload.firstTimeOnboardStep,
        firstPatientId: action.payload.firstPatientId,
        intercomSendToPatient: action.payload.sendToPatient,
        intercomAddPatientCount: action.payload.addPatientCount,
        onboardingStep: action.payload.onboardingStep

      };
    case CLEAR_CLINIC:
      return {
        ...state,
        clinicId: null,
        clinicEmail: "",
        clinicPhoneNumber: "",
        clinicCountryCode: "",
        clinicStartTime: "",
        clinicEndTime: "",
        clinicName: "",
        clinic_onboarded_status: "",
        journeyStatus: [],
        address: "",
      };
    case GET_JOURNEY_STATUS:
      return {
        ...state,
        journeyStatus: action.payload,
      };
    case NOTIFICATION_STATE_CHANGE:
      return {
        ...state,
        isNotificationsActive: action.payload.isActive,
      };
    case CLINIC_NOTIFICATION_LOADED:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case CLINIC_NOTIFICATION_READ:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case UPDATE_COMMENT_BOX_STATE:
      return {
        ...state,
        openCommentBox: action.payload,
      };
    case OPEN_COMMENT_BOX_PATIENT_STATE:
      return {
        ...state,
        openPatientChatTab: action.payload,
      };
    case APPOINTMENT_CREATED:
      return {
        ...state,
        newAppointment: action.payload,
      };

    case SAVE_COUNTRY_CODES:
      return {
        ...state,
        countries: action.payload.countries,
        countryCodes: action.payload.countryCodes,
      };

    case PATIENT_COUNTRY_CODES:
      return {
        ...state,
        patientCountryCodesList: action.payload
      };
    case CLINIC_AVAILABILITY:
      return {
        ...state,
        clinicAvailability: action.payload
      };
    case CLINICIAN_NAME_UPDATE:
      return {
        ...state,
        clinicName: action.payload
      };
    case OPEN_NEW_PATIENT:
      return {
        ...state,
        openNewPatient: action.payload
      };
    case CLINIC_ONBOARDING_PATIENT_STATUS_FINISH:
      return {
        ...state,
        firstTimeOnboard: false,
      };
    case CLINIC_ONBOARDING_PATIENT_STATUS_UPDATE:
      return {
        ...state,
        firstTimeOnboardStep: action.payload,
      };
    case INTERCOM_OPEN_SEND_INVITE:
      return {
        ...state,
        intercomOpenSendInvite: action.payload,
      };
    case INTERCOM_SHOW_AFTER_PATIENT_SENT_ORDER_TOUR:
      return {
        ...state,
        intercomOrderTourAfterPatientSent: action.payload,
      };
    case CLINIC_SETTINGS_LOADER:
      return {
        ...state,
        clinicSettingsLoader: action.payload || false
      };
    case CLINIC_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_CLINIC_PROFILE_DATA:
      return {
        ...state,
        clinicProfile: { ...action.payload }
      };
    case UPDATE_CLINIC_MANAGEMENT_DATA:
      return {
        ...state,
        clinicManagement: [...action.payload]
      };
    case CLINIC_COMMON_DATA:
      return {
        ...state,
        commonData: action.payload
      };
    case COMPLETE_ONBOARDING_STEP_ONE:
      return {
        ...state,
        clinicProfile: { ...action.payload }
      };
    case COMPLETE_ONBOARDING_STEP_TWO:
      return {
        ...state,
        clinicProfile: { ...action.payload }
      };
    case COMPLETE_ONBOARDING_STEP_TREE:
      return {
        ...state,
        clinicManagement: [...action.payload]
      }
    case UPDATE_CLINIC_TREATMENT_PRODUCTS_DATA:
      return {
        ...state,
        products: action.payload
      };
    case UPDATE_CLINIC_APPOINTMENTS_DATA:
      return {
        ...state,
        appointments: action.payload
      };
    case UPDATE_CLINIC_PAYMENTS_DATA:
      return {
        ...state,
        payments: action.payload
      }
    case UPDATE_CLINIC_RECEIVE_PAYMENT_PROFILE_DATA:
      return {
        ...state,
        clinicProfile: {
          ...state.clinicProfile,
          receiveAppPayment: action.payload
        }
      }
    case LOAD_PATIENT_LIST:
      return {
        ...state,
        reloadPatientList: action.payload,
      };
    case UPDATE_COMMON_DATA_PRODUCT_TYPES:
      return {
        ...state,
        commonData: { ...state.commonData, productTypes: action.payload }
      }
    case UPDATE_COMMON_DATA_APPOINTMENT_TYPES:
      return {
        ...state,
        commonData: { ...state.commonData, appointmentTypes: action.payload }
      };
    default:
      return state;
  }
};

export default clinicReducer;
