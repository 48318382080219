import PartnerView from "./PartnerView/PartnerView";
import { useSelector } from "react-redux";
import { getClinicPartnerSelector } from "services/redux/selectors/clinicSelectors";

const Partner = () => {
  const clinicPartner = useSelector(getClinicPartnerSelector);
  return <PartnerView clinicPartner={clinicPartner} />;
};

export default Partner;
