import React from 'react';
import styles from "./value.module.scss"


const ClinicInfoDisplay = ({ value, flag}) => {
  return (
    <p className={styles["form-info-value"]}>
      {value ? value : "--"}
    </p>
  );
};

export default ClinicInfoDisplay;
