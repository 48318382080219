import { Row, Col } from "antd";
import styles from "./partner.module.scss";
import { tagBackgroundColor } from "colors-config";

const PartnerView = ({ clinicPartner }) => {
  return (
    <div className={styles["partner-view-container"]}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Partner</h2>
        </Col>
      </Row>
      <div className={styles["partner-card"]}>
        <h2
          className={styles["form-info-heading"]}
          style={{ background: tagBackgroundColor }}
        >
          {clinicPartner?.labDetails?.lab_name || "--"}
        </h2>
        <div className={styles["partner-card-info"]}>
          <label className={styles["form-info-label"]}>Email</label>
          <p
            className={styles["form-info-value"]}
            style={{ paddingTop: "20px" }}
          >
            {clinicPartner?.lab_email || "--"}
          </p>
        </div>

        <p className={styles["form-info-value"]}>
          {clinicPartner?.lab_partnership_status || "--"}
        </p>
      </div>
    </div>
  );
};

export default PartnerView;
