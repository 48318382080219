import { companyType, primaryColor } from "colors-config";


const CustomEditIcon = ({ style, ...props }) => {
  let color='#0A8196'
  if(companyType===5){
   color='#a1582e';
  }
  const svgFile = (<svg width="131" height="131" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="131" height="131" rx="6" fill="#EDEDED"/>
    <path d="M103.5 67C103.5 89.3733 85.5856 107.5 63.5 107.5C41.4144 107.5 23.5 89.3733 23.5 67C23.5 44.6267 41.4144 26.5 63.5 26.5C85.5856 26.5 103.5 44.6267 103.5 67Z" fill="#F8F8F8" stroke="#C4C4C4"/>
    <path d="M52.1202 49.1875C50.2718 49.1875 48.7734 50.6935 48.7734 52.5342V65.9211V72.6146V82.6547C48.7734 84.4954 50.2718 86.0015 52.1202 86.0015H75.5472C77.3963 86.0015 78.8939 84.4954 78.8939 82.6547V72.6146V65.9211V59.2277L68.8538 49.1875H52.1202Z" fill={color}/>
    <path d="M52.1202 47.5117C50.2718 47.5117 48.7734 49.0177 48.7734 50.8584V64.2453V70.9388V80.9789C48.7734 82.8196 50.2718 84.3257 52.1202 84.3257H75.5472C77.3963 84.3257 78.8939 82.8196 78.8939 80.9789V70.9388V64.2453V57.5519L68.8538 47.5117H52.1202Z" fill={primaryColor}/>
    <path d="M78.8995 57.5519L68.8594 47.5117V54.2052C68.8594 56.0459 70.357 57.5519 72.2061 57.5519H78.8995Z" fill={color}/>
    </svg>)
  // return <CustomIcon style={style} {...props} iconComponent={svgFile} />;
  return svgFile
};

export default CustomEditIcon