import { useState } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  InputNumber,
  Checkbox,
  Divider,
  Button,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./form.module.scss";
import { primaryColor } from "colors-config";
const { TextArea } = Input;
const { Option } = Select;

const AppointmentForm = ({
  onProductSubmit,
  addAppointmentForm,
  paymentList,
  clinicCurrenciesList,
  clinicAppointmentTypesList,
  clinicAppointmentDurationsList,
  setAppointmentNameList,
  appointmentNameList,
}) => {
  const [newAppointmentName, setNewAppointmentName] = useState("");

  const handleAppointmentTypeChange = (typeId) => {
    const selectedTypeObj = clinicAppointmentTypesList?.find(
      (type) => type.clinic_appointment_type_id === typeId
    );
    if (selectedTypeObj) {
      setAppointmentNameList(selectedTypeObj.appointmentNamesList);
    }
    addAppointmentForm.setFieldsValue({
      appointment: null
    });

  };

  const addAppointmentName = () => {
    const trimmedName = newAppointmentName.trim();
    if (trimmedName === "") {
      return message.error("Please enter an appointment name");
    }

    // Check if name already exists
    const existingName = appointmentNameList.find(
      (item) =>
        item.clinic_appointment_name.toLowerCase() === trimmedName.toLowerCase()
    );
    if (existingName) {
      return message.error("Appointment name already exists");
    }

    // Add the new appointment name to the list
    const newAppointment = {
      clinic_appointment_type_name_id: new Date().getTime(), // unique ID
      clinic_appointment_name: trimmedName,
    };

    setAppointmentNameList([...appointmentNameList, newAppointment]);
    setNewAppointmentName("");
  };

  return (
    <div>
      <Form
        form={addAppointmentForm}
        layout="vertical"
        name="addResourceForm"
        onFinish={onProductSubmit}
        className={styles["appointment-form"]}
      >
        <Row gutter={[16, 16]}>
          <Col lg={8}>
            <p className={styles["form-label"]}>Appointment Type</p>
            <Form.Item
              name="appointmentType"
              label=""
              rules={[
                {
                  required: true,
                  message: "Please add appointment type",
                },
              ]}
            >
              <Select
                size="large"
                style={{ width: "100%" }}
                placeholder="Appointment Type"
                onChange={handleAppointmentTypeChange}
              >
                {clinicAppointmentTypesList?.map((type) => (
                  <Option
                    key={type.clinic_appointment_type_id}
                    value={type.clinic_appointment_type_id}
                  >
                    {type.clinic_appointment_type_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8}>
            <p className={styles["form-label"]}>Appointment Name</p>
            <Form.Item
              name="appointment"
              label=""
              rules={[
                {
                  required: true,
                  message: "Appointment name is required",
                },
              ]}
              style={{ textAlign: "left" }}
            >
              <Select
                size="large"
                placeholder="Select Appointment Name"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <div
                      style={{
                        padding: "0 8px 4px",
                        display: "flex",
                        alignItems: "center",
                        gap: "7px",
                      }}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Appointment Name"
                        value={newAppointmentName}
                        onChange={(e) => setNewAppointmentName(e.target.value)}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addAppointmentName}
                        style={{ color: primaryColor }}
                      >
                        Add
                      </Button>
                    </div>
                  </>
                )}
              >
                {appointmentNameList.map((item, i) => (
                  <Option
                    key={i}
                    value={item.clinic_appointment_name}
                  >
                    {item.clinic_appointment_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Row gutter={[16, 16]}>
              <Col lg={24} style={{ position: "relative" }}>
                <p className={styles["form-label"]}>Price</p>

                <div
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "-5px",
                  }}
                >
                  <Form.Item name="free" valuePropName="checked">
                    <Checkbox>
                      {" "}
                      <label className={styles["form-label"]}>Free</label>
                    </Checkbox>
                  </Form.Item>
                </div>

                <Row>
                  <Col lg={12}>
                    <Form.Item name="price" label="" placeholder="Price">
                      <InputNumber
                        placeholder="Price"
                        min={0}
                        step={0.1}
                        style={{ width: "100%" }}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item name="currency" noStyle>
                      <Select
                        size="large"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                        placeholder="Currency"
                      >
                        {clinicCurrenciesList.map((currency) => {
                          return (
                            <Option key={currency.currency_id}>
                              {currency.currency_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={8}>
            <p className={styles["form-label"]}>Duration</p>
            <Form.Item
              name="duration"
              label=""
              rules={[
                {
                  required: true,
                  message: "Please add appointment duration",
                },
              ]}
            >
              <Select size="large" placeholder="Duration">
                {clinicAppointmentDurationsList.map((obj) => {
                  return (
                    <Option key={obj.clinic_meeting_duration_id}>
                      {obj.clinic_meeting_duration}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={16}>
            <p className={styles["form-label"]}>Payment Type</p>
            <Form.Item
              rules={[{ required: true, message:"Please select payment type" }]}
              style={{
                width: "100%",
                textAlign: "left",
              }}
              label=""
              name="paymentIds"
            >
              <Select
                size="large"
                style={{ width: "100%" }}
                mode="multiple"
                placeholder="Payment Type"
              >
                {paymentList?.map((el) => {
                  return <Option value={el.id}>{el.buttonText}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginTop:"10px"}}>
          <Col lg={24}>
            <Form.Item label="" name="description">
              <TextArea rows={3} placeholder="Description" size="large"/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AppointmentForm;
