import { Switch, Route, withRouter, Redirect, HashRouter as Router } from "react-router-dom";
import { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import { Amplify } from "aws-amplify";
import Registration from "./routes/public/registration";
import Login from "./routes/public/login";
import SignUpConfirmation from "./components/private/signUpConfirmation";
import Onboarding from "./routes/private/NewOnboarding";
import ChangePassword from "./routes/private/changePassword";
import PatientDetail from "./routes/private/PatientDetail/PatientDetail";
import WelcomePageComponent from "./components/private/welcomePageComponent";
import AppointmentScheduling from "./routes/private/appointmentScheduling";
import ProfileAndSettings from "./routes/private/profileAndSettings";
import VideoChatRoom from "./routes/private/videoChatRoom";
import StripeSuccessPage from "./routes/public/stripeSuccessPage";
import StripeRefreshPage from "./routes/public/stripeRefreshPage";
import ForgotPassword from "./routes/public/forgotPassword";
import PatientList from "./routes/private/patientList";
import ArchivePatients from "routes/private/archivePatients";
import ClinicInvoiceList from "./routes/private/clinicInvoiceList";
import ClinicLabPartners from "./routes/private/clinicLabPartners";
import store from "./services/redux/store";
import { loadUser } from "./services/redux/actions/authActions";
import Notifications from "./routes/private/notifications";
import PrivateRoute from "./hoc/privateRoute";
import awsConfig from "./config/AWSConfig";
import AppointmentCalendarComponent from "./components/private/scheduling/appointmentCalendarComponent";
import VideoCallComponent from "./components/private/videoCallComponent";
//New Onboarding
import ProfileScreen from "./components/OnBoarding/ProfileScreen";
import HelpSupport from "./components/OnBoarding/HelpSupport";
import SuccessScreen from "./components/OnBoarding/SuccessScreen";
import ClinicStep from "./components/OnBoarding/ClinicStep";
import StlViewer from "./routes/public/stlViewer";

import {
  loadClinicNotificationCount,
  getAllCountriesAndCodes,
} from "services/redux/actions/clinicActions";

import { getClinicAppointmentsSelector } from "services/redux/selectors/clinicSelectors";

import {
  primaryColor,
  secondaryColor,
  tagBackgroundColor,
  tagTextColor,
  buttonColor,
  companyType,
} from "colors-config";
import { Auth } from "aws-amplify";
import { onMessageListener } from "./firebase.js";
import { ConfigProvider } from "antd";
import { isOnboardedSelector, clinicSelector } from "./services/redux/selectors/clinicSelectors";

import "./App.scss";
import ClinicIntegrations from "routes/private/clinicIntegrations";
import SEO from "components/SEO/SEO";
import Resources from "routes/private/resources";
import useInactivityLogout from "./customHooks/useInactivityHook";
import ErrorPage from "routes/public/errorPage";
import ConfirmUser from "routes/public/confirmUser";
import WhiteLabel from "routes/private/WhiteLabel/whiteLabel";
import DoctorAppRedirect from "./routes/public/doctorAppRedirect";
import Settings from "routes/private/Settings/Settings";
import { AppProvider } from "AppProvider";
import { getAllCommonList } from "services/redux/actions/commonActions";

Amplify.configure(awsConfig);

async function getAuthenticationStatus() {
  try {
    await Auth.currentSession();
    return true;
  } catch (error) {
    return false;
  }
}

export function updateNotificationState() {
  store.dispatch(loadClinicNotificationCount());
}

if (!window?.location?.href?.includes("localhost")) {
  if (!window.console) window.console = {};
  var methods = ["log", "debug", "warn", "info"];
  for (var i = 0; i < methods.length; i++) {
    console[methods[i]] = function () {};
  }
}

const Main = withRouter(({ location }) => {
  const isOnboarded = useSelector(isOnboardedSelector);
  const clinicAppointments = useSelector(getClinicAppointmentsSelector);

  useInactivityLogout();

  const OnBoardingComponent = () => {
    return (
      <Route
        render={({ match }) => (
        <Onboarding>
          {/* Index route for the first step */}
          <Switch>
            <Route exact path={match.path} component={ProfileScreen} />
            <Route path={`${match.path}/help-support`} component={HelpSupport} />
            <Route path={`${match.path}/clinic-setup`} component={ClinicStep} />
            <Route path={`${match.path}/success`} component={SuccessScreen} />
            {/* <Redirect from={`${match.path}`} to={`${match.path}/`} /> */}
          </Switch>
        </Onboarding>
      )}
       />
    )
  }
  
  return (
    <>
     <AppProvider>
      <SEO />
      <div className={companyType === "5" ? "App smileAlign-app" : "App"}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              let redirectLink = "/login";
              console.log("main");
              if (getAuthenticationStatus() && !isOnboarded) {
                redirectLink = "/onboarding";
              }

              if (getAuthenticationStatus() && isOnboarded) {
                redirectLink = "/clinic/patients";
                // if(clinicAppointments?.bookAppointmentApp){
                //   redirectLink = "/clinic/scheduling/pending";
                // }

                // if(!clinicAppointments?.bookAppointmentApp){
                //   redirectLink = "/clinic/patients";
                // }
              }

              return <Redirect to={redirectLink} />;
            }}
          />
{/* 
          <Route
            exact
            path="/onboarding"
            render={({ match }) => {
              let redirectLink = "/clinic/settings";
              if (getAuthenticationStatus()) {
                if(!isOnboarded){ 
                  redirectLink = match.path;
                }
                if(clinic?.onboardingStep === 'success' && match.path === '/onboarding/success'){
                  redirectLink = match.path
                }
              }

              return <Redirect to={redirectLink} />;
            }}
          /> */}

          <PrivateRoute path="/clinic/whiteLabel" component={WhiteLabel} />
          <Route exact path="/404" component={ErrorPage} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup-confirmation" component={SignUpConfirmation} />
          <Route exact path="/confirm-user" component={ConfirmUser} />
          <Route exact path="/calendar" component={AppointmentCalendarComponent} />

          <Route exact path="/stripe/success" component={StripeSuccessPage} />
          <Route exact path="/stripe/refresh" component={StripeRefreshPage} />

          <Route exact path="/forgot-password" component={ForgotPassword} />

          

          <PrivateRoute path="/changePassword" component={ChangePassword} />
          {/* New Onboarding */}

          <PrivateRoute
            path="/onboarding"
            component={OnBoardingComponent}
          />

          {/* <PrivateRoute path="/onboarding" component={Onboarding} /> */}
          <PrivateRoute exact path="/clinic/notifications" component={Notifications} />
          <PrivateRoute path="/clinic/resources" component={Resources} />

          <PrivateRoute path="/welcome-page" component={WelcomePageComponent} />
          <PrivateRoute exact path="/clinic/scheduling/:status" component={AppointmentScheduling} />

          <PrivateRoute path="/welcome-page" component={WelcomePageComponent} />
          <PrivateRoute path="/clinic/profile" component={ProfileAndSettings} />
          {/* <PrivateRoute exact path="/clinic/settings" component={ProfileAndSettings} /> */}
          {/* <PrivateRoute exact path="/clinic/settings/:step" component={ProfileAndSettings} /> */}
          <PrivateRoute path="/videoChatRoom" component={VideoChatRoom} />
          <PrivateRoute path="/changePassword" component={ChangePassword} />

          <PrivateRoute path="/clinic/lab-partners" component={ClinicLabPartners} />
          <PrivateRoute path="/clinic/integrations" component={ClinicIntegrations} />
          <PrivateRoute path="/clinic/invoices" component={ClinicInvoiceList} />

          <PrivateRoute
            exact
            path="/clinic/patient/:patientId/:patientJourney?"
            component={PatientDetail}
          />
          <PrivateRoute exact path="/clinic/patients" component={PatientList} />
          <PrivateRoute exact path="/clinic/archivePatients" component={ArchivePatients} />
          <Route exact path="/clinic/doctorApp-scan" component={DoctorAppRedirect} />
          <PrivateRoute exact path="/clinic/settings" component={Settings} />
          <PrivateRoute exact path="/clinic/settings/*" component={Settings} />

          

        </Switch>
      </div>
      <VideoCallComponent />
      </AppProvider>
    </>
  );
});

function App() {
  // store.dispatch(getAllCountriesAndCodes());


  useEffect(() => {
    store.dispatch(getAllCountriesAndCodes());
    store.dispatch(getAllCommonList());
    store.dispatch(loadUser());

  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/stl-viewer/:fileName" component={StlViewer} />
        </Switch>
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              // colorPrimary: "#00c6e9",
              colorPrimary: primaryColor,
              borderRadius: 4,
              // colorText:"#fff",
            },
            components: {
              Radio: {
                // buttonColor: "#fff",
                buttonSolidCheckedColor: secondaryColor,
                buttonCheckedBg: primaryColor,
                // colorText:"#fff",
              },
              Button: {
                colorTextLightSolid: companyType === "4" ? secondaryColor : buttonColor,
              },
              Tag: {
                defaultColor: tagTextColor,
                defaultBg: tagBackgroundColor,
              },
            },
          }}
        >
          <Main />
        </ConfigProvider>
      </Router>
    </Provider>
  );
}

onMessageListener()
  .then((payload) => {
    // updateNotificationState()
  })
  .catch((err) => console.log("failed: ", err));

navigator.serviceWorker.addEventListener("message", (event) => {
  updateNotificationState();
});

export default App;
