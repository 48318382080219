import { useState } from "react";
import {
  Form,
  Select,
  Row,
  Radio,
  Col,
  Input,
  Button,
  Popconfirm,
  message,
} from "antd";
import { memo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { FORM_CONSTANTS } from "../constants";
import { ReactComponent as AddIcon } from "assets/images/teeths/addIcon.svg";
import AppToolTip from "components/shared/AppToolTip/AppToolTip";
import { getPhasingInfo } from "messages/messageController";
import { companyType, primaryColor } from "colors-config";
const { TextArea } = Input;
const { Option } = Select;

const SGTreatmentType = ({
  onFormChange,
  disabled,
  showPrescriptionPhasing,
  treatmentOptions,
  prescriptionForm,
}) => {
  const [selectedTreatments, setSelectedTreatments] = useState([]);

  const handleTreatmentChange = (value, name, fieldKey) => {
    if (!value) {
      return;
    }
    const values = prescriptionForm.getFieldsValue()?.sgTreatmentTypes || [];
    const isDuplicate = values.some(
      (item, index) =>
        item?.smilelignTreatmentTypeId === value && index !== name
    );

    if (isDuplicate) {
      message.warning(
        "This treatment type is already selected. Please choose another."
      );
      prescriptionForm.setFields([
        {
          name: [`sgTreatmentTypes`, name, "smilelignTreatmentTypeId"],
          value: null, // Reset the duplicate field
        },
      ]);
      return;
    }

    // Update the form if no duplicates are found
    onFormChange("smilelignTreatmentTypeId", value);
  };

  const getFilteredOptions = currentIndex => {
    // Get all selected treatment type IDs
    const selectedValues =
      prescriptionForm
        .getFieldsValue()
        ?.sgTreatmentTypes?.map(item => item?.smilelignTreatmentTypeId) || [];

    // Exclude the value of the current field being edited
    return treatmentOptions.filter(
      option =>
        !selectedValues.includes(option.id) ||
        selectedValues[currentIndex] === option.id
    );
  };

  return (
    <>
      <h1 className="form-heading" style={{ margin: "0px 0px 10px 0px" }}>
        1. Treatment Type
      </h1>
      <div className="treatment-row aligner-row">
        <Form.List name="sgTreatmentTypes" disabled={disabled}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <div key={key} style={{ position: "relative" }}>
                  {fields.length > 1 && (
                    <div
                      className="num-btn"
                      style={{ background: primaryColor }}
                    >
                      {index + 1}
                    </div>
                  )}

                  <Row gutter={[16, 8]} align="middle">
                    <Col lg={companyType === 1 ? 9 : 16}>
                      <Form.Item
                        {...restField}
                        fieldKey={[fieldKey, "smilelignTreatmentTypeId"]}
                        name={[name, "smilelignTreatmentTypeId"]}
                        label="Treatment Type"
                        rules={[
                          {
                            required: true,
                            message: "Please select a type",
                          },
                        ]}
                      >
                        <Select
                          disabled={disabled}
                          size="large"
                          className="form-select"
                          placeholder="Select Treatment Type"
                          notFoundContent={
                            <p>No treatment type added in the clinic</p>
                          }
                          onChange={value =>
                            handleTreatmentChange(value, name, fieldKey)
                          }
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          showSearch
                        >
                          {getFilteredOptions(name).map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.treatmentName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={companyType === 1 ? 13 : 16}>
                      <Form.Item
                        {...restField}
                        fieldKey={[fieldKey, "smilelignArches"]}
                        name={[name, "smilelignArches"]}
                        label="Arches Treated"
                        rules={[
                          {
                            required: true,
                            message: "Please select an Option",
                          },
                        ]}
                      >
                        <Radio.Group disabled={disabled}>
                          <Radio value={FORM_CONSTANTS.UPPER_AND_LOWER}>
                            Upper and Lower
                          </Radio>
                          <Radio value={FORM_CONSTANTS.UPPER}>Upper</Radio>
                          <Radio value={FORM_CONSTANTS.LOWER}>Lower</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col lg={2}>
                      {fields.length > 1 && (
                        <Popconfirm
                          title="Delete"
                          description="Are you sure to delete this treatment type?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => {
                            const updatedSelections = [...selectedTreatments];
                            updatedSelections[name] = null; // Clear the removed selection
                            setSelectedTreatments(updatedSelections);
                            remove(name);
                          }}
                        >
                          <DeleteOutlined
                            style={{
                              color: "black",
                              fontSize: "16px",
                              float: "left",
                              paddingTop: "20px",
                            }}
                          />
                        </Popconfirm>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
              <Button
                icon={
                  <AddIcon
                    style={{
                      fill: fields.length === 3 ? "grey" : primaryColor,
                    }}
                  />
                }
                className="addOn-btn"
                onClick={() => {
                  const selectedValues =
                    prescriptionForm
                      .getFieldsValue()
                      ?.sgTreatmentTypes?.map(
                        item => item?.smilelignTreatmentTypeId
                      ) || [];

                  const defaultOption = treatmentOptions.find(
                    option => !selectedValues.includes(option.id)
                  );
                  add({
                    smilelignTreatmentTypeId: defaultOption?.id || null,
                  });
                }}
                disabled={disabled || fields.length === 3}
              >
                Add Another Treatment
              </Button>
            </>
          )}
        </Form.List>
      </div>

      {companyType === 5 ? (
        <div className="phasing-row" style={{ paddingTop: "30px" }}>
          <Row gutter={[16, 16]}>
            <Col lg={7}>
              <div className="label">
                <label>
                  <span style={{ color: "#ff4d4f" }}>*</span>Phasing
                  <AppToolTip title={getPhasingInfo()}>
                    {/* <AppInfoIcon iconStyles={{ marginLeft: "10px" }}/> */}
                  </AppToolTip>
                </label>
              </div>
            </Col>
            <Col lg={16}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please select an Option",
                  },
                ]}
                name="phasing"
                label=""
              >
                <Radio.Group
                  onChange={e => onFormChange("phasing", e.target.value)}
                  disabled={disabled}
                >
                  <Radio value={FORM_CONSTANTS.YES}>Yes</Radio>
                  <Radio value={FORM_CONSTANTS.NO}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {showPrescriptionPhasing && (
            <Row gutter={[16, 16]}>
              <Col lg={24}>
                <Form.Item name="prescriptionPhasing" label="">
                  <TextArea
                    placeholder="Enter details"
                    rows={4}
                    onChange={e =>
                      onFormChange("prescriptionPhasing", e.target.value)
                    }
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
      ) : null}
    </>
  );
};

export default memo(SGTreatmentType);
