import React from "react";
import { Row, Col, Card, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "../../assets/styles/components/onboarding/PatientsScreen.scss";
import FormImg from "../../assets/images/form.png";
import SmilelignFormImg from "../../assets/images/FormSmilelign.svg";
import gareIcon from "../../assets/images/gareIcon.png";
import successIcon from "../../assets/images/success.png";
import Patients from "../../assets/images/Patients.png";
import AppButton from "components/shared/AppButton/AppButton";
import { openNewPatientDailog } from "services/redux/actions/clinicActions";
import { useDispatch } from "react-redux";

import { companyType, tagBackgroundColor } from "colors-config";
import AddPatientIcon from "components/shared/Icon/AddPatientIcon";
const { Title, Text } = Typography;

const AddPatientsScreen = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const openPatientDialog = () => {
    dispatch(openNewPatientDailog(true));
  };

  return (
    <div className="screen-container">
      <Col
        className="screen-col"
        xs={{ span: 18, offset: 3 }}
        sm={{ span: 18, offset: 3 }}
        md={{ span: 18, offset: 3 }}
        lg={{ span: 18, offset: 3 }}
        xl={{ span: 18, offset: 3 }}
      >
        <Row className="options-container">
          <Col xs={24} className="congrats-col">
            <Row>
              <Col span={21}>
                <Title level={3} className="congrats-title">
                  Congratulations! 🎉
                </Title>
                <Text className="instruction-text">
                  Please choose one of the options below to get started:
                </Text>
              </Col>
              <Col span={3}>
                <img className="success-image" src={successIcon} alt="success-img"/>
              </Col>
            </Row>
          </Col>
          <Col xs={24} className="add-patient-col">
            <Card
              className="option-card add-patient-card"
              style={{ backgroundColor: tagBackgroundColor }}
              onClick={openPatientDialog}
            >
              <Row justify="space-between" align="middle">
                <Col lg={12} offset={4}>
                  <Row className="left-section add-patient-left">
                    <Col lg={4}>
                      <div className="icon-container">
                        <AddPatientIcon />
                      </div>
                    </Col>
                    <Col lg={20}>
                      <div className="option-content add-patient-content">
                        <Title level={4} className="add-patient-title">
                          Add Patients
                        </Title>
                        <Text className="add-patient-description">
                          Continue to add a patient and start your first case.
                        </Text>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={8}>
                  <div className="right-section add-patient-right">
                    <img
                      src={companyType === 5 ? SmilelignFormImg : FormImg}
                      alt="New Patient Form Preview"
                      className="form-image add-patient-image"
                    />
                    <div style={{ display: "inline-block" , paddingRight:"20px"}}>
                      <RightOutlined />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} className="advanced-setup-col">
            <Card
              className="option-card advanced-setup-card no-padding"
              onClick={() => history.push("/clinic/settings/payments")}
              hoverable
            >
              <div className="left-section advanced-setup-left">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2em",
                  }}
                >
                  <div>
                    <img
                      src={gareIcon}
                      alt="New Patient Form Preview"
                      className="form-image add-patient-image"
                    />
                  </div>
                  <div className="option-content advanced-setup-content">
                    <Title
                      level={6}
                      className="add-patient-title advanced-setup-title"
                    >
                      Advanced Setup
                    </Title>
                    <Text className="advanced-setup-description">
                      Treatment Product, Online Payments, and Appointments.
                    </Text>
                  </div>
                </div>
                <div>
                  <RightOutlined />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={14} offset={10} className="finish-col">
            <AppButton
              shape="round"
              style={{ padding: "0 40px", height: "35px" }}
              type="primary"
              onClick={() => history.push("/clinic/patients")}
              className="guideBtn finish-btn"
            >
              Finish & Discover More
            </AppButton>
          </Col>
        </Row>
      </Col>
      <div style={{ height: "120px" }}></div>
    </div>
  );
};

export default AddPatientsScreen;
