import AppSettingsContext from "Context/useAppContext";
import {useState, useEffect} from "react"
import { useSelector } from 'react-redux'
import { getClinicAppointmentsSelector, receivePaymentAppSelector,getClinicProfileSelector  } from "services/redux/selectors/clinicSelectors";

const AppProvider = ({ children }) => {
  const clinicAppointments = useSelector(getClinicAppointmentsSelector);
  const receiveAppPayment = useSelector(receivePaymentAppSelector);
  const clinicProfile = useSelector(getClinicProfileSelector);


  const [clinicConfig,setClinicConfig] = useState({
        appointment: Boolean(clinicAppointments?.bookAppointmentApp),
        settings: true,
        receiveAppPayment: Boolean(receiveAppPayment),
        isProductAvailable: Boolean(clinicProfile?.isProductAvailable)
    })

    useEffect(()=>{
      setClinicConfig({
        appointment: Boolean(clinicAppointments?.bookAppointmentApp),
        settings: true,
        receiveAppPayment: Boolean(receiveAppPayment),
        isProductAvailable: Boolean(clinicProfile?.isProductAvailable)
      })
    },[clinicProfile,receiveAppPayment, clinicAppointments])
    return (
      <AppSettingsContext.Provider value={{ clinicConfig,setClinicConfig}}>
        {children} {/* All children will have access to the context */}
      </AppSettingsContext.Provider>
    );
  };
  
  export { AppProvider };
  