import { getFileUrlAPI } from "api/common";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import StlViewer from "stl-viewer-component"; 
import { StlViewer } from "react-stl-file-viewer";
import { getSTLUrl } from "utilities/treatment";
// Make sure to import StlViewer if it's an external component

export default function StlViewerComponent() {
  const { fileName } = useParams();
  const [loading, setLoading] = useState(true);
  const [modalUrl, setModalUrl] = useState("");
  const [volume, setVolume] = useState(0);

  const fetchFileUrl = async () => {
    try {
      const { data:url } = await getFileUrlAPI({ fileName });
      
      // setModalUrl(getSTLUrl("", url));
      setModalUrl(url);
    } catch (error) {
      console.error("Failed to fetch file URL:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Call fetchFileUrl when the component mounts or when fileName changes
    if (fileName) {
      fetchFileUrl();
    }
  }, [fileName]); // Add fileName as a dependency

  return (
    <div>
      {loading ? (
        <div>Loading....</div>
      ) : (
        <StlViewer
          width={750}
          height={600}
          url={modalUrl}
          groundColor="rgb(255, 255, 255)"
          objectColor="rgb(137, 137, 137)"
          skyboxColor="rgb(255, 255, 255)"
          gridLineColor="rgb(0, 0, 0)"
          lightColor="rgb(255, 255, 255)"
          volume={setVolume}
        />
      )}
    </div>
  );
}