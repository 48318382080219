import { Row, Col, Alert } from "antd";
import styles from "./appointment.module.scss";
import { ReactComponent as CalendarOff } from "assets/images/CalendarOff.svg";
import AppointmentPhone from "components/shared/Icon/AppointmentPhone";
import { ReactComponent as BulbIcon } from "assets/images/BulbIcon.svg";
import { appointmentMessage } from "messages/messages";
import AppButton from "components/shared/AppButton/AppButton";
import { useHistory } from "react-router-dom";

const OffAppointment = ({onAvailabilityChange}) => {
  const history = useHistory();
  return (
    <div className={styles["appointment-view-container"]}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Appointments</h2>
        </Col>
      </Row>

      <Alert
        className={styles["treatment-view-info"]}
        message={appointmentMessage}
        type="info"
        showIcon
        icon={<BulbIcon />}
      />

      <div className={styles["empty-container"]}>
        <CalendarOff />

        <h2>Your availability is Off. </h2>
        <p>Do you want to book appointment through this portal?</p>

        <AppButton type="primary" shape="round" size="large" onClick={onAvailabilityChange} style={{width:"270px"}}>
          Yes Turn On
        </AppButton>
        <AppButton shape="round" size="large" 
        onClick={() => {
          history.push("/clinic/settings/products")
        }}
         style={{width:"270px"}}>
        Set up later
        </AppButton>
      </div>

      <div className={styles["form-user-phone"]}>
        <AppointmentPhone />
      </div>
    </div>
  );
};

export default OffAppointment;
