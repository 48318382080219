import { useEffect, useState, useContext } from "react";
import { Form, message } from "antd";
import PaymentsForm from "./PaymentsForm/PaymentsForm";
import FormFooter from "../../shared/FormFooter/FormFooter";
import { useDispatch } from "react-redux";
import {
  updatePaymentMethod,
} from "components/shared/modals/PaymentSettings/services/payment.api";

import { useSelector } from "react-redux";
import { clinicIdSelector, clinicPayments, receivePaymentAppSelector } from "services/redux/selectors/clinicSelectors";
import { getCardPaymentData, mapPaymentData, validatePayments } from "utilities/settings";
import OffPayments from "./OffPayments/OffPayments";
import PaymentsView from "./PaymentsView/PaymentsView";
import { getClinicProfile, updateClinicPaymentsAction, updateClinicReceivePaymentProfileAction } from "services/redux/actions/clinicActions";
import { v4 as uuidv4 } from "uuid";
import { PaymentTypes } from "components/shared/modals/PaymentSettings/constants";
import AppSettingsContext from "Context/useAppContext";

const Payments = () => {
  const dispatch = useDispatch()
  const [paymentsForm] = Form.useForm();
  const clinicId = useSelector(clinicIdSelector);
  const payments = useSelector(clinicPayments);
  const receiveAppPayment = useSelector(receivePaymentAppSelector);
  const [loading, setLoading] = useState(false);
  const [stripeEnabled,setStripeEnabled] = useState(false)
  
  const [editPayments, setEditPayments] = useState(false);

  const { setClinicConfig } = useContext(AppSettingsContext);

  useEffect(()=>{
    if(payments?.length > 0){
      const formData = mapPaymentData(payments);
      if(formData.length < 2){
        formData.push({
          buttonText: '',
          default: false,
          isActive: true,
          url: "",
          id: `(New)${uuidv4()}`,
        })
      }
      const finalObj = {
        clinicPayments: formData,
      };
      const cardData = getCardPaymentData(payments)
      setStripeEnabled(cardData)
      paymentsForm.setFieldsValue(finalObj);
    }
  },[payments])

  useEffect(() => {
    paymentsForm.setFieldsValue({ receiveAppPayment });
  }, [receiveAppPayment, paymentsForm])


  const paymentsSubmit = async (values) => {
    
    const isValid = validatePayments(values);
    if (!isValid) {
      return message.error("Duplicate Payment URL link found. Please ensure each payment method has a unique URL.")
    }
    try {

      setLoading(true);
      const payClinic = payments.find(el => el.type === 'IN_CLINIC');
      const updatedValues = [payClinic, ...values.clinicPayments];

      const paymentPayload = updatedValues.map(data => {
        return {
          id: data.id,
          clinicId,
          type: data.type ? data.type : PaymentTypes.OTHERS,
          buttonText: data.buttonText,
          url: data.url || '',
          isActive: data.isActive || false,
          default: data.default || false
        }
      })

      const payload={
        receiveAppPayment:values.receiveAppPayment,
        payments: paymentPayload
      }
      
      const { data } = await updatePaymentMethod(clinicId, payload);

      setClinicConfig(prev=> ({...prev, receiveAppPayment: values.receiveAppPayment}))
 
      dispatch(updateClinicPaymentsAction(data?.data?.paymentTypes));
      dispatch(updateClinicReceivePaymentProfileAction(values.receiveAppPayment));

      console.log("Online Payments",);
      if(!values.receiveAppPayment){
        //run api
        await dispatch(getClinicProfile());
      }

      message.success("Payment method updated successfully");
    } catch (error) {
      console.log("Error submitting payment method");
    } finally {
      setEditPayments(false);
      setLoading(false);
    }

  };

  const onOk = () => {
    paymentsForm.submit();
  };
  const onCancel = () => {
    setEditPayments(false);
  };

 
  return (
    <div>
        {editPayments ? (
          <>
            <PaymentsForm
              onFinish={paymentsSubmit}
              paymentsForm={paymentsForm}
              clinicId={clinicId}
              receiveAppPayment={receiveAppPayment}
              stripeEnabled={stripeEnabled}
            />
            <FormFooter
              onCancelClick={onCancel}
              onOkClick={onOk}
              loading={loading}
            />
          </>
        ) : payments?.length  > 2 || stripeEnabled ? (
          <PaymentsView
            setEditPayments={setEditPayments}
            receiveAppPayment={receiveAppPayment}
          />
        ) : (
          <OffPayments setEditPayments={setEditPayments} />
        )}
    </div>
  );
};

export default Payments;
