export const stlFilesOrder = (file) => {
    switch (file.name.toLowerCase()) {
      case "upperarch.stl":
        return 1;
      case "lowerarch.stl":
        return 2;
      case "bitescan1.stl":
        return 3;
      case "bitescan2.stl":
        return 4;
      default:
        return 5;
    }
  };
  
  
  export const getSTLUrl = (file, fileUrl) => { 
    const withOutAccessKeyImg = fileUrl.indexOf("?");
    const processedURL = withOutAccessKeyImg !== -1 
      ? fileUrl.slice(0, withOutAccessKeyImg) 
      : fileUrl;
    return processedURL.endsWith(".stl") ? processedURL : `${processedURL}.stl`;
  };
  


  export const calculateProductsTotal = (products) => {
    let totalString = '';
    const totalsByCurrency = {};
  
    products?.forEach((item) => {
      if (!item.free) {
        const currency = item.currency || 'EUR';
        let productPrice = item.price || 0;  // Use price instead of netPrice
  
        // Apply discountAmount if available
        if (item.discountAmount) {
          productPrice = Math.max(productPrice - item.discountAmount, 0);
        } 
        // Apply discountPercentage if available
        else if (item.discountPercentage) {
          const discount = (item.discountPercentage / 100) * productPrice;
          productPrice = Math.max(productPrice - discount, 0);
        }
        const productTotal = productPrice * (item.quantity || 1);
        if (!totalsByCurrency[currency]) {
          totalsByCurrency[currency] = 0;
        }
        totalsByCurrency[currency] += productTotal; 
      }
    });
    totalString = Object.entries(totalsByCurrency)
      .map(([currency, total]) => `${currency}: ${total.toFixed(2)}`)
      .join(', ');
  
    return totalString || '0.00';
  };
  
  const calculateDiscountGiven = (product) => {
    const { price, discountAmount, discountPercentage } = product;
    if (discountAmount) {
      return Math.min(discountAmount, price).toFixed(2);
    }
    if (discountPercentage) {
      return ((discountPercentage / 100) * price).toFixed(2); 
    }
    return "0.00"; 
  };
  
  export const productsDataMapper = (products) => {
    let data = [];
    if (products) {
      data = products.map((prod, i) => {
        return {
          key: `${i}`,
          labProduct: prod?.productName,
          currency: prod.currency,
          qty: prod?.quantityType === "Per aligner" ? prod?.quantity : "--",
          total: `${
            prod?.free 
              ? `${prod?.currency} 0.00 (Free)` 
              : `${prod?.currency} ${( (prod.quantity || 1) * calculateNetPrice(prod) ).toFixed(2)}`
          }`,
          free: prod.free,
          netPrice:  priceCalculator(prod.quantity, calculateNetPrice(prod)),
          price: priceCalculator(prod.quantity, prod.price ),
          discountPrice: priceCalculator(prod.quantity, calculateDiscountGiven(prod)) ,
          discountAmount: priceCalculator(prod.quantity,prod.discountAmount),
          discountPercentage: prod.discountPercentage, 
          discountGiven : Boolean(prod.discountAmount || prod.discountPercentage)
        };
      });
    }
    return data;
  };
  
  export const initialProductsFormData = () => {
    return [
      {
        labProductId: null,
        arches: null,
        price: "",
        quantity: null,
        total: 0,
        free: false,
      },
    ];
  };
  const calculateDiscountPrice = (product) => {
    const { price, discountAmount, discountPercentage } = product;
    if (discountAmount) {
      return Math.max(price - discountAmount, 0);
    }
    if (discountPercentage) {
      const discount = (discountPercentage / 100) * price;
      return Math.max(price - discount, 0);
    }
    return 0;
  };
  
  
  const calculateNetPrice = (product) => {
    const { price, discountAmount, discountPercentage } = product;
    if (discountAmount) {
      return Math.max(price - discountAmount, 0);
    }
    if (discountPercentage) {
      const discount = (discountPercentage / 100) * price;
      return Math.max(price - discount, 0);
    }
    return price;
  };
  
  export const labProductsFormMapper = (products) => {
    let data = [];
    if (products) {
      data = products.map((prod, i) => {
        return {
          id: prod.id,
          labProductId: prod.labProductId,
          arches: prod.arches,
          labProduct: prod.productName,
          quantity: prod.quantity || 1,
          price: prod.price,
          quantityType: prod.quantityType,
          total: prod.price,
          free: prod.free,
          currency: prod.currency,
          discountAmount: prod.discountAmount,
          discountPercentage: prod.discountPercentage,
          discountPrice: calculateDiscountPrice(prod),
          netPrice: calculateNetPrice(prod)
        };
      });
    }
    return data;
  };
  
  const priceCalculator = (quantity, amount) => {
    const qty = quantity || 1;
    if (!amount ) return "0.00";
    return (qty * amount).toFixed(2);
  
  };

  const productMapper = (product) => {
    return {
      productName: product.productName,
      arches: product.arches,
      currency: product.currency,
      free: product?.free,
      quantity: product.quantityType === "Per aligner" ? product.quantity : "--",
      price: product?.free
        ? product.currency + " " + "0.00 (Free)"
        : product.price
        ? `${product.currency} ${product.price.toFixed(2)}`
        : "--",
        total: `${
          product?.free 
            ? `${product?.currency} 0.00 (Free)` 
            : `${product?.currency} ${( (product.quantity || 1) * calculateNetPrice(product) ).toFixed(2)}`
        }`,
        netPrice:  priceCalculator(product.quantity, calculateNetPrice(product)),
        productPrice: priceCalculator(product.quantity, product.price ),
        discountPrice: priceCalculator(product.quantity, calculateDiscountGiven(product)) ,
        discountAmount: priceCalculator(product.quantity,product.discountAmount),
        discountPercentage: product.discountPercentage, 
        discountGiven : Boolean(product.discountAmount || product.discountPercentage)
    };
  };
  
  export const orderDetailMapper = (orderDetails) => {
    return {
      productList: orderDetails?.labProductList?.length
        ? orderDetails.labProductList.map((product) => productMapper(product))
        : [
            {
              productName: "--",
              arches: "--",
              currency: "--",
              free: "--",
              quantity: "--",
              price: 0,
              total: "--",
            },
          ],
      payment_status: orderDetails?.payment_status,
      orderNote: orderDetails?.orderNote ? orderDetails?.orderNote : "--",
      shipping_address: orderDetails?.shipping_address,
      order_status: orderDetails?.order_status,
      totalPrice: calculateProductsTotal(orderDetails?.labProductList),
  
    };
  }

  export function getProductServiceName(dataObj) {
    const labProductList = dataObj?.labProductList;
  
    if (!labProductList || labProductList.length === 0) {
        return "Not Available";
    }
  
    if (labProductList.length === 1) {
        return labProductList[0].productName;
    }
  
    return `${labProductList[0].productName}, +${labProductList.length - 1}`;
  }
  
  export function getProductServiceTotalQuantity(dataObj) {
    const labProductList = dataObj?.labProductList;
  
    if (!labProductList || labProductList.length === 0) {
        return "Not Available"; // Return 0 if there are no products
    }
  
    return labProductList.reduce((total, product) => {
        return total + (product.quantity || 0); // Add the quantity of each product to the total
    }, 0);
  }
  
  export function getProductServiceNames(dataObj) {
    const labProductList = dataObj?.labProductList;
  
    if (!labProductList || labProductList.length === 0) {
        return ""; 
    }
  
    return dataObj.labProductList.map(product => product.productName).join(', ')
  }
export  const sumQuantities = (products) => {
    return products.reduce((total, product) => total + (product.quantity || 0), 0);
  };



  export const calculateFormProductsTotal = (products) => {
    let totalString = '';
    const totalsByCurrency = {};
  
    products?.forEach((item) => {
      if (!item.free) {
        const currency = item.currency || 'EUR';
        const productTotal = (item.price || 0) * (item.quantity || 1);
        if (!totalsByCurrency[currency]) {
          totalsByCurrency[currency] = 0;
        }
        totalsByCurrency[currency] += productTotal;
      }
    });
    totalString = Object.entries(totalsByCurrency)
      .map(([currency, total]) => `${currency}: ${total.toFixed(2)}`)
      .join(', ');
  
    return totalString || '0.00';
  };
  