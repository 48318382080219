import axios from "axios";
import store from "../services/redux/store";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { Auth } from "aws-amplify";

dayjs.extend(timezone);
export const baseUrl = process.env.REACT_APP_BASE_URL;
const tenantId = process.env.REACT_APP_SMILE_TENENT_ID;

export const getToken = async () => {
  const session = await Auth.currentSession();
  const accessToken = session.getIdToken();
  const accessTokenValue = accessToken.getJwtToken();

  return accessTokenValue;
};

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 3600000,
  // headers: {'tenantId': tenantId}
});

instance.interceptors.request.use(
  async (config) => {
    const clinic = store?.getState()?.clinic;
    let token = "";
    // if (
    //   !config.url.includes("checkEmailExists") &&
    //   !config.url.includes("lab/clinic-on-boarding/update/progress-status") &&
    //   !config.url.includes("/lab/clinic-on-boarding/get-onboarding-clinic")
    // ) {
    //   token = await getToken();
    // }
    config.headers["tenantId"] = tenantId;
    if (clinic.companyType == 4) {
      config.headers["tenantId"] =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.NA.ncku8QEPs6Sc9Rm89XUw_r__NIDwPfm-dCNDJSmy0Jc";
    }
    config.headers["timezone"] = dayjs.tz.guess();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
