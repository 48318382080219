import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Spin, message } from "antd";
import AppointmentForm from "./Components/Form/Form";
import AppointmentCalendar from "./Components/Calendar/Calendar";
import Slots from "./Components/Slots/Slots";
import { getClinicNewWorkingDays, getClinicWorkingDays, getHolidays, mapTimeSlotData } from "./utils";
import dayjs from "dayjs";
import {
  getClinicScheduleApi,
  getTimeSlotApi,
  getClinicAppointmentTypesApi,
  getClinicUsersApi,
  // getBookedAppointment,
  getServicesAndResources,
} from "./api";

const AppointmentContent = ({
  selectedPatient,
  setSelectedSlot,
  selectedSlot,
  setTimeSlots,
  timeSlots,
  dropDown,
  setDropDown,
  selectedDate,
  setSelectedDate,
  clinicAppointmentTypes,
  setClinicAppointmentTypes,
  clinicAppointmentNames,
  setClinicAppointmentNames,
  setClinicAppointmentServices,
  iprSelected,
  setClinicianNotAvailable,
  closeModal
}) => {
  const clinicId = useSelector((state) => state.clinic.clinicId);
  const [clinicWorkingSchedule, setClinicSchedule] = useState(null);
  const [loading, setLoading] = useState(false);
  const [slotLoading, setSlotLoading] = useState(true);
  const [clinicAppointmentsAll,setClinicAppointmentsAll]=useState([]);

  const [clinicUsers, setClinicUsers] = useState([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getClinicScheduleApi(clinicId),
      getClinicUsersApi(clinicId),
      getClinicAppointmentTypesApi(clinicId),
      getServicesAndResources(clinicId),
    ])

      .then(([res, clinicUsersRes, clinicTypes, servicesRes]) => {
        // const slots = mapTimeSlotData(timeSlotsRes.data?.body?.timeSlots);
        // setTimeSlots(slots);

        // const workingDays = getClinicWorkingDays(res?.data?.body.workdays);
        let newWorkingDays = {}
        if (res?.data?.body?.workingDays?.workingHours) {
          newWorkingDays = getClinicNewWorkingDays(res?.data?.body?.workingDays?.workingHours)
        } 
      
        const holidays = getHolidays(res?.data?.body?.holidays);
        
        const holidayDates = res?.data?.body?.holidays.map(date => dayjs(date).format('YYYY-MM-DD'));
        setClinicianNotAvailable(holidayDates.includes(selectedDate))
      
        setClinicSchedule(
          {
            workingDays:newWorkingDays,
            newWorkingDays,
            holidays: holidays || {},
            wokringPeriod: res?.data?.body?.working_period || [],
          } || null
        );

        if (clinicUsersRes?.data?.body?.data?.length) {
          setClinicUsers(clinicUsersRes.data.body.data);
        }

        if (servicesRes?.data?.body?.clinic_appointments?.length) {
          setClinicAppointmentServices(
            servicesRes.data.body.clinic_appointments
          );
          const clinicAppointments = servicesRes.data.body.clinic_appointments;
          setClinicAppointmentsAll(clinicAppointments);
          // For Appointment Names
          const appointmentNames = [];
          let appointmentTypes = [];
          clinicAppointments.map((cObj,index) => {
            const checkAppointmentNameExists = appointmentNames.find(
              (obj) => obj.label == cObj.appointment_name
            );
            if (!checkAppointmentNameExists) {
              appointmentNames.push({
                value: cObj.appointment_name_id,
                label: cObj.appointment_name,
              });
            }

            if(index==0){
              const checkAppointmentTypeExists = clinicAppointments.filter(
                (obj) => obj.appointment_name_id == cObj.appointment_name_id
              );
              checkAppointmentTypeExists.forEach(obj => {
                appointmentTypes.push({
                  value: obj.appointment_type_id,
                  label: obj.appointment_type,
                })
              })
            }
          });

          setClinicAppointmentNames(appointmentNames);
          const findFollowUpName = appointmentNames?.find(obj => obj.value == 6);
          if (iprSelected && findFollowUpName) {
            onfirstDropDownChange(findFollowUpName.value, "appointmentNameId",clinicAppointments);
          }
          else {
            onfirstDropDownChange(appointmentNames[0].value, "appointmentNameId",clinicAppointments);
          }

          // setClinicAppointmentTypes(appointmentTypes);
          // onDropDownSelect(appointmentTypes[0].value, "appointmentTypeId");

        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getSlots = async (
    date,
    appointmentTypeId,
    appointmentNameId,
    clinicianId
  ) => {
    if (!appointmentTypeId || !date || !appointmentNameId) {
      return;
    }
    try {
      setSlotLoading(true);
      const response = await getTimeSlotApi({
        clinicId,
        date,
        appointmentTypeId,
        appointmentNameId,
        clinicianId,
        time: dayjs().format("HH:mm:ss"),
      });
      setTimeSlots(mapTimeSlotData(response.data?.body?.timeSlots));
    } catch (err) {
      console.log("error in slots", err);
    } finally {
      setSlotLoading(false);
    }
  };

  useEffect(() => {
    getSlots(
      selectedDate,
      dropDown.appointmentTypeId,
      dropDown.appointmentNameId,
      dropDown.clinicianId
    );
  }, [
    dropDown.appointmentNameId,
    dropDown.appointmentTypeId,
    selectedDate,
    dropDown.clinicianId,
  ]);

  const onfirstDropDownChange = (value, name, clinicAppointments) => {
    const checkAppointmentTypeExists = clinicAppointments.filter(
      (obj) => obj.appointment_name_id == value
    );
    let appointmentTypes = checkAppointmentTypeExists.map(obj => {
      return {
        value: obj.appointment_type_id,
        label: obj.appointment_type,
      }
    });
    if (iprSelected) {
      const iprAppointmentTypes = appointmentTypes.filter(obj => obj.label.toLowerCase() == "video consultation");
      if (!iprAppointmentTypes.length) {
        message.error("No Video Consultation appointment type  is added");
      }
      else {
        appointmentTypes = iprAppointmentTypes
      }

    }
    setClinicAppointmentTypes(appointmentTypes);
    setDropDown((prev) => ({
      ...prev,
      [name]: value,
      appointmentTypeId: appointmentTypes[0].value
    }));
  }

  const onDropDownSelect = (value, name) => {
    setDropDown((prev) => ({ ...prev, [name]: value }));
    if (name == "appointmentNameId") {
      const checkAppointmentTypeExists = clinicAppointmentsAll.filter(
        (obj) => obj.appointment_name_id == value
      );
      let appointmentTypes = checkAppointmentTypeExists.map(obj => {
        return {
          value: obj.appointment_type_id,
          label: obj.appointment_type,
        }
      })
      if (iprSelected) {
        // appointmentTypes = appointmentTypes.filter(obj => obj.label.toLowerCase() != "video consultation");
        const iprAppointmentTypes = appointmentTypes.filter(obj => obj.label.toLowerCase() == "video consultation");

      if (iprAppointmentTypes.length) {
        appointmentTypes = iprAppointmentTypes
      }
      }
      setClinicAppointmentTypes(appointmentTypes);
      onDropDownSelect(appointmentTypes[0].value, "appointmentTypeId");
    }
  };

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          <div style={{ marginBottom: "1.2rem" }}>
            <AppointmentForm
              selectedPatient={selectedPatient}
              clinicUsers={clinicUsers}
              clinicAppointmentTypes={clinicAppointmentTypes}
              clinicAppointmentNames={clinicAppointmentNames}
              dropDown={dropDown}
              // isUpdate={isUpdate}
              onSelect={onDropDownSelect}
            />
          </div>
          <Row gutter={[0, 32]}>
            <Col md={11}>
              <AppointmentCalendar
                setSelectedDate={setSelectedDate}
                // getSlots={getSlots}
                clinicWorkingSchedule={clinicWorkingSchedule}
              />
            </Col>
            <Col md={13}>
              <div
                className="custom-slots"
                style={{
                  marginLeft: "1rem",
                  height: "327px",
                  overflowY: "auto",
                }}
              >
                <Slots
                  slotLoading={slotLoading}
                  setSelectedSlot={setSelectedSlot}
                  selectedSlot={selectedSlot}
                  timeSlots={timeSlots}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default AppointmentContent;
