import { Select } from "antd";
import { getPatientCountryCodesList } from "services/redux/selectors/clinicSelectors";
import { useSelector } from "react-redux";
import styles from "./select.module.scss"; // Import your SCSS module

const CountryCodeSelect = ({ value, onChange, disabled }) => {
  const countryCodes = useSelector(getPatientCountryCodesList);

  return (
    <Select
      size="large"
      disabled={disabled}
      showSearch
      value={value} // Controlled by Form
      onChange={onChange} // Controlled by Form
      dropdownClassName="country-dropdown"
      className={styles["country-select"]}
      dropdownStyle={{ width: "300px" }}
      style={{ width: 110, }}
      labelRender={(props) => props.label}
      options={countryCodes?.map((code, index) => {
        return {
          ...code,
          key: index,
          value: code.countryCallingCodes,
          label: <><span className="emoji-font">{code.emoji}</span> {code.countryCallingCodes}</>
        };
      })}
      optionRender={(option) => {
        const { data } = option;
        return (
          <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}>
            <span className="emoji-font">{data.emoji}</span>
            <span>{data.countryCallingCodes}</span>
            <span>| {data.name}</span>
          </div>
        );
      }}
      filterOption={(input, option) => {
        const lowerCasedInput = input.toLowerCase();
        return (
          option.value.toLowerCase().includes(lowerCasedInput) ||
          option.name.toLowerCase().includes(lowerCasedInput)
        );
      }}
    />
  );
};

export default CountryCodeSelect;
