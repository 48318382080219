import { useState } from "react";
import ProfileForm from "./ProfileForm/ProfileForm";
import ProfileView from "./ProfileView/ProfileView";
import { useSelector } from "react-redux";
import { getClinicProfileSelector, clinicIdSelector } from "services/redux/selectors/clinicSelectors";

const Profile = () => {
  const clinicProfile = useSelector(getClinicProfileSelector);
  const clinicId = useSelector(clinicIdSelector);
  //  console.log("clinicProfileclinicProfileclinicProfile", clinicProfile)
  const [editProfile, setEditProfile] = useState(false);
  return (
    <div>
      {editProfile ? (
        <ProfileForm
          setEditProfile={setEditProfile}
          clinicProfile={clinicProfile}
          clinicId={clinicId}
        />
      ) : (
        <ProfileView
          setEditProfile={setEditProfile}
          clinicProfile={clinicProfile}
        />
      )}
    </div>
  );
};

export default Profile;
