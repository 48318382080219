import { useState, useEffect,useMemo } from "react";
import moment from "moment";
import { Modal } from "antd";
import { convertToMinutes } from "../../../utilities/commonMethod";
import { useSelector } from "react-redux";
import {
  getClinicProfileSelector
} from "services/redux/selectors/clinicSelectors";


const DeleteConfirmationModal = ({
  title,
  content,
  onConfirmation,
  showModal,
  setShow,
  selectedAppointment,
  mode,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const clinic = useSelector(getClinicProfileSelector);


  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
    onConfirmation();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  
const appointmentDateTime = useMemo(() => {
  const startMinutes = convertToMinutes(
    selectedAppointment?.appointment_start_time
  );
  const endMinutes = convertToMinutes(
    selectedAppointment?.appointment_end_time
  );

  return {
    date: moment(selectedAppointment?.appointment_date).format("MMM Do YYYY"),
    startTime: moment(selectedAppointment?.appointment_date)
      .startOf("day")
      .add(startMinutes, "minutes")
      .format("hh:mm A"),
    endTime: moment(selectedAppointment?.appointment_date)
      .startOf("day")
      .add(endMinutes, "minutes")
      .format("hh:mm A"),
  };
}, [selectedAppointment]);

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        okText="Confirm"
        onCancel={handleCancel}
        okButtonProps={{
          danger: true,

          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        {mode === "onboarding" && (
          <p> Are you sure you want to remove ({content})</p>
        )}
        {mode === "cancel" && (
       <p>
       Are you sure you want to remove {content} for{" "}
       <b>
         {appointmentDateTime?.date}
       </b>{" "}
       from{" "}
       
       <b>
         {appointmentDateTime?.startTime}
         {" - "}
         {appointmentDateTime?.endTime}
       </b>
     {"  with "}
       <b>{`${clinic?.clinic_name}`}</b>
     </p>
        )}
        {mode === "status" && (
          <p>
            {" "}
            <p>
              Are you sure you want to change the patient (
              {selectedAppointment.patient_first_name +
                " " +
                selectedAppointment.patient_last_name}
              ) appointment status to no show?
            </p>
          </p>
        )}
        {mode === "treatmentCancel" && (
          <p>Are you sure you want to cancel this treatment</p>
        )}
        {mode === "deleteImage" && (
          <p>Are you sure you want to delete {content} permanently.</p>
        )}
      </Modal>
    </>
  );
};

export default DeleteConfirmationModal;
