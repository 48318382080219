import { Row, Col, Alert } from "antd";
import styles from "./offPayment.module.scss";
import { ReactComponent as PaymentCard } from "assets/images/PaymentCard.svg";
import { ReactComponent as BulbIcon } from "assets/images/BulbIcon.svg";
import { offPaymentMessage } from "messages/messages";
import AppButton from "components/shared/AppButton/AppButton";
import {useHistory} from "react-router-dom";
import { ReactComponent as PaymentsPhone } from "assets/images/PaymentsPhone.svg";

const OffPayments = ({setEditPayments}) => {
  const history = useHistory();
  return (
    <div className={styles["payment-view-container"]}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Payment Setups</h2>
        </Col>
      </Row>

      <Alert
        className={styles["payment-view-info"]}
        message={offPaymentMessage}
        type="info"
        showIcon
        icon={<BulbIcon />}
      />

      <div className={styles["empty-container"]}>
        <PaymentCard />
        <h2>No payment methods added. </h2>
        <p>Would you like to add one now?</p>
        <AppButton
          type="primary"
          shape="round"
          size="large"
          onClick={() => {
            setEditPayments(true);
          }}
          style={{ width: "270px" }}
        >
          Yes, Continue
        </AppButton>
        <AppButton
          shape="round"
          size="large"
          onClick={() => {
            history.push("/clinic/settings/appointments")
          }}
          style={{ width: "270px" }}
        >
          Set up later
        </AppButton>
      </div>

      <div className={styles["form-user-phone"]}>
        <PaymentsPhone />
      </div>
    </div>
  );
};

export default OffPayments;
