import { TREATMENT_ORDER_KEY, APPLIANCES_ORDER_KEY } from "./constants";

export const placeOrderItems = (
  handleOnClick,
  currentOrdersSelection,
  isTpReady = false
) => {
  const options = [
    {
      key: APPLIANCES_ORDER_KEY,
      label: "Appliances",
      onClick: () => handleOnClick(APPLIANCES_ORDER_KEY),
    },
  ];

  if (isTpReady) {
    options.unshift({
      key: TREATMENT_ORDER_KEY,
      label: "Treatment",
      onClick: () => handleOnClick(TREATMENT_ORDER_KEY),
    });
  }

  return options;
};
