import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Flex,
  Popconfirm,
  message,
  Empty,
  Typography,
} from "antd";
import { ReactComponent as EditIcon } from "assets/images/edit_icon.svg";
import AppButton from "components/shared/AppButton/AppButton";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as BulbIcon } from "assets/images/BulbIcon.svg";
import styles from "./treatment.module.scss";
import { treatmentProductsMessage } from "messages/messages";
import { primaryColor } from "colors-config";
import ProductModal from "../ProductModal/ProductModal";
import { deleteClinicProductAPI } from "api/settingsApi";
import { useDispatch } from "react-redux";
import { updateClinicTreatmentProductsAction } from "services/redux/actions/clinicActions";
import { getClinicCurrencySelector } from "services/redux/selectors/clinicSelectors";
import { useSelector } from "react-redux";

import { getAllPaymentMethods } from "components/shared/modals/PaymentSettings/services/payment.api";
import { getCurrencyName, getPaymentName } from "utilities/settings";

const TreatmentProductsView = ({
  clinicTreatmentProducts,
  clinicId,
  showProductModal,
  setShowProductModal,
}) => {
//  console.log("clinicTreatmentProducts", clinicTreatmentProducts)
  const dispatch = useDispatch();
  const [editTreatmentProduct, setEditEditTreatmentProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  const [paymentList, setPaymentList] = useState([]);
  const clinicCurrenciesList = useSelector(getClinicCurrencySelector)

  const fetchPayments = async () => {
    const resPayments = await getAllPaymentMethods(clinicId);

    setPaymentList(
      resPayments.data.data.paymentTypes.filter((el) => el.isActive)
    );
  };

  useEffect(() => {
    if (clinicId) {
      fetchPayments();
    }
  }, [clinicId]);

  // const [treatmentProducts, setTreatmentProducts] = useState([
  //   {
  //     name: "Treatment - Extended",
  //     currency: "EUR",
  //     price: "400",
  //     discount: "--",
  //     total: "400 EUR",
  //     paymentTypes: ["Pay in Clinic", "Pay with card"],
  //     description: "--",
  //   },
  //   {
  //     name: "Treatment - Extended",
  //     currency: "EUR",
  //     price: "400",
  //     discount: "--",
  //     total: "--",
  //     paymentTypes: ["Pay in Clinic", "Pay with card"],
  //     description: "--",
  //   },
  //   {
  //     name: "Treatment - Light",
  //     currency: "EUR",
  //     price: "400",
  //     discount: "--",
  //     total: "--",
  //     paymentTypes: ["Pay in Clinic", "Pay with card"],
  //     description: "--",
  //   },
  // ]);

  const onDelete = async (productId) => {
    const products = [...clinicTreatmentProducts];
    try {
      setLoading(true);
      const { data } = await deleteClinicProductAPI(productId, clinicId);
      message.success("Clinic product deleted successfully");
      const filteredProducts = products.filter(
        (product) => product.product_id !== productId
      );
      dispatch(updateClinicTreatmentProductsAction(filteredProducts));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    setShowProductModal(false);
    setEditEditTreatmentProduct(null);
  };

  const onEdit = (product) => {
    setShowProductModal(true);
    setEditEditTreatmentProduct(product);
  };

  function formatProductAmount(amount, currencyId, clinicCurrenciesList, isFree = false) {
    if (isFree) {
      return "Free";
    }
    if (amount == null) {
      return "--";
    }
    const currencyName = getCurrencyName(currencyId, clinicCurrenciesList);
    return `${currencyName} ${Number(amount)?.toFixed(2)}`;
  }
  
  function getProductTotal(product, clinicCurrenciesList) {
    const price = product.discount ? product.price - product.discount : product.price;
    return formatProductAmount(price, product.product_currency_id, clinicCurrenciesList, product.free);
  }
  
  function getProductDiscount(product, clinicCurrenciesList) {
    return formatProductAmount(product.discount, product.product_currency_id, clinicCurrenciesList,  product.free);
  }
  
  function getProductPrice(product, clinicCurrenciesList) {
    return formatProductAmount(product.price, product.product_currency_id, clinicCurrenciesList,  product.free);
  }
  
  return (
    <div className={styles["treatment-view-container"]}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Treatment & Products</h2>
        </Col>
        <Col>
          <AppButton
            type="primary"
            shape="round"
            onClick={() => {
              setShowProductModal(true);
              setEditEditTreatmentProduct(null);
            }}
          >
            + Add New
          </AppButton>
        </Col>
      </Row>
      <Alert
        className={styles["treatment-view-info"]}
        message={treatmentProductsMessage}
        type="info"
        showIcon
        icon={<BulbIcon />}
      />

      <div className={styles["treatment-products-view"]}>
        {clinicTreatmentProducts?.map((product, index) => {
          return (
            <div key={index} className={styles["treatment-product-view"]}>
              <Row align="middle" gutter={[16, 16]}>
                <Col lg={4}>
                  <label className={styles["form-info-label"]}>Type Name</label>
                </Col>
                <Col lg={9}>
                  <Row>
                    <Col lg={8}>
                      {" "}
                      <label className={styles["form-info-label"]}>Price</label>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <label className={styles["form-info-label"]}>
                        Discount
                      </label>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <label className={styles["form-info-label"]}>
                        {" "}
                        Total
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col lg={7}>
                  <label className={styles["form-info-label"]}>
                    {" "}
                    Payment Types
                  </label>
                </Col>
              </Row>
              <Row align="middle" gutter={[16, 16]}>
                <Col lg={4}>
                  <p
                    className={styles["form-info-primary"]}
                    style={{ background: primaryColor }}
                  >
                    {product.product_name}
                  </p>
                </Col>
                <Col lg={9}>
                  <Row style={{ background: "#FAFAFA", borderRadius: "4px"}}>
                    <Col lg={8}>
                      <p className={styles["form-info-value"]}>
                       {getProductPrice(product, clinicCurrenciesList)}
                    
                      </p>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <p className={styles["form-info-value"]}>
                        {getProductDiscount(product,clinicCurrenciesList)}
                      </p>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <p
                        className={styles["form-info-value"]}
                        style={{ color: primaryColor }}
                      >
                        {getProductTotal(product, clinicCurrenciesList)}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={7}>
                  <Flex gap="small">
                    {JSON.parse(product.paymentIds).map((item, i) => {
                      return (
                        <p className={styles["form-info-value"]} key={i}>
                          {getPaymentName(item, paymentList)}
                        </p>
                      );
                    })}
                  </Flex>
                </Col>
                <Col lg={4}>
                  <Flex>
                    <EditIcon
                      fill={"#77849E"}
                      style={{
                        width: "14px",
                        margin: "0px 30px",
                        cursor: "pointer",
                      }}
                      onClick={() => onEdit(product)}
                    />
                    <Popconfirm
                      title="Delete"
                      description="Are you sure you want to delete this product?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={(e) => {
                        onDelete(product.product_id);
                      }}
                      onCancel={() => {}}
                      loading={loading}
                    >
                      <DeleteOutlined
                        style={{
                          fontSize: "18px",
                          float: "right",
                          color: "#77849E",
                        }}
                      />
                    </Popconfirm>
                  </Flex>
                </Col>
              </Row>
              <Row align="middle" gutter={[16, 16]}>
                <Col lg={24}>
                  <label
                    className={styles["form-info-label"]}
                    style={{ marginTop: "20px" }}
                  >
                    Description
                  </label>
                  <p className={styles["form-info-value"]}>
                    {product.description ? product.description : "--"}
                  </p>
                </Col>
              </Row>
            </div>
          );
        })}
        {!clinicTreatmentProducts.length && (
          <Empty
            description={<Typography.Text>No Clinic Products</Typography.Text>}
          ></Empty>
        )}
      </div>

      {showProductModal && (
        <ProductModal
          showProductModal={showProductModal}
          editTreatmentProduct={editTreatmentProduct}
          setShowProductModal={setShowProductModal}
          clinicTreatmentProducts={clinicTreatmentProducts}
          paymentList={paymentList}
        />
      )}
    </div>
  );
};

export default TreatmentProductsView;
