export const urlToBase64 = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};

export const determineMimeType = (fileName, file) => {
  if (fileName.endsWith('.stl')) {
    return 'model/stl';
  }
  return file.type;
};


export const getScanFileImageType = (scanType) => {
  // Define the scan type mapping
  const scanTypeMapping = {
    upperarch: "UpperArch",
    lowerarch: "LowerArch",
    bitescan: "BiteScan",
    bitescan2: "BiteScan2",
  };

  // Check if the scanType is already one of the mapped values
  const isAlreadyMapped = Object.values(scanTypeMapping).includes(scanType);
  
  // If it is already mapped, return it as is; otherwise, map it
  return isAlreadyMapped ? scanType : scanTypeMapping[scanType] || ""; 
};

