import { useState, useEffect, useContext } from "react";
import { Layout, Menu, ConfigProvider } from "antd";

import HeaderComponent from "./headerComponent";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as Resources } from "assets/images/Resources.svg";
import "../../assets/styles/components/layoutComponent.scss";
import mixpanel from 'mixpanel-browser';
import AppSettingsContext from "Context/useAppContext";

import { useDispatch } from "react-redux";
import {
  UserOutlined,
  CalendarOutlined,
  UsergroupDeleteOutlined,
  WalletOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  companyName,
  companyType,
  defaultActiveColor,
  // logoColor,
  primaryColor,
  // secondaryColor,
  sibarHoverColor,
  sidebarColor,
} from "colors-config";
import { useSelector } from "react-redux";
import { isClinicAvailability, isClinicProducts , isClinicPayments} from "services/redux/selectors/clinicSelectors";

const { Content, Sider } = Layout;

const LayoutComponent = ({ children, user, clinic }) => {
  // const [defaultMenuKey, setDefaultMenuKey] = useState("5");
  // const isLoggedIn = useAutoLogout(3600000);
  const { clinicConfig } = useContext(AppSettingsContext);

  const isAvailability = useSelector(isClinicAvailability);
  const isProducts = useSelector(isClinicProducts);
  const isPayments = useSelector(isClinicPayments)


  const [selectedKey, setSelectedKey] = useState("");
  const [showLogoutConfirmationModel, setShowLogoutConfirmationModel] =
    useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     setShowLogoutConfirmationModel(true);
  //   }
  // }, [isLoggedIn]);

  const handleHash = (inputText) => {
      return new Promise((resolve,reject)=>{
        const secretKey = 'K8oKr6-cOgxwzx1U1cpG1-51ri5EYvLguisIVU4t'; 
        const encoder = new TextEncoder();
        const data = encoder.encode(inputText);
        const secretKeyBuffer = encoder.encode(secretKey);
    
        window.crypto.subtle.importKey(
          'raw',
          secretKeyBuffer,
          { name: 'HMAC', hash: 'SHA-256' },
          true,
          ['sign']
        ).then((key) => {
          return window.crypto.subtle.sign({ name: 'HMAC' }, key, data);
        }).then((signature) => {
          const hashedValue = Array.from(new Uint8Array(signature))
            .map((byte) => ('0' + byte.toString(16)).slice(-2))
            .join('');
            resolve(hashedValue);
        }).catch((err) => {
          console.error('Error hashing:', err);
          reject(err)
        });
    })
    
  };
  const enableIntercom=async (clinic)=>{
    const userIdentifier = clinic.clinicId;
    const hash=  await handleHash(userIdentifier);
    const intercomData={
      api_base: "https://api-iam.intercom.io",
      app_id: "j7p07692",
      name: clinic.clinicName, 
      email: clinic.clinicEmail,
      created_at: clinic.onboardedDate, 
      user_id:userIdentifier,
      PortalType:"clinic",
      CompanyType:companyName,
      user_hash: hash,
      Stage:process.env.REACT_APP_STAGE
    };
    mixpanel.identify(clinic.clinicId);
    mixpanel.people.set({
      $name: clinic.clinicName,
      $email: clinic.clinicEmail,
      clinic_id: clinic.clinicId,
      CompanyName:companyName,
      Stage:process.env.REACT_APP_STAGE
    });

    if(process.env.REACT_APP_INTERCOM==='true'){
      window.Intercom("boot", intercomData);
    }
   
  }

  useEffect(() => {
    if (clinic.clinicId) {
      enableIntercom(clinic);
    }

  }, [clinic])

  const onSelect = (val) => {
    if (+val.key === 1) {
      if(clinicConfig?.appointment){
        history.push("/clinic/scheduling/pending");
        setSelectedKey("1");
      }
      
    } else if (+val.key === 2) {
      // history.push("/clinic/patient/PATIENT94533511");
      setSelectedKey("2");

      history.push("/clinic/patients");
    } else if (+val.key === 3) {
      // history.push("/clinic/patient/PATIENT94533511");
      setSelectedKey("3");

      history.push("/clinic/lab-partners");
    } else if (+val.key === 4) {
      // history.push("/clinic/patient/PATIENT94533511");
      setSelectedKey("4");

      history.push("/clinic/invoices");
    } else if (+val.key === 5) {
      // history.push("/clinic/patient/PATIENT94533511");
      setSelectedKey("5");

      history.push("/clinic/resources");
    } else if (+val.key === 6) {
      setSelectedKey("6");

      history.push("/clinic/settings");
    }
    else if(+val.key === 7){
      window.open('https://help.smilegeniusapp.com/en/collections/8759303-clinic', '_blank');
    }
  };

  useEffect(() => {
    pickMenuKey(history.location.pathname);
  }, [history.location]);

  const pickMenuKey = (pathname) => {
    switch (pathname) {
      case "/clinic/scheduling":
        setSelectedKey("1");
        break;
      case "/clinic/profile":
        setSelectedKey("6");
        break;
      case "/clinic/lab-partners":
        setSelectedKey("3");
        break;
      case "/clinic/invoices":
        setSelectedKey("4");
        break;
      case "/clinic/resources":
        setSelectedKey("5");
        break;
      case "/onboarding":
        setSelectedKey("6");
        break;
      case "/welcome-page":
        setSelectedKey("6");
        break;
      case "/clinic/patients":
        setSelectedKey("2");
        break;
      case "/clinic/notifications":
        setSelectedKey("0");
        break;
      case "/clinic/settings":
        setSelectedKey("6");
        break;
      case "/clinic/settings/products":
        setSelectedKey("6");
        break;
      case "/clinic/settings/appointments":
        setSelectedKey("6");
        break;
      case "/clinic/settings/payments":
        setSelectedKey("6");
        break;
      case "/clinic/settings/partner":
        setSelectedKey("6");
        break;
      case "/clinic/settings/management":
        setSelectedKey("6");
        break;

      default:
        setSelectedKey("1");
    }

    if (pathname.includes("/clinic/patient/")) {
      setSelectedKey("2");
    }
  };
  return (
    <Layout>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 4,
          },
          components: {
            Button: {
              itemBg: primaryColor,
            },
          },
        }}
      >
        <HeaderComponent />
      </ConfigProvider>

      {/* {!isLoggedIn && (
        <AutoLogoutModal
          title="Auto Logout"
          showModal={showLogoutConfirmationModel}
          onConfirmation={signOut}
          setShow={setShowLogoutConfirmationModel}
        />
      )} */}
      <Layout>
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                itemBg: sidebarColor,
                itemHoverBg: sibarHoverColor,
                itemHoverColor: primaryColor,
                itemSelectedBg: defaultActiveColor,
                itemColor: "white",
              },
            },
          }}
        >
          <Sider
            width={100}
            className="sidebar-container"
            style={{
              minHeight: "100vh",
              position: "fixed",
              marginTop: "3.9rem",
              backgroundColor: sidebarColor, // dynamic theme color
            }}
          >
            <Menu
              className="custom-menu"
              mode="vertical"
              selectedKeys={[selectedKey]}
              onSelect={onSelect}
              style={{
                height: "100%",
                borderRight: 0,
                color: "white",
              }}
            >
              {clinicConfig?.appointment && <Menu.Item
                className="sidebar-menu-items"
                disabled={!clinic.isOnboarded}
                key="1"
                icon={
                  <CalendarOutlined
                    style={{ paddingTop: "15px" }}
                    className="sidebar-icon"
                  />
                }
              >
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "Mulish",
                  }}
                >
                  Appointments
                </p>
              </Menu.Item>}
              <Menu.Item
                onClick={() => history.push("/clinic/patients")}
                key="2"
                disabled={!clinic.isOnboarded}
                className="sidebar-menu-items"
              >
                <UsergroupDeleteOutlined
                  style={{
                    paddingTop: "15px",
                  }}
                  className="sidebar-icon"
                />
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "Mulish",
                  }}
                >
                  Patients
                </p>
              </Menu.Item>
              {/* <Menu.Item
                key="3"
                disabled={!clinic.isOnboarded}
                className="sidebar-menu-items"
              >
                <UserOutlined
                  style={{ paddingTop: "15px" }}
                  className="sidebar-icon"
                />
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "Mulish",
                  }}
                >
                  Partners
                </p>
              </Menu.Item> */}
              <Menu.Item
                key="4"
                disabled={!clinic.isOnboarded}
                className="sidebar-menu-items"
              >
                <WalletOutlined
                  style={{ paddingTop: "15px" }}
                  className="sidebar-icon"
                />
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "Mulish",
                  }}
                >
                  Billing
                </p>
              </Menu.Item>

              <Menu.Item
                key="5"
                disabled={!clinic.isOnboarded}
                className="sidebar-menu-items"
                
              >
                <div style={{ paddingTop: "15px" }} className="sidebar-icon">
                  <Resources style={{ fill: !clinic.isOnboarded ? companyType == 5 ? "rgb(175 102 59)" : "#0d94af" : "#fff", stroke: primaryColor }} />
                 
                </div>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "Mulish",
                  }}
                >
                  Resources
                </p>
              </Menu.Item>
              <Menu.Item
                key="6"
                // disabled={!clinic.isOnboarded}
                className="sidebar-menu-items"
              >
                <SettingOutlined
                  style={{ paddingTop: "15px" }}
                   className="sidebar-icon"
                />
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "Mulish",
                  }}
                >
                  Settings
                </p>
              </Menu.Item>
              {/* <Menu.Item
                key="7"
                className="sidebar-menu-items"
              >
                <QuestionCircleOutlined
                  style={{ paddingTop: "15px" }}
                  className="sidebar-icon"
                />
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "0px",
                  }}
                >
                  FAQs
                </p>
              </Menu.Item> */}
            </Menu>
          </Sider>
        </ConfigProvider>

        <Layout
          style={{
            padding: "0 24px 24px",
            backgroundColor: "rgba(182, 225, 239, 0.13)",
          }}
        >
          <Content
            style={{
              padding: 24,
              minHeight: "100vh",
              margin: "5rem 0 0 5rem ",
              backgroundColor: "#E9F0F4",
              overflow: "hidden",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  clinic: state.clinic,
});

export default connect(mapStateToProps)(LayoutComponent);
