import { Route, Switch } from "react-router-dom";
import Profile from "../Tabs/Profile/Profile";
import Management from "../Tabs/Management/Management";
import Partner from "../Tabs/Partner/Partner";
import TreatmentProducts from "../Tabs/TreatmentProducts/TreatmentProducts";
import Appointments from "../Tabs/Appointments/Appointments";
import Payments from "../Tabs/Payments/Payments";

const SettingRoutes = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/clinic/settings" component={Profile} />
        <Route path="/clinic/settings/partner" component={Partner} />
        <Route path="/clinic/settings/management" component={Management} />
        <Route path="/clinic/settings/products" component={TreatmentProducts} />
        <Route path="/clinic/settings/appointments" component={Appointments} />
        <Route path="/clinic/settings/payments" component={Payments} />
    
        <Route path="*">
          <div>404 Not Found</div>
        </Route>
      </Switch>
    </div>
  );
};

export default SettingRoutes;
