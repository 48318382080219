import React from 'react';
import styles from "./form.module.scss"


const FormLabel = ({ label }) => {
  return (
    <p className={styles["form-info-label"]}>
      {label}
    </p>
  );
};

export default FormLabel;
