import { returnErrors } from "./errorActions";
import axios from "axios";
import { dentistClinicApiTasks } from "../../../models/dentistClinic";
import {
  getClinicDetails,
  getClinicNotification,
  getProfileAndAvailability,
} from "api/clinic";
// import errorModal from "../../../components/shared/modals/errorModal";
import firebase from "firebase/app";

import { getFirebaseToken } from "../../../firebase";

import {
  AUTH_ERROR,
  CLINIC_LOADED,
  CLEAR_CLINIC,
  GET_JOURNEY_STATUS,
  NOTIFICATION_STATE_CHANGE,
  UPDATE_COMMENT_BOX_STATE,
  APPOINTMENT_CREATED,
  SAVE_COUNTRY_CODES,
  CLINIC_NOTIFICATION_LOADED,
  CLINIC_NOTIFICATION_READ,
  PATIENT_COUNTRY_CODES,
  CLINIC_AVAILABILITY,
  CLINICIAN_NAME_UPDATE,
  OPEN_NEW_PATIENT,
  CLINIC_ONBOARDING_PATIENT_STATUS_FINISH,
  OPEN_COMMENT_BOX_PATIENT_STATE,
  CLINIC_ONBOARDING_PATIENT_STATUS_UPDATE,
  INTERCOM_OPEN_SEND_INVITE,
  INTERCOM_SHOW_AFTER_PATIENT_SENT_ORDER_TOUR,
  CLINIC_SETTINGS,
  CLINIC_SETTINGS_LOADER,
  UPDATE_CLINIC_PROFILE_DATA,
  CLINIC_COMMON_DATA,
  UPDATE_CLINIC_MANAGEMENT_DATA,
  UPDATE_CLINIC_TREATMENT_PRODUCTS_DATA,
  UPDATE_CLINIC_APPOINTMENTS_DATA,
  UPDATE_CLINIC_PAYMENTS_DATA,
  UPDATE_CLINIC_RECEIVE_PAYMENT_PROFILE_DATA,
  LOAD_PATIENT_LIST,
  UPDATE_COMMON_DATA_PRODUCT_TYPES,
  UPDATE_COMMON_DATA_APPOINTMENT_TYPES
} from "./types";
import { message } from "antd";
import { getClinicProfileService, getPatientCountryCodesService, getClinicCommonDataService } from "api/patient";
import { getAllPaymentMethods } from "components/shared/modals/PaymentSettings/services/payment.api";
import { getCommonListAPI } from "api/common";

export const tokenExpired = () => async (dispatch) => {
  dispatch({
    type: AUTH_ERROR,
  });
};

export const fetchJourneyStatus = () => async (dispatch) => {
  try {
    let response = await dentistClinicApiTasks.getAll(
      "clinic/getClinicListOfValues",
      {
        pageName: "patientlist",
      }
    );
    await dispatch({
      type: GET_JOURNEY_STATUS,
      payload: response.body,
    });
  } catch (error) {
    console.log(error);

    message.error(error.message);
    await dispatch(returnErrors(error, error.code));
  }
};

// Check token & load clinic
export const loadClinic = () => async (dispatch, getState) => {
  try {
    const res = await getClinicDetails(getState().auth.user.attributes.email);
    if (firebase.messaging.isSupported()) {
      getFirebaseToken()
        .then(async (token) => {
          try {
            await dispatch(settingLoaderAction(true));
            await dentistClinicApiTasks.post("deviceToken/add", {
              user_id: res?.data?.body?.clinic_id,
              device_token: token,
            });
            localStorage.setItem("device_token", token);
            await dispatch(loadClinicNotificationCount());
            await dispatch(loadClinicAvailability());
            await dispatch(getClinicProfile());
            await dispatch(getClinicCommonData())
            await dispatch(getClinicPaymentsData())
            await dispatch(settingLoaderAction(false))
            //saveDeviceToken(token)
          } catch (error) {
            console.log(error);
          }
        })
        .catch((err) => console.log("failed: ", err));
    }
    await dispatch({
      type: CLINIC_LOADED,
      payload: res?.data?.body,
    });
  } catch (error) {
    console.log('loadclinic', error);

    message.error(error?.response?.data?.error);
    await dispatch(returnErrors(error, error?.response?.status));
  }
};

export const loadClinicDetails = (payload) => async (dispatch, getState) => {
  dispatch({
    type: CLINIC_LOADED,
    payload: payload
})
};

export const clearClinic = () => async (dispatch, getState) => {
  await dispatch({
    type: CLEAR_CLINIC,
    payload: null,
  });
};

export const changeNotificationState =
  (isActive) => async (dispatch, getState) => {
    await dispatch({
      type: NOTIFICATION_STATE_CHANGE,
      payload: { isActive },
    });
  };

export const changeCommentBoxState = (payload) => async (dispatch) => {
  await dispatch({
    type: UPDATE_COMMENT_BOX_STATE,
    payload,
  });
};

export const updateCommentPatientTabState = (payload) => async (dispatch) => {
  await dispatch({
    type: OPEN_COMMENT_BOX_PATIENT_STATE,
    payload,
  });
};

export const newAppointmentCreated = (payload) => {
  return {
    type: APPOINTMENT_CREATED,
    payload,
  };
};

export const saveCountryAndCodes = (payload) => {
  return {
    type: SAVE_COUNTRY_CODES,
    payload,
  };
};

export const loadClinicNotificationCount = () => async (dispatch, getState) => {
  try {
    const clinicId = getState().clinic.clinicId;
    if (clinicId) {
      const getNotifications = await getClinicNotification({
        user_id: clinicId,
        limit: 1,
        page: 1,
        key: "ALL",
        secondaryFilter: "",
      });

      await dispatch({
        type: CLINIC_NOTIFICATION_LOADED,
        payload: getNotifications?.body?.unreadDataCount
          ? getNotifications?.body?.unreadDataCount
          : 0,
      });
    }
  } catch (error) {
    console.log(error);

    message.error(error?.response?.data?.error);
    await dispatch(returnErrors(error, error?.response?.status));
  }
};
export const loadClinicAvailability = () => async (dispatch, getState) => {
  try {
    const clinicId = getState().clinic.clinicId;
    if (clinicId) {
      const response = await getProfileAndAvailability({ clinicId: clinicId });
      await dispatch({
        type: CLINIC_AVAILABILITY,
        payload: response.body,
      });
    }
  } catch (error) {
    console.log(error);

    message.error(error?.response?.data?.error);
    await dispatch(returnErrors(error, error?.response?.status));
  }
};

export const getAllCountriesAndCodes = () => async (dispatch, getState) => {
  try {
    const { data } = await getPatientCountryCodesService();
    await dispatch({
      type: PATIENT_COUNTRY_CODES,
      payload: data?.data?.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateReadCount = (count) => async (dispatch, getState) => {
  let notificationCount =
    count == 0 ? 0 : getState().clinic.notificationCount - 1;
  if (notificationCount < 1) {
    notificationCount = 0;
  }
  await dispatch({
    type: CLINIC_NOTIFICATION_READ,
    payload: notificationCount,
  });
};

export const addReadCount = () => async (dispatch, getState) => {
  let notificationCount = getState().clinic.notificationCount + 1;
  await dispatch({
    type: CLINIC_NOTIFICATION_READ,
    payload: notificationCount,
  });
};

export const updateClinicianName = (clinicianName) => async (dispatch) => {
  await dispatch({
    type: CLINICIAN_NAME_UPDATE,
    payload: clinicianName,
  });
};




export const openNewPatientDailog = (value) => async (dispatch) => {
  await dispatch({
    type: OPEN_NEW_PATIENT,
    payload: value,
  });
};

export const updateClinicFirstTimeStatusFinish = (value) => async (dispatch) => {
  await dispatch({
    type: CLINIC_ONBOARDING_PATIENT_STATUS_FINISH,
  });
};

export const updateClinicFirstTimeStatusUpdate = (value) => async (dispatch) => {
  await dispatch({
    type: CLINIC_ONBOARDING_PATIENT_STATUS_UPDATE,
    payload: value
  });
  if (value === "INVITE_APP") {
    await dispatch({
      type: INTERCOM_OPEN_SEND_INVITE,
      payload: true
    });
  }
};

export const showOrderTourAfterSendToPatient = (value) => async (dispatch) => {
  await dispatch({
    type: INTERCOM_SHOW_AFTER_PATIENT_SENT_ORDER_TOUR,
    payload: value
  });
};



export const showIntercomInviteApp = (value) => (dispatch) => {
  dispatch({
    type: INTERCOM_OPEN_SEND_INVITE,
    payload: value
  });
};

export const getClinicProfile = () => async (dispatch, getState) => {
  const clinicId = getState().clinic.clinicId;
  try {
    if (clinicId) {
      dispatch(settingLoaderAction(true))
      const { data } = await getClinicProfileService(clinicId);
      dispatch({
        type: CLINIC_SETTINGS,
        payload: data.data,
      });
    }

  } catch (error) {
    console.error(error);
  }
  finally {
    dispatch(settingLoaderAction(false))
  }
};


const settingLoaderAction = (loader = true) => {
  return {
    type: CLINIC_SETTINGS_LOADER,
    payload: loader
  }
}

export const updateClinicProfileAction = (data) => {
  return {
    type: UPDATE_CLINIC_PROFILE_DATA,
    payload: data
  }
}


export const updateClinicManagementAction = (data) => {
  return {
    type: UPDATE_CLINIC_MANAGEMENT_DATA,
    payload: data
  }
}


export const getClinicCommonData = () => async (dispatch, getState) => {
  const clinicId = getState().clinic.clinicId;
  try {
    if (clinicId) {
      const { data } = await getClinicCommonDataService(clinicId);
      dispatch({
        type: CLINIC_COMMON_DATA,
        payload: data.data,
      });
    }

  } catch (error) {
    console.error(error);
  }
  finally {
  }
};


export const updateClinicTreatmentProductsAction = (data) => {
  return {
    type: UPDATE_CLINIC_TREATMENT_PRODUCTS_DATA,
    payload: data,
  };
};

export const updateClinicAppointmentsAction = (data) => {
  return {
    type: UPDATE_CLINIC_APPOINTMENTS_DATA,
    payload: data,
  };
};

export const updateClinicPaymentsAction = (data) => {
  return {
    type: UPDATE_CLINIC_PAYMENTS_DATA,
    payload: data,
  };
};

export const getClinicPaymentsData = () => async (dispatch, getState) => {
  const clinicId = getState().clinic.clinicId;
  try {
    if (clinicId) {
      const { data } = await getAllPaymentMethods(clinicId);
      const filteredPayments = data?.data?.paymentTypes;
      dispatch({
        type: UPDATE_CLINIC_PAYMENTS_DATA,
        payload: filteredPayments,
      });
    }

  } catch (error) {
    console.error(error);
  }
  finally {
  }
};


export const loadPatientList = (value) => async (dispatch) => {
  await dispatch({
    type: LOAD_PATIENT_LIST,
    payload: value,
  });
};
export const updateClinicReceivePaymentProfileAction = (value) => {
  return {
    type: UPDATE_CLINIC_RECEIVE_PAYMENT_PROFILE_DATA,
    payload: value
  }
}

export const updateClinicCommonProductTypeAction = (value) => async (dispatch) => {
  await dispatch({
    type: UPDATE_COMMON_DATA_PRODUCT_TYPES,
    payload: value,
  });
}

export const updateClinicCommonAppointmentTypeAction = (value) => async (dispatch) => {
  await dispatch({
    type: UPDATE_COMMON_DATA_APPOINTMENT_TYPES,
    payload: value,
  });
}