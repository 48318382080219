import { Row, Col, Alert, Switch, Divider, Empty, Typography } from "antd";
import styles from "./style.module.scss";
import AppButton from "components/shared/AppButton/AppButton";
import { getAppointmentSlotName, getCurrencyName } from "utilities/settings";
import { useSelector } from "react-redux";
import { getClinicCurrencySelector } from "services/redux/selectors/clinicSelectors";
import { ReactComponent as Clock } from "assets/images/Clock.svg";
import { primaryColor, tagBackgroundColor } from "colors-config";

const AvailabilityView = ({
  clinicAppointments,
  clinicDurationList,
  setEditAvailability,
  setMode,
}) => {
  // console.log("clinicAppointments", clinicAppointments);
  const clinicCurrenciesList = useSelector(getClinicCurrencySelector) || [];
  return (
    <div className={styles["appointment-view-container"]}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Availability View</h2>
        </Col>
        <Col>
          <h2 className={styles["heading"]}>
            <AppButton
              type="primary"
              shape="round"
              style={{ width: "120px" }}
              onClick={() => {
                setMode("edit");
                setEditAvailability(true);
              }}
            >
              Edit
            </AppButton>
          </h2>
        </Col>
      </Row>
      <Row
        align="middle"
        justify="space-between"
        style={{ margin: "30px 0px" }}
      >
        <Col lg={8}>
          <label>Time Zone</label>
          <p>{clinicAppointments.timezone}</p>
        </Col>
        <Col md={6} lg={10}>
          <div className={styles["book-appointment"]} style={{ backgroundColor: tagBackgroundColor}}>
            <label>Book Appointments through this portal?</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Switch checked={clinicAppointments.bookAppointmentApp} disabled/>

              <span style={{ marginLeft: "10px" }}>Yes</span>
            </div>
          </div>
        </Col>
      </Row>
      <Divider />
      <div className={styles["appointments-hours-view"]}>
        <Row align="middle" gutter={[16, 16]}>
          <Col lg={6}>
            <label className={styles["form-info-heading"]}>Availability</label>
          </Col>
          <Col lg={6}>
            <div style={{display:"flex", gap:"7px"}}>
            <label className={styles["form-info-heading"]}>
              Working Hours 
            </label> <Clock fill={primaryColor} />
            </div>
      
          </Col>
          <Col lg={4}>
            <label className={styles["form-info-heading"]}>Break Hours</label>
          </Col>
        </Row>

        {clinicAppointments?.availabilityList?.map((day, index) => {
          return (
            <div key={index}>
              {day.workDay ? (
                <Row align="middle" gutter={[16, 16]} key={index}>
                  <Col lg={6}>
                    <p className={styles["form-info-label"]}>{day.name}</p>
                  </Col>

                  <Col lg={6}>
                    {day.workDay ? (
                      <>
                        {day?.workHours?.map((time, i) => (
                          <p className={styles["form-info-value"]} key={i}>
                            {time[0]} - {time[1]}
                          </p>
                        ))}
                      </>
                    ) : (
                      "--"
                    )}
                  </Col>

                  <Col lg={4}>
                    {day.workDay ? (
                      <>
                        <p className={styles["form-info-value"]}>
                          {day.breakHours.length
                            ? `${day.breakHours[0]} - ${day.breakHours[1]}`
                            : `No day breaks`}
                        </p>
                      </>
                    ) : (
                      "--"
                    )}
                  </Col>
                </Row>
              ) : null}
            </div>
          );
        })}
      </div>
      <Divider />
      <div className={styles["appointments-products-view"]}>
        <label className={styles["form-info-heading"]}>Days Off</label>
        <Row align="middle" gutter={[16, 16]}>
          {clinicAppointments?.daysOffList?.map((date, index) => {
            return (
              <Col lg={4}>
                <div key={index} className={styles["appointment-product-view"]}>
                  <p className={styles["form-info-value"]}>{date.holiday}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      <Divider />

      <div className={styles["appointments-products-view"]}>
        <Row align="middle" gutter={[16, 16]}>
          <Col lg={8}>
            <label className={styles["form-info-heading"]}>
              Appointment Type
            </label>
          </Col>
          <Col lg={4}>
            <label className={styles["form-info-heading"]}>Duration</label>
          </Col>

          <Col lg={6}>
            <label className={styles["form-info-heading"]}>Price</label>
          </Col>
        </Row>

        {clinicAppointments?.appointmentsList?.map((appointment, index) => {
          return (
            <div key={index} className={styles["appointment-product-view"]}>
              <Row align="middle" gutter={[16, 16]}>
                <Col lg={8}>
                  <p className={styles["form-info-value"]}>
                    {appointment.appointment_type}-
                    {appointment.appointment_name}
                  </p>
                </Col>
                <Col lg={4}>
                  <p className={styles["form-info-value"]}>
                    {" "}
                    {appointment.duration}
                    {getAppointmentSlotName(
                      appointment.slot_duration_id,
                      clinicDurationList
                    )}
                  </p>
                </Col>
                <Col lg={4}>
                  <p className={styles["form-info-value"]}>
                    {
                      appointment.free ? "Free" : `${getCurrencyName(
                        appointment.slot_currency_id,
                        clinicCurrenciesList
                      ) } ${appointment.price}`
                    }
                  </p>
                </Col>
              </Row>
            </div>
          );
        })}
        {!clinicAppointments?.appointmentsList?.length && (
          <Empty
            description={
              <Typography.Text>No Clinic Appointments</Typography.Text>
            }
          ></Empty>
        )}
      </div>
    </div>
  );
};

export default AvailabilityView;
