import { Tooltip } from "antd";
import { appCurrencyId, companyType, primaryColor } from "colors-config";
import { InfoCircleOutlined } from "@ant-design/icons";
import { weeklyAvailabilityValuesMapperTwo } from "./patientDetail";
import dayjs from "dayjs";
import { weeklyAvailabilityInitialMapper } from "utilities/patientDetail";

export const accountSettingFormValuesNormalizer = (formValue, addressIds) => {
  const formObject = { ...formValue };
  let multipleAddress = [];
  let addressDefault = null;
  addressIds.forEach(({ addressId }) => {
    const obj = { id: addressId };
    for (const key in formObject) {
      if (key.includes("" + addressId)) {
        const splittedValue = key.split("-");

        obj[splittedValue[0]] = formValue[key];

        delete formObject[key];
      }
    }
    if (obj.default) {
      addressDefault = {
        ...obj,
      };
    }

    multipleAddress.push(obj);
  });

  if (!addressDefault) {
    multipleAddress = multipleAddress.map((el, index) => {
      if (index === 0) {
        return {
          ...el,
          default: true,
        };
      }
      return el;
    });

    addressDefault = multipleAddress[0];
  }

  return {
    ...formObject,
    multipleAddresses: multipleAddress,
    ...addressDefault,
    phone: formValue.phone.number,
    prefix: formValue.phone.prefix,
  };
};

export const SupportToolTip = ({ name, title, label }) => {
  return (
    <div className="support-tag">
      <span style={{ color: "#798c96" }}> {label ? label : ""}</span>
      <Tooltip title={title} arrowPointAtCenter arrow={{ pointAtCenter: true }}>
        <InfoCircleOutlined
          style={{ color: primaryColor, marginLeft: "10px" }}
        />
      </Tooltip>
    </div>
  );
};

export const getOrderBtnBackground = () => {
  let color = primaryColor;
  if (companyType === 7) {
    color = "#E92A2A";
  }
  return color;
};

export const getClinicFlag = (countryCode, countryCodes) => {
  if (countryCodes) {
    const matchCode = countryCodes.find(
      code => code.countryCallingCodes?.trim() === countryCode?.trim()
    );
    if (matchCode) {
      return <span className="emoji-font">{matchCode.emoji}</span>;
    }
  }
  return "--";
};

export const getAppointmentSlotName = (slotdId, appointmentSlots) => {
  if (appointmentSlots) {
    const matchObj = appointmentSlots.find(
      obj => obj.clinic_meeting_duration_id === slotdId
    );
    if (matchObj) {
      return matchObj.clinic_meeting_duration;
    }
  }
  return "--";
};

export const getPaymentName = (paymentId, paymentList) => {
  if (paymentList) {
    const matchObj = paymentList.find(obj => obj.id === paymentId);
    if (matchObj) {
      return matchObj.buttonText;
    }
  }
  return "--";
};

export const getCurrencyName = (currencyId, currencyList) => {
  // console.log("currencyId", currencyId, currencyList)
  if (currencyList) {
    const matchObj = currencyList?.find(obj => obj.currency_id === currencyId);
    if (matchObj) {
      return matchObj.currency_name;
    }
  }
  return "--";
};

export const mapClinicProfileData = clinicProfile => {
  return {
    clinic_name: clinicProfile?.clinic_name,
    clinic_email: clinicProfile?.clinic_email,
    supportEmail: clinicProfile?.supportEmail,
    supportSameEmail: clinicProfile?.supportSameEmail,
    supportSamePhone: clinicProfile?.supportSamePhone,
    clinic_contact: {
      countryCode: clinicProfile?.country_code_name,
      phoneNumber: clinicProfile?.clinic_contact,
    },
    support_contact: {
      countryCode: clinicProfile?.supportPrefix,
      phoneNumber: clinicProfile?.supportNumber,
    },
  };
};

export const mapClinicManagementData = clinicManagement => {
  // console.log("clinicManagementclinicManagementclinicManagement", clinicManagement)
  const data = clinicManagement?.map(obj => {
    return {
      ...obj,
      id: obj.id,
    };
  });
  return data;
};
export const mapClinicTreatmentProductsData = clinicTreatmentProducts => {
  const data = clinicTreatmentProducts?.map(obj => {
    return {
      ...obj,
      id: obj.id,
    };
  });
  return data;
};

export const mapClinicAppointmentsData = appointmentData => {
  // console.log("appointmentData?.availabilityList", weeklyAvailabilityInitialMapper())
  return {
    ...appointmentData,
    daysOffList: appointmentData?.daysOffList.map(date => dayjs(date.holiday)),
    weekTimings: appointmentData?.availabilityList
      ? weeklyAvailabilityValuesMapperTwo({
          workingHours: appointmentData?.availabilityList,
        })
      : weeklyAvailabilityInitialMapper(),
  };
};

export const mapTreatmentProduct = (product) => {
  const price = Number(product.price) || 0;
  const discount = Number(product.discount) || 0;
  const total = (price - discount).toFixed(2);
  const discountPercentage = price ? ((discount / price) * 100).toFixed(2) : "0.00";

  return {
    paymentIds: JSON.parse(product.paymentIds || []), // Parse paymentIds safely
    productId: product?.product_id,
    productDescription: product.description,
    productName: product.product_name,
    productPrice: price.toFixed(2), // Ensure price is formatted to 2 decimal places
    total,
    productCurrencyId: String(product.product_currency_id),
    discount: discount.toFixed(2), // Ensure discount is formatted to 2 decimal places
    productType: product.product_type || product.productType,
    free: product.free || false,
    discountPercentage,
  };
};


export const mapTreatmentInitialProduct = activePayments => {
  const activeIds = activePayments?.map((obj) => obj.id)
  return {
    productCurrencyId: String(appCurrencyId),
    paymentIds: activeIds ? activeIds : [],
  };
};

export const updateProductList = (productList, productId, values) => {
  return productList.map(product => {
    if (product.product_id === productId) {
      return {
        ...product,
        product_type: values.productType,
        price: values.productPrice,
        product_currency_id: Number(values.productCurrencyId),
        discount: values.discount,
        description: values.productDescription,
        free: values.free,
        paymentIds: Array.isArray(values.paymentIds) 
        ? JSON.stringify(values.paymentIds) 
        : JSON.stringify(JSON.parse(values.paymentIds)),
        product_name: values.productName
      };
    }
    return product;
  });
};

export const mapAppointmentData = appointment => {
  return {
    appointment: appointment.appointment_name,
    appointmentType: appointment.appointment_type_id,
    currency: String(appointment.slot_currency_id),
    description: appointment.description,
    duration: String(appointment.slot_duration_id),
    free: appointment.free,
    price: appointment.price,
    paymentIds: JSON.parse(appointment.paymentIds),
    appointment_id: appointment.appointment_id,
    appointmentTypeId: appointment.appointment_type_id,
    // appointmentType: appointment.appointment_type,
    // appointmentName:appointment.appointment_name,
    // appointmentDuration:appointment.slot_duration,
    // appointmentDescription:appointment.description,
    // appointmentPrice:appointment.price,

    // appointmentNameId:appointment.appointment_name_id,
    // appointmentDurationId:appointment.slot_duration_id,
    // appointmentCurrencyId:appointment.slot_currency_id,
    // paymentIds: JSON.parse(appointment.paymentIds),
    // free: appointment.free,
  };
};

export const mapPaymentData = payments => {
  // Filter out payments that have type 'IN_CLINIC'
  const data = payments.filter(obj => obj.type !== "IN_CLINIC");
  // Check if there's already a STRIPE payment in the list
  const hasStripe = data.some(payment => payment.type === "STRIPE");
  if (!hasStripe) {
    data.unshift({
      type: "STRIPE",
      buttonText: "Pay by Card",
      default: 1,
      id: Date.now(),
      isActive: 1,
      isEnabled: false,
      updatedAt: new Date().toISOString(),
      url: "https://example.com",
    });
  }
  // if (data.length === 1) {
  //   data.push({
  //     buttonText: '',
  //     default: 0,
  //     edit: false,
  //     isActive: 1,
  //     isEnabled: true,
  //     url: '',
  //   });
  // }

  // Ensure all payments have edit: true
  return data.map(payment => ({
    ...payment,
  }));
};

export const mapPreviousAppointmentData = appointment => {
  return {
    appointmentType: appointment.appointment_type,
    appointmentName: appointment.appointment_name,
    appointmentDuration: appointment.slot_duration,
    appointmentDescription: appointment.description,
    appointmentPrice: appointment.price,
    appointmentTypeId: appointment.appointment_type_id,
    appointmentNameId: appointment.appointment_name_id,
    appointmentDurationId: appointment.slot_duration_id,
    appointmentCurrencyId: appointment.slot_currency_id,
    paymentIds: JSON.parse(appointment.paymentIds),
    free: appointment.free,
  };
};

export const mapAppointmentInitialData = activePayments => {
  const activeIds = activePayments?.map((obj) => obj.id)
  return {
    currency: String(appCurrencyId),
    paymentIds: activeIds ? activeIds : [],
  };
};

export const validatePayments = values => {
  const { clinicPayments } = values;

  // Check for duplicate URLs
  const urlCount = clinicPayments.reduce((acc, payment) => {
    if (payment.url) {
      acc[payment.url] = (acc[payment.url] || 0) + 1;
    }
    return acc;
  }, {});

  const duplicateUrls = Object.keys(urlCount).filter(url => urlCount[url] > 1);

  if (duplicateUrls.length > 0) {
    return false; // Prevent submission if duplicates are found
  }

  return true; // Proceed with form submission
};

export const getCardPaymentData = payments => {
  const hasStripe = payments.find(payment => payment.type === "STRIPE");
  if (hasStripe) {
    return hasStripe.isEnabled;
  }
  return false;
};
