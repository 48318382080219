
import api from "./api";

export const getCommonListAPI = () => {
  return api({
    method: "GET",
    url: "/common/list",
  });
};

export const getFileUrlAPI = (data) => {
  return api({
    method: "POST",
    url: "/scan/getFileUrl",
    data
  }).then(res=>res.data)
};
