import {useHistory} from "react-router-dom"
import { Row, Col, Alert } from "antd";
import styles from "./offProducts.module.scss";
import { ReactComponent as ProductBox } from "assets/images/ProductBox.svg";
import { ReactComponent as BulbIcon } from "assets/images/BulbIcon.svg";
import {offProductsMessage } from "messages/messages";
import AppButton from "components/shared/AppButton/AppButton";
import { ReactComponent as ProductPhone } from "assets/images/ProductPhone.svg";


const OffAppointment = ({setEditProducts, setShowProductModal}) => {
  const history = useHistory()
  return (
    <div className={styles["off-view-container"]}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Treatment & Products</h2>
        </Col>
      </Row>

      <Alert
        className={styles["treatment-view-info"]}
        message={offProductsMessage}
        type="info"
        showIcon
        icon={<BulbIcon />}
      />

      <div className={styles["empty-container"]}>
        <div style={{marginTop:"20px"}}>
        <ProductBox />
        </div>

        <AppButton 
        type="primary" shape="round" size="large" onClick={() => {
          setEditProducts(true)
          setShowProductModal(true)
        }} style={{width:"270px", marginTop:"30px"}}>
        + Add New
        </AppButton>
        <AppButton shape="round" size="large" onClick={() => {
          history.push("/clinic/patients")
        }} style={{width:"270px"}}>
        Set up later
        </AppButton>
      </div>

      <div className={styles["form-user-phone"]}>
        <ProductPhone />
      </div>
    </div>
  );
};

export default OffAppointment;
