import { useState, useRef } from "react";
import { Row, Col, Divider, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as OtherPaymentIcon } from "assets/images/OTHER_PAYMENT.svg";
import { ReactComponent as StripeIcon } from "assets/images/STRIPE.svg";
import { ReactComponent as InClinicIcon } from "assets/images/IN_CLINIC.svg";
import styles from "../../paymentSettings.module.scss";
import EditButton from "components/shared/EditButton/EditButton";
import StripeButton from "components/shared/StripeButton/StripeButton";
import ConfirmationModal from "components/shared/ConfirmationModal/ConfirmationModal";
import SwitchButton from "../SwitchButton/SwitchButton";
import {
  changeActiveStatus,
  changeDefaultStatus,
} from "../../services/payment.api";
import { buttonType, PaymentTypes } from "../../constants";
import { primaryColor } from "colors-config";

const paymentIcons = {
  STRIPE: StripeIcon,
  IN_CLINIC: InClinicIcon,
  INSURANCE: InClinicIcon,
  OTHERS: OtherPaymentIcon,
};

const DefaultPaymentMethod = ({
  payment,
  updateList,
  onEditClick,
  onDeleteClick,
}) => {
  const {
    type,
    clinicId,
    default: defaulBtnValue,
    isActive,
    buttonText,
    id,
    url,
  } = payment;

  const [statusLoading, setStatusLoading] = useState(false);
  const [defaultLoading, setDefaultLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const statusDataRef = useRef(null);

  const statusChangeHandler = async (checked, type) => {
    const payload = {
      paymentId: id,
    };

    let apiFN = null;

    if (type === buttonType.ACTIVE) {
      setStatusLoading(true);
      payload.status = checked;

      apiFN = changeActiveStatus;
    } else {
      setDefaultLoading(true);
      payload.status = checked;
      apiFN = changeDefaultStatus;
    }
    try {
      await apiFN(clinicId, payload);

      updateList({
        paymentId: id,
        checked: checked ? 1 : 0,
        activeBtn: type === buttonType.ACTIVE,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setDefaultLoading(false);
      setStatusLoading(false);
    }
  };

  const onSwitchChange = async (checked, type) => {
    if (!checked) {
      statusDataRef.current = {
        checked,
        type,
      };

      return setShowConfirmationModal(true);
    }
    statusChangeHandler(checked, type);
  };

  const PaymentNameIcon = paymentIcons[payment.type];

  const handleOk = () => {
    if (statusDataRef.current) {
      const data = JSON.parse(JSON.stringify(statusDataRef.current));
      statusChangeHandler(data.checked, data.type);
    }

    handleCancel();
  };

  const handleCancel = () => {
    statusDataRef.current = null;
    setShowConfirmationModal(false);
  };
  return (
    <>
      {showConfirmationModal && (
        <ConfirmationModal
          handleCancel={handleCancel}
          handleOk={handleOk}
          title="Warning"
          open={showConfirmationModal}
        >
          It will remove this payment from the services
        </ConfirmationModal>
      )}
      <Row>
        <Col md={5}>
          <div className={styles["heading-text"]}>Payment Type:</div>
          {type === PaymentTypes.OTHERS && (
            <div className={styles["heading-text"]}>Payment Url:</div>
          )}
          <div className={styles["heading-text"]}>Status:</div>
        </Col>
        <Col md={19}>
          <div className={styles["payment-row"]}>
            <div>
              <h4 className={styles["payment-name"]}>
                {buttonText}
                <PaymentNameIcon
                  style={{ marginBottom: "3px", marginLeft: "5px", fill:primaryColor }}
                />
              </h4>
              {type === PaymentTypes.OTHERS && (
                <div className={styles["url-text"]}>
                  {payment ? payment?.url : ""}
                </div>
              )}
              <div>
                <div className={styles["active-col"]}>
                  <SwitchButton
                    value={isActive}
                    loading={statusLoading}
                    onSwitchChange={onSwitchChange}
                    name="Active"
                    styles={styles}
                    type={buttonType.ACTIVE}
                    disabled={
                      type === PaymentTypes.STRIPE && !payment?.isEnabled
                    }
                  />
                </div>
              </div>
              <div className={styles["default-btn"]}>
                <SwitchButton
                  value={defaulBtnValue}
                  loading={defaultLoading}
                  onSwitchChange={onSwitchChange}
                  name="Default For All Products & Services"
                  styles={styles}
                  type={buttonType.DEFAULT}
                  disabled={type === PaymentTypes.STRIPE && !payment?.isEnabled}
                />
              </div>
            </div>
            <div className={styles["col-two"]}>
              {type === PaymentTypes.STRIPE && (
                <StripeButton
                  stripeConnected={
                    payment?.isEnabled ? payment?.isEnabled : false
                  }
                  clinicId={clinicId}
                />
              )}
            </div>
            <div className={styles["edit-button"]}>
              {type === PaymentTypes.OTHERS && (
                <EditButton onEditClick={() => onEditClick(payment)} />
              )}
            </div>
            <div className={styles["edit-button"]}>
              {type === PaymentTypes.OTHERS && (
                <Popconfirm
                  title="Delete Payment Method"
                  description=" Are you sure you want to delete this payment method?"
                  onConfirm={() => onDeleteClick(id)}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined className={styles["delete-icon"]} />
                </Popconfirm>
              )}
            </div>
          </div>
          <Divider />
        </Col>
      </Row>
    </>
  );
};

export default DefaultPaymentMethod;
