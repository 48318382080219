import React, { useState, useEffect } from "react";
import { Form, Select, Row, Col, Typography, Input, Spin, message } from "antd";
import { primaryColor } from '../../colors-config';
import "../../assets/styles/components/onboarding/Profile.scss";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AppButton from "components/shared/AppButton/AppButton";
import { getPatientCountryCodesList, getClinicProfileSelector } from "services/redux/selectors/clinicSelectors";
import { completeProfileStepOne } from "api/patient";
import { CLINIC_LOADED, COMPLETE_ONBOARDING_STEP_ONE } from "services/redux/actions/types";
import mixpanel from 'mixpanel-browser';
const { Title } = Typography;

const ClinicianDetailsForm = () => {
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const clinic = useSelector(getClinicProfileSelector);
  const countries = useSelector(getPatientCountryCodesList);
  const [countryCodePrefix, setCountryCodePrefix] = useState(clinic?.country_code_name);
  const dispatch = useDispatch();
  const step="Profile"

  const [form] = Form.useForm();

  const history = useHistory();


  useEffect(() => {
    if (clinic) {
      form.setFieldsValue({ countryCodePrefix: clinic?.country_code_name, clinicName: clinic?.clinic_name, phone: clinic?.clinic_contact })
      setCountryCodePrefix(clinic?.country_code_name);
      setPageLoading(false);
      mixpanel.track('Step 1 Started', { step: "Profile", clinic_id:clinic?.clinic_id});
    }

  }, [clinic])


  const onFinish = async (values) => {
    try {
      setLoading(true)
      const clinicId = clinic?.clinic_id;
      const payload = {
        ...values,
        countryCode: countryCodePrefix
      }
      const { data } = await completeProfileStepOne(payload, clinicId);
      mixpanel.people.set({
        $name:values.clinicName,
        PhoneNo: countryCodePrefix + " " + values.phone
      });
      mixpanel.track('Step Transition', { step: "Help & Support", clinic_id: clinic?.clinic_id });
      dispatch({
        type: CLINIC_LOADED,
        payload: data.data
      })
      dispatch({
        type: COMPLETE_ONBOARDING_STEP_ONE,
        payload: data.data
      })
      message.success('Clinician details updated.')
      history.push("/onboarding/help-support");

    } catch (e) {
      console.log('error', e)
      message.error('Error updating clinician details.')
    } finally {
      setLoading(false)
    }

  };

  const onConfirm = () => {
    history.push("/onboarding/help-support");
  };

  const prefixSelector = (
    <Select size="large"
      showSearch
      value={countryCodePrefix}
      onChange={(value) => {
        setCountryCodePrefix(value);
        mixpanel.track('Field Updated', {
          step: step,
          field: 'countryCode',
          value: value,
          clinic_id:clinic?.clinic_id
        });
      }}
      dropdownClassName="country-dropdown"
      className="country-select"
      dropdownStyle={{ width: "300px" }}
      style={{ width: 110 }}
      labelRender={(props) => {
        return props.label;
      }}
      options={countries?.map((code, index) => {
        return {
          ...code,
          key: index,
          value: code.countryCallingCodes,
          label : <><span className="emoji-font">{code.emoji}</span> {code.countryCallingCodes}</>
        };
      })}
      optionRender={(option) => {
        const { data } = option;
        return (
          <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}>
            <span className="emoji-font">{data.emoji}</span>
            <span>{data.countryCallingCodes}</span>
            <span>| {data.name}</span>
          </div>
        );
      }}
      filterOption={(input, option) => {
        const lowerCasedInput = input.toLowerCase();
        return (
          option.value.toLowerCase().includes(lowerCasedInput) ||
          option.name.toLowerCase().includes(lowerCasedInput)
        );
      }}
    />

  );
console.log("clinic",clinic);

  return (
    <div className="clinician-details-container">
      <Spin spinning={pageLoading}>
        <Row justify="start" gutter={[16, 16]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 20, offset: 1 }}
            md={{ span: 16, offset: 2 }}
            lg={{ span: 12, offset: 4 }}
            xl={{ span: 8, offset: 3 }}
          >
            <Title level={2} className="clinician-details-title">
              Confirm <span style={{ color: primaryColor }} className="highlight-text">Clinician Details</span>
            </Title>

            <Form
              name="clinician_details"
              layout="vertical"
              onFinish={onFinish}
              className="clinician-details-form"
              form={form}
            >
              {/* Clinician Name Input */}
              <Form.Item
                name="clinicName"
                className="clinician-name-form-item"
                rules={[
                  { required: true, message: "Clinician name is required" },
                  {
                    max: 100,
                    message: "Clinic name cannot be more than 100 characters",
                    whitespace: true,
                  },
                ]}
              >
                <Input size={"large"} placeholder="Clinician Name"
                onChange={(e) => {
                 
                  // mixpanel.track('Field Updated', {
                  //   step: step,
                  //   field: 'clinicianName',
                  //   value: e.target.value,
                  //   clinic_id:clinic?.clinic_id
                  // });
                }}
                />
              </Form.Item>

              {/* Phone Number Input */}
              <Form.Item
                name="phone"
                rules={[
                  () => ({
                    validator(_, value) {
                      const convertedValue = Number(value);
                      if (!value) {
                        return Promise.resolve();
                      } else if (
                        !isNaN(convertedValue) &&
                        (value.length === 9 || value.length === 10 || value.length === 8)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Invalid phone number");
                    },
                  }),
                ]}
              >
                <Input
                  size="large"
                  addonBefore={prefixSelector}
                  autoComplete="off"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Phone Number"
                  onChange={(e) => {
                    mixpanel.track('Field Updated', {
                      step: step,
                      field: 'phoneNumber',
                      value: e.target.value,
                      clinic_id:clinic?.clinic_id
                    });
                  }}
                />
              </Form.Item>

              {/* Submit Button */}
              <Form.Item className="submit-button-container">
                <AppButton shape="round"
                  type="primary"
                  className="guideBtn"
                  style={{ padding: '0 40px', height: '35px' }}
                  loading={loading}
                  htmlType="submit" >
                  Confirm
                </AppButton>
              </Form.Item>

            </Form>
          </Col>
        </Row>
      </Spin>
      <div style={{ height: '130px'}}></div>
    </div>
  );
};

export default ClinicianDetailsForm;
