
import { useEffect, useState } from "react"
import { Space, message, Upload } from "antd";
import { ReactComponent as Pdf } from "assets/images/Pdf.svg";
import { DownloadOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { deleteConsentFormApi, getClinicDetails, uploadMediaApi } from "api/clinic";
import { useDispatch, useSelector } from "react-redux";
import { loadClinicDetails } from "services/redux/actions/clinicActions";
import ConsentScanFileIcon from "components/shared/Icon/ConsentScanFileIcon";
import { primaryColor } from "colors-config";

const ConsentForm = ({
    mode = "view"
}) => {
    const clinicDetails = useSelector(state => state.clinic)
    const [labConsentDetails, setLabConsentDetails] = useState();
    const [consentDetails, setConsentDetails] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (clinicDetails && clinicDetails?.consentDetails?.type == 'lab') {
            setLabConsentDetails(clinicDetails?.consentDetails);
        }
        else {
            setConsentDetails(clinicDetails?.consentDetails);
        }
    }, [clinicDetails.consentDetails]);


    const onDeleteConsentClick = async () => {
        try {
            message.info("Deleting started");
            await deleteConsentFormApi(clinicDetails.clinicId);
            message.success("Deleting done");
            setConsentDetails(null);
            fetchClinicDetails();
        } catch (error) {
            message.error("Error occured while deleting")
        }
    }
    const fetchClinicDetails = async () => {
        const res = await getClinicDetails(clinicDetails?.clinic_email || clinicDetails?.clinicEmail);
        dispatch(loadClinicDetails(res?.data?.body))
    }



    const beforeUpload = (file) => {

        const isPDF = file.type === 'application/pdf';
        if (!isPDF) {
            message.error('You can only upload PDF file!');
        }
        const isLt150M = file.size / 1024 / 1024 < 150;
        if (!isLt150M) {
            message.error('Image must smaller than 150MB!');
        }
        return isPDF && isLt150M;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'error') {
            setLoading(false);
            return;
        }

        if (info.file.status === 'done') {
            setLoading(false);
            setConsentDetails(info?.file?.response);
            setLabConsentDetails(null);
        }
    };

    async function uploadFiles(options) {
        const { onSuccess, onError, file, onProgress, filename } = options;
        try {

            let formData = new FormData();

            formData.append("file", file);
            formData.append("clinicId", clinicDetails.clinicId);
            formData.append("model", "clinic_consent");
            formData.append("uploadedBy", "clinic");

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
                onUploadProgress: (event) => {
                    const percent = Math.floor((event.loaded / event.total) * 100);

                    if (percent === 100) {
                    }
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                },
            };

            const uploadData = await uploadMediaApi(formData, config);
            console.log("uploadData", uploadData);

            onSuccess(uploadData.data.data);
            fetchClinicDetails();

        } catch (error) {
            console.log("hello", error);
            onError({ error });
        }
    }
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const downloadHandler = (type) => {
        let fileDetails;
        if (type == 'lab') {
            fileDetails = labConsentDetails;
        }
        else {
            fileDetails = consentDetails;
        }
        const link = document.createElement("a");
        link.href = fileDetails.url;
        link.download = fileDetails.name;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div className="consent-upload-container">
            <h2 className="title">Consent Form for Patient</h2>
            <div>
                <Space direction="horizontal">
                    {consentDetails ? (
                        <div className="fileImage">
                            <ConsentScanFileIcon
                            />
                            <div className="fileMask">
                                <span className="icons" onClick={() => downloadHandler("clinic")}><DownloadOutlined /></span>
                                {mode !== "view" ? <span className="icons" onClick={onDeleteConsentClick}><DeleteOutlined /></span> : null}
                            </div>
                        </div>
                    ) : (
                        <Upload
                            name="consent-uploader"
                            listType="picture-card"
                            className="consent-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            customRequest={uploadFiles}
                            disabled={mode === "view"}
                            accept="application/pdf"
                        >
                            {mode==="edit" ? uploadButton:null}
                        </Upload>
                    )}
                    {labConsentDetails ?
                        <div className="fileImage">
                            <ConsentScanFileIcon/>
                            <div className="fileMask">
                                <span className="icons" onClick={() => downloadHandler("lab")}><DownloadOutlined /></span>
                                {/* {mode !== "view" ? <span className="icons" onClick={onDeleteConsentClick}><DeleteOutlined /></span> : null}  */}
                            </div>
                        </div> : null
                    }
                </Space>

            </div>
        </div>)
};

export default ConsentForm;